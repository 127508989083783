import React, { useEffect } from "react";
import {
  IconButton,
  Typography,
  Box,
  Paper,
  Button,
  Divider,
  Stack,
  ClickAwayListener,
} from "@mui/material";
import { CaretDown } from "phosphor-react";
import { useState } from "react";
import { Popper } from "@mui/material";
import { t } from "i18next";
import { getDocumentDirection } from "../../../../../services/Helper";
import "./OneFilter.scss";
import { useDispatch } from "react-redux";

const OneFilter = ({
  label,
  children,
  active,
  showActionBtns,
  disabled,
  control,
  id,
  reset,
  controlCloseMenu,
  closed,
  setClosed,
  applied,
  applyFilterFunction
}) => {
  const textDir = getDocumentDirection();
  const [anchorEl, setAnchorEl] = useState();
  const [menuState, setMenuState] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (controlCloseMenu && closed)
  //     setMenuState(false);
  // }, [closed])

  // useEffect(() => {
  //   controlCloseMenu && setClosed(!menuState);
  // }, [menuState])

  const FilterDropDown = (props) => {
    const { anchorEl, open } = props;
    return (
      <ClickAwayListener onClickAway={() => setMenuState((prev) => !prev)}>
        <Popper
          anchorEl={anchorEl}
          open={open}
          disableAutoFocus={true}
          placement={textDir === "left" ? "bottom-start" : "bottom-end"}
          sx={{
            zIndex: "4123",
          }}
        >
          <Paper className="one-filter-dropdown">
            <Stack divider={<Divider />} spacing={1}>
              <Box className="one-filter-dropdown-content">
                {props.children}
              </Box>
              {showActionBtns && (
                <Box>
                  <Stack direction={"row"} spacing={1}>
                    <Button
                      className="btn reset-filter"
                      onClick={() => {
                        if (id === "salaries") {
                          reset({ ...control(), max: "", min: "", [id]: [] });
                          dispatch({
                            type: `MAX_FILTER`,
                            payload: null,
                          });
                          dispatch({
                            type: `MIN_FILTER`,
                            payload: null,
                          });
                        } else if (id === "countries") {
                          reset({ ...control(), ['governorates']: [], ['cities']: [], [id]: [] });
                          dispatch({
                            type: `${'governorates'.toUpperCase()}_FILTER`,
                            payload: [],
                          });
                          dispatch({
                            type: `${'cities'.toUpperCase()}_FILTER`,
                            payload: [],
                          });

                        } else if (id === "governorates") {

                          reset({ ...control(), ['cities']: [], [id]: [] });
                          dispatch({
                            type: `${'cities'.toUpperCase()}_FILTER`,
                            payload: [],
                          });

                        } else {
                          reset({ ...control(), [id]: [] });
                        }

                        dispatch({
                          type: `${id.toUpperCase()}_FILTER`,
                          payload: [],
                        });
                        setMenuState(false)
                      }}
                    >
                      {t("others.reset")}
                    </Button>
                    <Button
                      className="btn btn-dark apply-filter"
                      onClick={() => {
                        applyFilterFunction()
                        setMenuState(false);
                      }}
                    >
                      {t("others.apply_filter")}
                    </Button>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Paper>
        </Popper>
      </ClickAwayListener>
    );
  };
  const renderFilterDropDown = (dropDownItems) => {
    if (dropDownItems)
      return (
        <>
          <FilterDropDown anchorEl={anchorEl} open={menuState}>
            {dropDownItems}
          </FilterDropDown>
        </>
      );
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuState((prev) => !prev);
  };
  return (
    <Box className="one-filter-warpper">
      <IconButton
        className={`one-filter ${(active && !disabled) ? "one-filter-active" : null}`}
        onClick={handleFilterClick}
        disabled={disabled}
      >
        <Typography className={`filter-label ${(active && !disabled) ? "one-filter-active-label" : null} `}>
          {label}
        </Typography>
        <CaretDown size={16} weight="fill" color={(active && !disabled) ? "white" : "gray"} />
      </IconButton>
      <Box>{renderFilterDropDown(children)}</Box>
    </Box>
  );
};

export default OneFilter;
