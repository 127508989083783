import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Show from "../Show";
import "./PageHeader.css";
const PageHeader = ({ showButton, title, icon, onClick, translate }) => {
    const { t } = useTranslation()
    return (
        <>
            <Box component="div" className="page-title">
                <Grid container direction="row" columnSpacing={2}>
                    <Grid item>
                        <Typography className="title">
                            {translate ? t(title) : title}
                        </Typography>
                    </Grid>
                    <Show condition={showButton}>
                        <Grid item>
                            <IconButton className="jb-icon-btn" onClick={onClick} >
                                {icon}
                            </IconButton>
                        </Grid>
                    </Show>
                </Grid>
            </Box>
        </>
    );
}
export default PageHeader;