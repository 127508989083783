import { useState } from "react";
import { Box, Button } from "@mui/material";
import BlockModal from "../block-modal/BlockModal";
import "./BlockAndUnblock.scss";
import { t } from "i18next";
import { block_unblock } from "../../../../../../../services/Helper";

const BlockAndUnblock = ({ blocked, user, setFetchTrigger }) => {
  console.log("blocked", user);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    if (!blocked) setOpen(true);
    else {
      setLoading(true);
      block_unblock(user.email, "Reason", "unblock")
        .then((res) => {
          console.log("Unblocked", user);
          setFetchTrigger((prev) => !prev);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  console.log(user);
  return (
    <Box className="block-unblock-wrapper">
      <Button
        onClick={handleClick}
        className={`${blocked === false ? "btn-block" : "btn-unblock"} `}
        disabled={loading}
      >
        {blocked === false
          ? t("buttons.block_account")
          : t("buttons.unblock_account")}
      </Button>
      <BlockModal
        setOpen={setOpen}
        open={open}
        userEmail={user.email}
        userToBlock={`${user.first_name} ${user.second_name}`}
        setFetchTrigger={setFetchTrigger}
        loading={loading}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default BlockAndUnblock;
