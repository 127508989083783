export const FETCH_PROFILE = "FETCH_PROFILE"
export const DELETE_PROFILE = "DELETE_PROFILE"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_STEP_FINISHED = "UPDATE_STEP_FINISHED"
export const ASSIGN_ROLE = "ASSIGN_ROLE";
export const DELETE_PROFILE_IMAGE = "DELETE_PROFILE_IMAGE";
export const LOAD_PROFILE = 'LOAD_PROFILE';

export const ADD_SKILL = "ADD_SKILL";
export const DELETE_SKILL = "DELETE_SKILL"
export const EDIT_SKILL = "EDIT_SKILL"

export const ADD_CONTACTPORTFOLIO = "ADD_CONTACTPORTFOLIO"
export const EDIT_CONTACTPORTFOLIO = "EDIT_CONTACTPORTFOLIO"

export const ADD_GENERALINFO = "ADD_GENERALINFO"

export const CHANGE_DEFAULT_ROLE = "CHANGE_DEFAULT_ROLE"

