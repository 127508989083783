import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  TextField,
  Input,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import "./SendNotification.scss";
import { Stack } from "@mui/material";
import IconInput from "./../../components/IconInput";
import {
  Money,
  TextAlignJustify,
  IdentificationBadge,
  XCircle,
  Image,
} from "phosphor-react";
import { Plus } from "phosphor-react";
import JLSelect from "./../../components/JLSelect";
import { t } from "i18next";
import {
  adminSendNotification,
  getCity,
  getCountry,
  getDocumentDirection,
  getGovernorate,
} from "../../services/Helper";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useForm, Controller, useWatch } from "react-hook-form";
import { DatePicker, MobileDatePicker } from "@mui/lab";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { IMAGE_TYPES } from "../../constants/constants";
const roles = [
  {
    name_en: "All",
    name_ar: "الكل",
    id: "All",
  },
  {
    name_en: "Recruiter",
    name_ar: "الشركات",
    id: "Recruiter",
  },
  {
    name_en: "Applicant",
    name_ar: "الأفراد",
    id: "Applicant",
  },
];
const SendNotification = () => {
  //document direction
  const documentDirection = getDocumentDirection();
  //Component State
  const [currentImg, setCurrentImg] = useState();
  const [currentImgFile, setCurrentImgFile] = useState(null);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [loading, setLoading] = useState(false);

  //form schema
  const notificationSchema = yup
    .object({
      title_en: yup.string().required(t("validations.required_input")),
      title_ar: yup.string().required(t("validations.required_input")),
      description_en: yup.string().required(t("validations.required_input")),
      description_ar: yup.string().required(t("validations.required_input")),
      // role: yup.required()
    })
    .required();
  //react-hook-form
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(notificationSchema) });
  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);
    adminSendNotification(data)
      .then((res) => {
        setCities([]);
        setGovernorates([]);
        setCurrentImg(null);
        setCurrentImgFile(null);
        reset();
        console.log(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCountry()
      .then((res) => {
        setCountries(res);
      })
      .catch((error) => {
        console.log(error);
      });
    getGovernorate()
      .then((res) => {
        setGovernorates(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const onCountryChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      getGovernorate(value).then((data) => {
        setGovernorates(data);
        setCities(null);
        setValue("city_id", "");
        setValue("governorate_id", "");
      });
    } else {
      setGovernorates(null);
      setCities(null);
      setValue("city_id", "");
      setValue("governorate_id", "");
    }
  };
  const onGovernorateChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      getCity(value).then((data) => {
        setValue("city_id", "");
        setCities(data);
      });
    } else {
      setCities(null);
      setValue("city_id", "");
    }
  };

  const deleteProfileImg = () => {
    setCurrentImg("");
    setCurrentImgFile(null);
    setValue("image", "");
    document.getElementById("icon-button-file").value = null;
  };
  useEffect(() => {
    if (
      typeof currentImg !== "string" &&
      currentImg !== null &&
      currentImg !== undefined
    ) {
      console.log("!string", getValues("images"));
      setCurrentImg(URL.createObjectURL(currentImg));
    } else {
      console.log("elseelseelse");
    }
  }, [currentImg]);
  return (
    <Stack
      className="send-notification-page-wrapper"
      direction={"column"}
      spacing={3}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography className="send-notifcation-title">
          {t("titles.send_notifications")}
        </Typography>
        <Grid container rowGap={2} columnGap={1}>
          <Grid item xs={12}>
            <Typography className="send-notification-subtitle">
              {t("titles.notifcation_content")}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={6}>
                <IconInput
                  label={t("titles.notification_title") + " EN"}
                  placeholder={t("titles.notification_title") + " EN"}
                  type="text"
                  icon={IdentificationBadge}
                  className="w100 new-job-input from_to_input"
                  fieldName="title_en"
                  onChange={(e) => console.log(e.target.value)}
                  register={register}
                  error={errors?.notification_title_en}
                />
              </Grid>
              <Grid item xs={6}>
                <IconInput
                  label={t("titles.notification_title") + " AR"}
                  placeholder={t("titles.notification_title") + " AR"}
                  type="text"
                  icon={IdentificationBadge}
                  className="w100 new-job-input from_to_input align-right"
                  fieldName="title_ar"
                  onChange={(e) => console.log(e.target.value)}
                  register={register}
                  error={errors?.notification_title_ar}
                />
              </Grid>
              <Grid item xs={6}>
                <IconInput
                  label={t("titles.notification_description") + " EN"}
                  placeholder={t("titles.notification_description") + " EN"}
                  type="text"
                  icon={TextAlignJustify}
                  className="w100 new-job-input from_to_input"
                  fieldName="description_en"
                  multiline={true}
                  iconTop
                  rows={4}
                  onChange={(e) => console.log(e.target.value)}
                  register={register}
                  error={errors?.notification_description_en}
                />
              </Grid>
              <Grid item xs={6}>
                <IconInput
                  label={t("titles.notification_description") + " AR"}
                  placeholder={t("titles.notification_description") + " AR"}
                  type="text"
                  icon={TextAlignJustify}
                  className="w100 new-job-input from_to_input"
                  fieldName="description_ar"
                  multiline={true}
                  iconTop
                  rows={4}
                  onChange={(e) => console.log(e.target.value)}
                  register={register}
                  error={errors?.notification_description_ar}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={true}>
            <Typography className="send-notification-label">
              {" "}
              {t("titles.notification_attach_img")}
            </Typography>
            <Box className="attach-img-to-notification ">
              <Grid item className="pic">
                <Stack
                  direction={"column"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  {currentImg && (
                    <IconButton
                      size="small"
                      className="img-cancel-btn"
                      onClick={deleteProfileImg}
                    >
                      <XCircle className="img-cancel-icon" size={24} />
                    </IconButton>
                  )}
                  <img
                    src={currentImg ? currentImg : ""}
                    width="73"
                    height="73"
                    alt="Profile"
                  />
                </Stack>
                <label htmlFor="icon-button-file" className="icon">
                  <Controller
                    control={control}
                    name="image"
                    render={({ field }) => {
                      return (
                        <Input
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          inputProps={{
                            accept: "image/png, image/jpg, image/jpeg",
                          }}
                          onChange={(e) => {
                            if (IMAGE_TYPES.includes(e.target.files[0]?.type)) {
                              field.onChange(e.target.files[0]);
                              setCurrentImg(e.target.files[0]);
                              console.log(currentImg);
                              setCurrentImgFile(e.target.files[0]);
                            } else {
                              setError("image", {
                                type: "manual",
                                message: t("validations.file_type"),
                              });
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {!currentImg && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Plus size={60} color={"#919191"} />
                    </IconButton>
                  )}
                </label>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container rowGap={2} columnGap={1}>
          <Grid item xs={12} mt={2}>
            <Typography className="send-notification-subtitle">
              {t("titles.notifications_filters")}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <JLSelect
              fieldName="role"
              control={control}
              label={t("titles.notifications_role")}
              selectLabel={t("titles.notifications_role")}
              className="new-job-input"
              childs={roles}
              error={errors?.role}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              columnGap={3}
            >
              <JLSelect
                label={t("subTitles.general_location")}
                childs={countries}
                selectLabel={t("placeholders.general_country")}
                fieldName="country_id"
                control={control}
                onChange={onCountryChange}
              />
              <JLSelect
                label={t("others.governorate_placeholder")}
                selectLabel={t("placeholders.governrate")}
                control={control}
                fieldName="governorate_id"
                childs={governorates}
                onChange={onGovernorateChange}
              />
              <JLSelect
                label={t("labels.city")}
                selectLabel={t("placeholders.general_city")}
                childs={cities}
                control={control}
                fieldName="city_id"
                error={errors.city_id}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          align={documentDirection === "right" ? "left" : "right"}
          mt={2}
        >
          <Button className="btn btn-dark send-notification-btn" type="submit">
            {t("titles.send_notifications")}
          </Button>
        </Grid>
      </form>
    </Stack>
  );
};

export default SendNotification;
