import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material'
import ProfileInfo from '../applicant-profile/components/profile-info/ProfileInfo';
import ProfileContent from '../applicant-profile/components/profile-content/ProfileContent';
import Loader from '../../components/loader/Loader';
import { useSelector } from "react-redux";
import { getReadProfileApplicant } from '../../services/Helper';
import { useLocation, useParams } from 'react-router-dom'


const ApplicantCvProfile = () => {
  const role = useSelector((state) => state.profile.role.name);
  const rehydrated = useSelector((state) => state._persist.rehydrated);
  const { applicant_id } = useParams();

  const [showAddAndInviteProfile, setShowAddAndInviteProfile] = useState(false);
  const [userData, setUserData] = useState();
  const [fetchTrigger, setFetchTrigger] = useState(false)

  useEffect(() => {
    setShowAddAndInviteProfile(role === 'GUEST_USER' ? false : role === 'RECRUITER' ? true : false)
  }, [rehydrated])

  useEffect(() => {
    getReadProfileApplicant(applicant_id).then((response) => {
      console.log("user", response)
      setUserData({ User: response })
    }).catch((error) => {
      console.log("errrrr", error)
    })
    console.log("usss", applicant_id)
  }, [fetchTrigger])


  return (
    <Grid
      className="applicant-profile"
      component="main"
      direction="row"
      container
      columnSpacing={3}
      paddingX={1.5}
    >
      <Grid item component="section" className="profile-info-section" xs={12} sm={12} md={5} lg={4} xl={3}>
        <Box className="applicant-profile-card">
          <Loader data={userData} width={"100%"} height={600} style={{ borderRadius: "1rem" }}>
            <ProfileInfo
              userData={userData}
              hideSaveIcon={true}
              setFetchTrigger={setFetchTrigger}

            />
          </Loader>
        </Box>
      </Grid>
      <Grid item component="section" xs={12} sm={12} md={true} >
        <Loader data={userData} width={"100%"} height={600} style={{ borderRadius: "1rem" }}>
          <ProfileContent
            userData={userData}
            setFetchTrigger={setFetchTrigger}
          />
        </Loader>
      </Grid>
    </Grid>
  )
}

export default ApplicantCvProfile

