import React from 'react';
import ErrorPage from '../../../components/error-page/ErrorPage';
import animateData from "../../../assets/empty-page.json"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Page400 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <ErrorPage
            title={t("labels.400")}
            animationData={animateData}
            actionText={t("labels.back_to_home")}
            action={() => navigate("/")}
        />
    );
};

export default Page400;