import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import JobDetailsCard from '../../components/job-details-card/JobDetailsCard';
import ApplicantCard from './components/applicants-card/ApplicantCard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Show from '../../components/Show';
import { changeCompanyStatus, perm, permAnd } from "../../services/Helper"
import Permissions from '../../constants/permissions';
import { useParams, Navigate } from "react-router-dom";
import { getJob } from '../../services/Helper';
import { translateField } from '../../services/Helper';
import ErrorPage from '../../components/error-page/ErrorPage';
import noDataAnimation from '../../assets/lootie-files/no-data.json'
import Loader from '../../components/loader/Loader';
import { loadProfileFromRedux } from '../../state/actions/profile/profile';


const JobDetails = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [job, setJob] = useState(null);
  const [jobLoaded, setJobLoaded] = useState(false);
  const [showStatus, setShowStatus] = useState(false)


  const role = useSelector((state) => state.profile.role?.name);
  const permissions = useSelector((state) => state.profile.role?.permissions);
  const loaded = useSelector((state) => state.profile.loaded);
  const params = useParams();
  const [followed, setFollowed] = useState(false)

  const handleFollowCompany = () => {
    changeCompanyStatus(job?.Recruiter?.id, role).then(() => {
      setFollowed(!followed)
    })
  }
  useEffect(() => {
    dispatch(loadProfileFromRedux())
  }, [])
  useEffect(() => {
    if (loaded && permAnd(permissions, Permissions.viewJob, Permissions.editJob)) {
      getJob(params.job_id, false).then((data) => {
        setJob(data);
        setJobLoaded(true)
        if (loaded && perm(permissions, Permissions.viewStatusInJobDetails)) {
          if (data?.ApplicantApplyJobs?.length > 0) {
            setShowStatus(true)
          }
        }
      }).catch((error) => {
        setJobLoaded(true)
      })
    } else if (loaded && perm(permissions, Permissions.viewJob)) {
      getJob(params.job_id, true).then((data) => {
        console.log(data)
        setJob(data);
        setJobLoaded(true)
        if (loaded && perm(permissions, Permissions.viewStatusInJobDetails)) {
          if (data?.ApplicantApplyJobs?.length > 0) {
            setShowStatus(true)
          }
        }
      }).catch((error) => {
        setJobLoaded(true)
      })
    }
  }, [loaded]);
  useEffect(() => {
    if (jobLoaded) {
      setFollowed(job?.Recruiter?.is_followed == 1 ? true : false);
    }
  }, [jobLoaded])
  console.log(job)
  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Show condition={perm(permissions, Permissions.viewJob)}>
          <Grid item xs={12} md={perm(permissions, Permissions.viewJobApplicants) ? 8 : 12}>
            <Loader data={jobLoaded} width={"100%"} height={400} style={{ borderRadius: "1rem" }}>
              <Show condition={jobLoaded && job}>
                <JobDetailsCard
                  followHandler={handleFollowCompany}
                  recruiterId={job?.recruiter_id}
                  jobId={params?.job_id}
                  followed={jobLoaded && followed}
                  status={job?.status}
                  jobTitle={translateField(job?.JobTitle, "name")}
                  experienceType={translateField(job?.ExperienceType, "name")}
                  applicantsCount={`${job?.count} ${t("others.applicants")}`}
                  date={job?.created_at}
                  location={`${translateField(job?.Country, "name")}, ${translateField(job?.Governorate, "name")}, ${translateField(job?.City, "name")}`}
                  yearsOfEx={translateField(job?.YearsOfExperience, "name")}
                  jobCategory={translateField(job?.JobCategory, "name")}
                  slaryRange={
                    job?.salary_from && job?.salary_to
                      ? job?.salary_from +
                      " - " +
                      job?.salary_to
                      : translateField(job?.Salary, "name")
                  }
                  workStyle={translateField(job?.WorkStyle, "name")}
                  jobDescreption={job?.job_descirption}
                  jobRequirement={job?.job_requirement}
                  noOfApplicants={job?.count}
                  isApplied={job?.is_applied}
                  isSaved={job?.is_saved}
                  emptyData={job === undefined ? true : false}
                  companyName={job?.Recruiter?.company_name}
                  companyLogo={job?.Recruiter?.image}
                  companySize={translateField(job?.Recruiter?.CompanySize, "name")}
                  ApplicantApplyJobs={job?.ApplicantApplyJobs}
                  showStatus={showStatus}
                  careerLevel={translateField(job?.CareerLevel, "name")}
                  jobStatus={
                    job?.ApplicantApplyJobs?.[0]?.JobStatus
                      ?.name_en === "Pending"
                      ? job?.ApplicantApplyJobs?.[0]?.is_seen
                        ? "Seen"
                        : job?.ApplicantApplyJobs?.[0]?.JobStatus
                          ?.name_en
                      : job?.ApplicantApplyJobs?.[0]?.JobStatus
                        ?.name_en
                  }
                />
              </Show>
            </Loader>
          </Grid>
        </Show>
        {/* <Show condition={perm(permissions, Permissions.viewJobApplicants)}>
          <Grid item xs={12} md={4}>
            <Loader data={jobLoaded} width={"100%"} height={400} style={{ borderRadius: "1rem" }}>
              <Show condition={jobLoaded && job}>
                <ApplicantCard applicantsCount={job?.count} applicantApplyJob={job?.ApplicantApplyJobs} job_id={params.job_id} />
              </Show>
            </Loader>
          </Grid>
        </Show> */}
        <Show condition={jobLoaded && !job}>
          <Grid item xs={12}>
            <ErrorPage
              hideAction
              animationData={noDataAnimation}
              title={t("labels.oops")}
              description={t("labels.no_jobs")}
            />
          </Grid>
        </Show>
      </Grid >
    </>
  )
}

export default JobDetails