import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Input, Radio, RadioGroup, Typography } from '@mui/material';
import * as yup from "yup";

import { t } from 'i18next';
import { FilePlus } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { addNewLocaleFile, editLocaleFile } from '../../services/Helper';

const LocalesModal = ({ open, closeHandler, edit, row }) => {
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [localeFile, setLocaleFile] = useState()
    let schema = {
        side_id: yup.number().required(t("validations.required_input")),
        lang_file: yup.mixed().required(t("validations.required_input"))
    };
    const modelSchema = yup.object(schema).required();
    console.log(row)
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(modelSchema),
    });
    const onSubmit = (data) => {
        setLoading(true)
        if (edit) {
            editLocaleFile(searchParams.get("app_language_id"), row.id, data.side_id, data.lang_file).then((res) => {
                closeHandler(true)
            }).finally(() => {
                closeHandler(true)
                setLoading(false)
            })
        }
        else {
            addNewLocaleFile(searchParams.get("app_language_id"), data.side_id, data.lang_file).then((res) => {
                closeHandler(true)
            }).finally(() => {
                closeHandler(true)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if (edit) {
            console.log(row.id)
            setValue("side_id", row?.local_side_id)
        }
    }, [])

    return (
        <Dialog open={open}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container
                    sx={{ width: "450px", height: "fit-content", padding: "20px" }}
                    rowSpacing={2}
                    columnSpacing={2}
                    justifyContent={"center"}
                >
                    {edit &&
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}> You Are Editing {row.locale_name + " " + row.local_side} </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FileDrop
                            onDrop={(files, event) => setLocaleFile(files[0])}
                        >
                            <div>
                                {localeFile &&
                                    <Typography sx={{
                                        color: "red"
                                    }}>{localeFile.name}</Typography>
                                }
                                <Typography className='text'> {t("others.json_file_format")}</Typography>
                                <label htmlFor="lang_file" className="icon" style={{ width: "100%" }}>
                                    <Controller
                                        control={control}
                                        name="lang_file"
                                        render={({ field }) => {
                                            return <Input
                                                id="lang_file"
                                                type="file"
                                                style={{ display: 'none' }}
                                                inputProps={{
                                                    accept: ".json"
                                                }}
                                                onChange={(e) => {
                                                    field.onChange(e.target.files[0])
                                                    setLocaleFile(e.target.files[0])
                                                }}
                                            />
                                        }}
                                    />
                                    <IconButton color="primary" className='drag-and-drop' component="span" >
                                        <FilePlus className='icon' />
                                        <Typography className='text'>{t("subTitles.drag_and_drop")}</Typography>
                                    </IconButton>
                                </label>
                            </div>
                        </FileDrop>
                        {errors.lang_file &&
                            <FormHelperText sx={{ color: "red" }}>{errors?.lang_file?.message}</FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name={"side_id"}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <FormControl>
                                        <FormLabel id="demo-controlled-radio-buttons-group">Side</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={onChange}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="Web" />
                                            <FormControlLabel value="2" control={<Radio />} label="Mobile Recruiter" />
                                            <FormControlLabel value="3" control={<Radio />} label="Mobile Applicant" />
                                        </RadioGroup>
                                    </FormControl>
                                )
                            }}
                        />
                        {errors.side_id &&
                            <FormHelperText sx={{ color: "red" }}>{errors?.lang_file?.message}</FormHelperText>
                        }
                    </Grid>
                    <Grid item container xs={12} columnSpacing={1} justifyContent={"flex-end"}>
                        <Grid item xs={3}>
                            <Button sx={{
                                '&:hover': {
                                    backgroundColor: "#3A3B81",
                                    opacity: "0.6"
                                },
                                backgroundColor: "#3A3B81",
                                textTransform: "capitalize",
                                width: "100%",
                                color: "white"
                            }}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                sx={{
                                    '&:hover': {
                                        backgroundColor: "red",
                                        opacity: "0.6"
                                    },
                                    backgroundColor: "red",
                                    textTransform: "capitalize",
                                    width: "100%",
                                    color: "white"
                                }}
                                onClick={() => closeHandler(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Dialog >
    )
}

export default LocalesModal