import { DataGrid } from '@mui/x-data-grid'
import React from 'react'

const Playground = () => {
    return (
        <DataGrid
            rows={[]}
            columns={[]}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
        />)
}

export default Playground