import { Box } from '@mui/material';
import React from 'react';

import { getDocumentDirection } from '../../../../services/Helper';
import "./DashboardHomeHeader.scss";

const DashboardHomeHeader = () => {
    const textAlignment = getDocumentDirection()

    return (
        <header >
            <Box align={textAlignment} sx={{ overflow: "hidden" }}>

                <h3 className="dashboard-home-header-title">Home</h3>
            </Box>
        </header>
    )
}

export default DashboardHomeHeader