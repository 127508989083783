import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from "./reducers";
import { completeB2cLogin } from "./actions/b2c/b2c";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["profile", "sidebar", "b2cLogin", "auth", "process"],
    blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(thunk)));
const persistor = persistStore(store, null, () => {
    store.dispatch(completeB2cLogin())
});

export { store, persistor };