import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store } from "./state/store";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./services/auth/B2CConfig";

const msalInstance = new PublicClientApplication(msalConfig);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
    .then(function (registration) {
      // console.log('Registration successful, scope is:', registration.scope);
    }).catch(function (err) {
      // console.log('Service worker registration failed, error:', err);
    });
}
ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </MsalProvider >
  </Provider>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
