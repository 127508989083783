import React, { useEffect } from "react";
import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Box,
  FormGroup,
} from "@mui/material";
import IconInput from "../../../components/IconInput";
import { At } from "phosphor-react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toastr } from "react-redux-toastr";
import { sendOTP } from "../../../services/AuthHelper";
import { startOTPVerification } from "../../../state/actions/otp/otp";
import { useDispatch, useSelector } from "react-redux";

const ForgetPassword = () => {
  let width = window.innerWidth;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("validations.email_format"))
        .required(t("validations.required_input")),
    })
    .required();

  const {
    control,
    register,
    reset,
    setValue,
    formState: { errors },
    setError,
    handleSubmit,
    getValues,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    const { email } = formData;

    try {
      const result = await sendOTP(email, "RESET_PASSWORD");
      dispatch(
        startOTPVerification(
          "Enter OTP To Verify Email",
          email,
          "RESET_PASSWORD"
        )
      );
      toastr.success(result.message);
      navigate("/reset-password-verification");
    } catch (error) {
      toastr.error(error.response.data.error);
    }
  };

  useEffect(() => {
    console.log("RESET PASSWORD", token);
    if (token) {
      console.log("RESET PASSWORD REDIRECT", token);
      navigate("/");
    }
  }, [token]);

  return (
    <AuthPageWrapper
      titleSlice1="Enjoy the"
      titleSlice2="Opportunities"
      description="Job Links allows connection between people and organizations through the world."
      className="sign-in-wrapper"
      single={width <= 991 ? true : false}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid className="signin-form" justifyContent="center" xs={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormControl className="mb24">
                <Typography className="title">Joblink</Typography>
                <Typography className="desc">
                  {t("subTitles.lets_reset")}
                </Typography>
              </FormControl>
              <IconInput
                label={t("labels.email")}
                id="email"
                placeholder={t("others.email_placeholder")}
                type="email"
                icon={At}
                className="mb16"
                fieldName="email"
                register={register}
                error={errors.email}
              />
              <FormControl className="mb24">
                <Button type="submit" className="login-btn" variant="contained">
                  {t("buttons.request_pass_btn")}
                </Button>
              </FormControl>
              <FormControl>
                <Typography variant="div" className="navigation-text">
                  {t("subTitles.remember_password")}{" "}
                  <Box component={Link} className="navigation-btn" to="/login">
                    {t("links.back_to_login")}
                  </Box>
                </Typography>
              </FormControl>
            </FormGroup>
          </form>
        </Grid>
      </Grid>
    </AuthPageWrapper>
  );
};

export default ForgetPassword;
