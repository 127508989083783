import React , {useState} from 'react';
import { Button } from '@mui/material';
import BlockHistory from '../block-history/BlockHistory';
import { useTranslation } from 'react-i18next';
import './BlockHistoryBtn.css'

const BlockHistoryBtn = ({user , style}) => {

  const {t} = useTranslation()

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <div className='block-history-wrapper'>
        <Button 
          className={style === 'applicant' ? 'history-btn-applicant' : 'history-btn-recruiter'} 
          onClick={handleClickOpen}
        >
          {t('others.block_history')}
        </Button>
        <BlockHistory 
          open={open}
          onClose={handleClose}
          user={user}
        />
    </div>
  )
}

export default BlockHistoryBtn