import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Question } from "phosphor-react";
import Lottie from 'lottie-react';
import recruiterAnimation from "../../../../../assets/recruiter.json";
import applicantAnimation from "../../../../../assets/applicant.json";
import interviewerAnimation from "../../../../../assets/interviewer.json";
import coachAnimation from "../../../../../assets/coach.json";

import "./RoleSwitcher.css"
import { useDispatch, useSelector } from "react-redux";
import { assignRole, fetchProfile } from "../../../../../state/actions/profile/profile";
import { useNavigate } from "react-router-dom";
import { store } from "../../../../../state/store";
import { useTranslation } from "react-i18next"
import { deleteFirebaseToken, requestForToken } from '../../../../../firebase';
import { addDeviceToken } from "../../../../../services/Helper"

const Role = ({ role, animationData, onClick, active }) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };
    return (
        <Box component="div" className={["one-role", active ? "active" : ""].join(" ")}>
            <Grid container justifyContent="center" columnSpacing={1} direction="row">
                <Grid item xs={12}>
                    <Lottie isClickToPauseDisabled options={defaultOptions} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid xs="auto">
                            <Typography className="title" onClick={onClick}>
                                {role}
                            </Typography>
                        </Grid>
                        <Grid xs="auto">
                            <IconButton size="small">
                                <Question />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}

const RoleSwitcher = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const role = useSelector((state) => state.profile.role.name);
    const isCvCreated = useSelector((state) => state.profile?.data?.cv?.User?.is_cv_created);
    const isCompanyCreated = useSelector((state) => state.profile?.data?.recruiter?.is_company_created);

    const notCompleted = (isCvCreated == 0 || isCompanyCreated == 0)
    // const roles = useSelector((state) => state.profile.data?.role);

    const handleRoleClick = async (pRole) => {
        let roles = store.getState().profile.data?.role;
        if (typeof roles === 'object') {
            roles = Object.values(roles);
        }
        console.log(roles);
        const result = roles?.find((item) => item.name == pRole);
        console.log("RoleSwitcher.js -->", result);
        if (result) {
            navigate("/");
            // await dispatch(fetchProfile(pRole));
            // dispatch(assignRole(result));
            // const token = await requestForToken();
            // addDeviceToken({ device_token: token }, result?.name, notCompleted)

        } else {
            switch (pRole) {
                case "APPLICANT":
                    navigate("/applicant-submit", { state: { switched: true } })
                    break;
                case "RECRUITER":
                    navigate("/recruiter-submit", { state: { switched: true } })
                    break;
                case "INTERVIEWER":
                    break;
                case "COACH":
                    break;
                default:
                    break
            }
        }
    }

    return (
        <>
            <Box component="div" className="role-switcher">
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="card-content">
                            <Role
                                active={role == "RECRUITER"}
                                role={t("labels.recruiter")}
                                animationData={recruiterAnimation}
                                onClick={() => handleRoleClick("RECRUITER")}
                            />
                            <Role
                                active={role == "APPLICANT"}
                                role={t("labels.applicant")}
                                animationData={applicantAnimation}
                                onClick={() => handleRoleClick("APPLICANT")}
                            />
                            {/* <Role
                                active={role == "INTERVIEWER"}
                                role={"Interviewer"}
                                animationData={interviewerAnimation}
                                onClick={() => handleRoleClick("INTERVIEWER")}
                            />
                            <Role
                                active={role == "COACH"}
                                role={"Coach"}
                                animationData={coachAnimation}
                                onClick={() => handleRoleClick("COACH")}
                            /> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
export default RoleSwitcher;