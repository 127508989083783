import React, { useState } from "react";
import {
  Avatar,
  Divider,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./ProfileCard.scss";
import {
  UsersThree,
  At,
  Phone,
  PencilSimple,
  ShareNetwork,
} from "phosphor-react";
import { useSelector } from "react-redux";
import Show from "../../../../components/Show";
import Permissions from "../../../../constants/permissions";
import { changeCompanyStatus, perm } from "../../../../services/Helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DecisionButton from "../../../../components/decision-button/DecisionButton";
import { Checks } from "phosphor-react";
import ShareJobPost from "../../../job-details/components/share-job-post/ShareJobPost";
import Hide from "../../../../components/Hide";
import  BlockUnBlockBtn  from "./components/block-unblock-btn/BlockUnBlockBtn";
import Jobs from "../../../jobs/Jobs";
import MiniJobCard from "../../../../components/mini-job-card/MiniJobCard";
import BlockHistoryBtn from "../../../../components/block-history-btn/BlockHistoryBtn"

const ProfileCard = ({
  logo,
  name,
  industry,
  companySize,
  email,
  phone,
  location,
  description,
  followed,
  id,
  blocked,
  userData,
  setFetchTrigger
}) => {
  const navigate = useNavigate();

  const params = useParams();
  console.log(userData.RecruiterBlocks)

  const { t, i18n } = useTranslation();

  const toggleDivider = useMediaQuery("(max-width:890px)");
  const isXs = useMediaQuery("(max-width:890px)");

  const permissions = useSelector((state) => state.profile.role?.permissions);
  const role = useSelector((state) => state.profile?.role);
  const companyId = useSelector((state) => state.profile?.data?.recruiter?.id);


  const [isFollowed, setIsFollowed] = useState(followed);

  const [openShare, setOpenShare] = useState(false);

  console.log("followed", followed);
  console.log(
    "COMPANY ID",
    companyId,
    params.company_id,
    companyId == params.company_id
  );

  const handleFollow = () => {
    changeCompanyStatus(id).then((res) => {
      setIsFollowed(!isFollowed);
    });
  };

  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseShare = (value) => {
    setOpenShare(false);
  };
  const handleViewFullProfile = () => {
    navigate(`/company/${id}`)
  }
  return (
    <>
    <Grid className="company-profile-card" direction="column">
      <Grid item xs={12} container direction="row">
        <Grid item xs={6} md={9} container direction="row">
          <Grid item xs={12} md={5}>
            <Avatar className="image" variant="rounded" src={logo} />
            <Typography className="name">{name}</Typography>
            <Typography className="info">{industry}</Typography>
            <div className="container">
              <UsersThree />
              <Typography className="company-size">{companySize}</Typography>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction="row"
            md={7}
            className="contact-info"
            sx={{
              marginTop: isXs ? "16px" : "0",
            }}
          >
            <Grid item>
              <Divider
                orientation="vertical"
                flexItem
                className="divider"
                sx={{
                  display: toggleDivider ? "none" : "block",
                }}
              />
            </Grid>
            <Grid item>
              <Typography className="title">
                {t("labels.contact_info")}
              </Typography>
              <div className="container">
                <At className="icon" />
                <Typography className="desc">{email}</Typography>
              </div>
              <div className="container">
                <Phone className="icon" />
                <Typography className="desc">{phone}</Typography>
              </div>
              <div className="location">
                <Typography className="title">
                  {t("labels.headquarters")}
                </Typography>
                <Typography className="desc">{location}</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} md={3}>
          <Grid container direction={'column'}>
            <Grid item>
              <BlockUnBlockBtn 
                blocked={blocked}
                user={userData}
                setFetchTrigger={setFetchTrigger}
              />
            </Grid>
            <Grid item>
              <BlockHistoryBtn user={userData.RecruiterBlocks}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="about">
        <Typography className="title">{t("labels.about")}</Typography>
        <Typography className="desc">{description}</Typography>
      </Grid>
    </Grid>
    </>
  );
};

export default ProfileCard;
