import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './BlockHistoryTable.css'


const BlockHistoryTable = ({rows,columns}) => {
  return (
    <div style={{ height: "400px", width: '100%' }}>
          <DataGrid
              rows={rows}
              columns={columns}
              disableColumnMenu
              disableDensitySelector
            
              sx={{
                  backgroundColor:"#fafafa",
                  height:"100%",
                  border:"none",
                  fontFamily: 'Urbanist',
                  color:"#404040",
                  cursor: "pointer"
              }}
              className="block-history-table"
          />
      </div>
  )
}

export default BlockHistoryTable