import { Grid } from "@mui/material";
import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
import VerifyOTP from "../../../components/VerifyOTP";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { submitOTP } from "../../../services/AuthHelper";
import { authSubmitOTP } from "../../../state/actions/auth/auth";

const ResetPasswordVerification = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const otp = useSelector((state) => state.otp);

  const onSubmit = async (data) => {
    const postData = {
      email: searchParams.get("email"),
      otp: data.otp,
      type: "RESET_PASSWORD",
    };
    try {
      dispatch(
        authSubmitOTP(
          navigate,
          otp.email,
          postData.otp,
          postData.type,
          "/reset-password"
        )
      );
    } catch (error) {
      console.log("RESET ERROR", error);
      toastr.error(error.response.data.error);
      navigate("/login");
    }
  };

  // useEffect(() => {
  //   if (!searchParams.get("email")) navigate("/login");
  // }, []);

  return (
    <AuthPageWrapper single={true}>
      <Grid
        className="signin-form"
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
      >
        <VerifyOTP title={"Enter OTP to reset password"} onSubmit={onSubmit} />
      </Grid>
    </AuthPageWrapper>
  );
};

export default ResetPasswordVerification;
