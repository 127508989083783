import { FormControl, FormHelperText, InputAdornment, InputBase, useMediaQuery } from "@mui/material";
import React from "react";
import Hide from "./Hide";

const IconInput = (props, ref) => {

    const matched = useMediaQuery("(max-width:900px)");

    return (
        <FormControl
            variant="standard" className={["standart-form-controller", props?.className].join(" ")}
        >
            <Hide condition={matched && props?.label === " "} >
                <div className="label" htmlFor={props?.id} >{props?.label}</div>
            </Hide>
            <InputBase
                disabled={props.disabled}
                value={props?.value}
                id={props?.id}
                className={["input", props?.label ? "" : "no-margin-top", props.searchHome ? "search-input-color" : ""].join(" ")}
                style={{ alignItems: props.iconTop ? "flex-start" : "center" }}
                placeholder={props?.placeholder}
                autoComplete="off"
                type={props?.type}
                multiline={props?.multiline}
                minRows={props?.multiline ? props?.rows : 1}
                error={props.error}
                startAdornment={
                    props.icon &&
                    <InputAdornment className="adornment" position="start">
                        {React.createElement(props?.icon, {
                            className: 'icon'
                        })}
                    </InputAdornment>
                }
                onClick={(e) => e.stopPropagation()}
                {...props?.register(props?.fieldName, {
                    onChange: props.onChange
                })}

            />

            {props.error && (
                <FormHelperText style={{ color: "red" }}>{props.error.message}</FormHelperText>
            )}

        </FormControl>
    );
}
export default IconInput;