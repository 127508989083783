import { Avatar, Grid, IconButton, useMediaQuery } from '@mui/material';
import { Eye, Faders } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataTable from '../../components/data-table/DataTable';
import { translateField } from '../../services/Helper';
import { filterRecruitersAction, getPageOfAdminRecruiter, getPageOfSearchAdminRecruiter } from '../../state/actions/RecruiterSearchAdmin/RecruiterSearchAdmin';
import SearchBar from '../search-page/components/search-bar/SearchBar';
import RecruitersFilter from './components/filter/RecruitersFilter';
import './Recruiters.css';


const Recruiters = () => {
    const { t, i18n } = useTranslation();
    const rows = useSelector((state) => state.recruiterSearchAdmin.data);
    const meta = useSelector((state) => state.recruiterSearchAdmin.meta);
    const pageNumber = useSelector((state) => state.recruiterSearchAdmin.pageNumber);
    const [searchParams, setSearchParams] = useSearchParams()
    const company_id = 99;

    const [search, setSearch] = useState("");


    const recruitersColumns = [
        {
            field: 'id',
            headerName: '#',
            width: 90,
            align: "center",
            headerAlign: "center"
        },
        {
            field: 'companyName',
            headerName: t('labels.company_name'),
            width: 220,
            // align: "center" , 
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        <Avatar src={params.value.image} className="img" />
                        <p className='name'>{params.value.name} </p>
                    </>
                )
            }
        },
        {
            field: 'location',
            headerName: t('labels.location'),
            width: 220,
            align: "center",
            headerAlign: "center"
        },
        {
            field: 'companyIndustry',
            headerName: t('labels.review_industry'),
            width: 220,
            align: "center",
            headerAlign: "center"
        },

        {
            field: 'status',
            headerName: t('others.status'),
            minWidth: 120,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) =>
                <p className={params.value === 'Blocked' ? 'red-color' : 'gray-color'}>
                    {params.value}
                </p>
        },
        {
            field: 'view',
            headerName: t('others.view'),
            minWidth: 120,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => <Eye size={19} onClick={() => navigate(`/recruiters/${params.id}`)} />
        },
    ]

    const recruitersRows = rows.map(row => (
        {
            id: row?.id,
            companyName: {
                image: row?.image,
                name: row?.company_name,
            },
            location: `${translateField(row?.City, "name")} ,${translateField(row?.Governorate, "name")} ,${translateField(row?.Country, "name")} `,
            companyIndustry: `${translateField(row?.CompanyIndustry, "name")}`,
            status: `${row?.is_blocked === null ? 'Active' : 'Blocked'}`,
        }
    ))

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isMd = useMediaQuery('(min-width:1167px)');
    const isXs = useMediaQuery('(max-width:800px)');
    const handleSearch = (search) => {
        dispatch(getPageOfSearchAdminRecruiter(search, 10, 1))
        console.log(search)
    }

    useEffect(() => {
        console.log("listening......")
        dispatch(filterRecruitersAction(searchParams.toString(), 10, 1))
        console.log(searchParams.toString())
    }, [searchParams])

    return (
        <div style={{ width: "100%" }}>
            <Grid
                container
                justifyContent={"flex-start"}
                spacing={2}
                alignItems={"center"}
                mb={2}
            >
                <Grid item xs={8} md={isMd ? 4 : 12} >
                    <SearchBar
                        fetchSearchPage={getPageOfSearchAdminRecruiter}
                        fetchPage={getPageOfAdminRecruiter}
                        isPaginated={true}
                        handleSearch={handleSearch}
                        search={search}
                        setSearch={setSearch}
                    />
                </Grid>
                {isXs ? (
                    <Grid item container justifyContent="flex-end" xs={4}>
                        <IconButton
                            className="btn"
                            sx={{
                                borderRadius: "8px",
                                height: "40px"
                            }}
                        >
                            <Faders color="#3A3B81" size={20} weight="fill" />
                        </IconButton>
                    </Grid>
                ) :
                    <Grid item xs={12} md={isMd ? 6 : 12} >
                        <RecruitersFilter />
                    </Grid>
                }
                {/* <Show condition={responsiveFilterState}>
                    <Grid
                        item
                        mt={1}
                        component={"section"}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={4}
                    >
                        <ResponsiveFilter
                            setData={setDataToRender}
                            page={page}
                            setNumberOfPages={setNumberOfPages}
                            getValues={getValues}
                            reset={reset}
                            control={control}
                        />
                    </Grid>
                </Show> */}
            </Grid>
            <div>
                <DataTable
                    rows={recruitersRows}
                    columns={recruitersColumns}
                    filter={searchParams.toString()}
                    totalNumberOfPages={meta?.totalNumberOfPages}
                    pageNumber={pageNumber}
                    fetchPage={getPageOfAdminRecruiter}
                    isPaginated={meta?.totalNumberOfPages > 1}
                />
            </div>
        </div>
    );
};

export default Recruiters;