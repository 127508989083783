import {
  SEARCH_FILTER,
  SENIORITYLEVELS_FILTER,
  NATIONALITIES_FILTER,
  DEGREES_FILTER,
  GRADES_FILTER,
  COUNTRIES_FILTER,
  GOVERNORATES_FILTER,
  CITIES_FILTER,
  JOBTYPES_FILTER,
  SALARIES_FILTER,
  MAX_FILTER,
  MIN_FILTER,
  CAREERLEVELS_FILTER,
  JOBINDUSTRIES_FILTER,
  COMPANYINDUSTRIES_FILTER,
  PEOPLE,
  COMPANIES,
  JOBS,
  RESPONSIVE_FILTER_TOGGLE,
  RESPONSIVE_FILTER_SET,
  RESPONSIVE_FILTER_UNSET,
  RESPONSIVE_COUNTRIES_FILTER,
  RESPONSIVE_GOVERNORATES_FILTER,
  RESPONSIVE_COUNTRIES_FILTER_REMOVE,
  RESPONSIVE_GOVERNORATES_FILTER_REMOVE
} from "../../actions/filter/types";

let initialState = {
  people: false,
  companies: false,
  jobs: false,
  responsiveFilterState: false,
  activeFilter: "people",
  search: "",
  seniorityLevels: [],
  nationalities: [],
  degrees: [],
  grades: [],
  countries: [],
  governorates: [],
  cities: [],
  jobTypes: [],
  salaries: [],
  careerLevels: [],
  jobIndustries: [],
  companyIndustries: [],
  min: null,
  max: null,
};

export const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  let selectedCountries;
  let selectedGovernorates;
  console.log(type, payload);
  switch (type) {
    case SEARCH_FILTER:
      return {
        ...state,
        search: payload,
      };
    case SENIORITYLEVELS_FILTER:
      return {
        ...state,
        seniorityLevels: payload,
      };
    case NATIONALITIES_FILTER:
      return {
        ...state,
        nationalities: payload,
      };
    case DEGREES_FILTER:
      return {
        ...state,
        degrees: payload,
      };
    case GRADES_FILTER:
      return {
        ...state,
        grades: payload,
      };
    case COUNTRIES_FILTER:
      return {
        ...state,
        countries: payload,
      };
    case RESPONSIVE_COUNTRIES_FILTER:
      console.log("selectedCountries");
      selectedCountries = [...state.countries];
      selectedCountries.push(payload);
      console.log("selectedCountries", selectedCountries);
      return {
        ...state,
        countries: selectedCountries,
      };
    case RESPONSIVE_COUNTRIES_FILTER_REMOVE:
      console.log("selectedCountries");
      selectedCountries = [...state.countries];
      selectedCountries.splice(selectedCountries.indexOf(payload), 1);
      selectedCountries.push(payload);
      console.log("selectedCountries", selectedCountries);
      return {
        ...state,
        countries: selectedCountries,
      };
    case GOVERNORATES_FILTER:
      return {
        ...state,
        governorates: payload,
      };
    case RESPONSIVE_GOVERNORATES_FILTER:
      selectedGovernorates = [...state.governorates];
      selectedGovernorates.push(payload);
      return {
        ...state,
        governorates: selectedGovernorates,
      };
    case RESPONSIVE_GOVERNORATES_FILTER_REMOVE:
      selectedGovernorates = [...state.governorates];
      selectedGovernorates.splice(selectedGovernorates.indexOf(payload), 1);
      selectedGovernorates.push(payload);
      return {
        ...state,
        governorates: selectedGovernorates,
      };
    case CITIES_FILTER:
      return {
        ...state,
        cities: payload,
      };
    case JOBTYPES_FILTER:
      return {
        ...state,
        jobTypes: payload,
      };
    case SALARIES_FILTER:
      return {
        ...state,
        salaries: payload,
      };
    case MIN_FILTER:
      return {
        ...state,
        min: payload,
      };
    case MAX_FILTER:
      return {
        ...state,
        max: payload,
      };
    case CAREERLEVELS_FILTER:
      return {
        ...state,
        careerLevels: payload,
      };
    case JOBINDUSTRIES_FILTER:
      return {
        ...state,
        jobIndustries: payload,
      };
    case COMPANYINDUSTRIES_FILTER:
      return {
        ...state,
        companyIndustries: payload,
      };
    case PEOPLE:
      return {
        ...state,
        companies: false,
        jobs: false,
        people: true,
        activeFilter: "people",
      };
    case COMPANIES:
      return {
        ...state,
        jobs: false,
        people: false,
        companies: true,
        activeFilter: "companies",
      };
    case JOBS:
      return {
        ...state,
        companies: false,
        people: false,
        jobs: true,
        activeFilter: "jobs",
      };
    case RESPONSIVE_FILTER_TOGGLE:
      return {
        ...state,
        responsiveFilterState: !state.responsiveFilterState,
      };
    case RESPONSIVE_FILTER_SET:
      return {
        ...state,
        responsiveFilterState: true,
      };
    case RESPONSIVE_FILTER_UNSET:
      return {
        ...state,
        responsiveFilterState: false,
      };
    default:
      return state;
  }
};

export default filterReducer;
