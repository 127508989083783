import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material';
import { getDocumentDirection, numberWithCommas } from '../../../../services/Helper';
import "./StatsCard.scss"


const StatsCard = ({ cardTitle, stats, iconTop, iconEnd }) => {
    const textAlignment = getDocumentDirection()
    return <Box className="stats-card-wrapper" >
        <Grid container paddingX={4} alignItems="center" align={textAlignment === "right" ? "right" : "left"}>
            <Grid item xs={8} marginY={3}>
                <Stack
                    direction={"column"}
                    spacing={3}
                >
                    <Typography className="stats-title">{cardTitle}</Typography>
                    <Typography className="stats-count">{numberWithCommas(stats)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} align={textAlignment === "right" ? "left" : "right"} >
                <Box className="icon-top-wrapper">
                    {iconEnd && iconEnd}
                </Box>
            </Grid>
        </Grid>
    </Box>
}

export default StatsCard