import { Button, Grid } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import DataTable from '../../components/data-table/DataTable';
import useAppLanguages from './useAppLanguages';

const AppLanguages = () => {
    const { langs, columns, handleOpenAddModal, AddModal, EditModal, RenderDeleteModal } = useAppLanguages()

    return (
        <>
            <Grid container rowSpacing={3}>
                <Grid item xs={12} align={"right"} >
                    <Button onClick={handleOpenAddModal} className="add-btn">
                        {t("buttons.add_new")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        rows={langs ? langs : []}
                    />
                </Grid>
            </Grid>
            <EditModal />
            <RenderDeleteModal />
            <AddModal />
        </>
    )
}

export default AppLanguages
