import { Button, Dialog, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import IconInput from "./../../../../components/IconInput";
import "./EditModal.css";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { editModel } from "../../../../services/Helper";
import JLSelect from "../../../../components/JLSelect";

const EditModal = ({
  openEditModal,
  openHandler,
  modelName,
  currentRow,
  refresh,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  let editSchema = {
    name_en: yup
      .string()
      .required(t("validations.required_input"))
      .matches(
        /^[a-zA-Z0-9 \\#\[\]\",/~`'!@$%^&*()_+={}|;<>.?:]+$/,
        t("validations.only_english_alphapet")
      ),
  };
  if (modelName !== "Grade") {
    editSchema.name_ar = yup
      .string()
      .required(t("validations.required_input"))
      .matches(
        /^[\u0621-\u064A0-9 \\#\[\]\",/~`'!@$%^&*()_+={}|;<>.?:]+$/,
        t("validations.only_arabic_alphapet")
      );
  }
  const modelSchema = yup.object(editSchema).required();
  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(modelSchema),
  });
  const onSubmit = (data) => {
    setLoading(true);
    if (modelName === "Country") {
      delete data.id_name;
    }
    const modelData = {
      ...data,
      model: modelName,
      model_id: `${currentRow.id}`,
    };
    editModel(modelData, true)
      .then((res) => {
        console.log(res);
        setRefresh(!refresh);
        openHandler(false);
        clearErrors();
        reset();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCancel = () => {
    openHandler(false);
    setValue("name_en", currentRow?.name_en);
    setValue("name_ar", currentRow?.name_ar);
    clearErrors();
  };
  // const handleModelEdit = () => {
  //     openHandler(false)
  // }

  console.log(currentRow);
  useEffect(() => {
    setValue("name_en", currentRow?.name_en);
    setValue("name_ar", currentRow?.name_ar);
    if (modelName === "Country") {
      setValue("id_name", currentRow?.id);
    }
  }, [currentRow]);
  return (
    <Dialog open={openEditModal} className="edit-category-modal" disableScrollLock={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography className="edit-modal-title">
              {t("others.edit")} {t(`models.${modelName}`)}
            </Typography>
          </Grid>
          {modelName === "Country" && (
            <Grid item xs={12}>
              <IconInput
                label={t("labels.country_id")}
                id="country_id"
                placeholder={t("placeholders.enter_country_id")}
                fieldName="id_name"
                // id="job_title"
                type="text"
                className="w100 "
                searchHome={true}
                onChange={(e) => console.log(e.target.value)}
                register={register}
                error={errors?.id_name}
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IconInput
              label={t("others.name_in_english")}
              // id="job_title"
              placeholder={t("placeholders.enter_name")}
              type="text"
              className="w100 "
              searchHome={true}
              // value={currentRow?.name_en}
              fieldName="name_en"
              register={register}
              error={errors?.name_en}
            />
          </Grid>
          <Grid item xs={12}>
            <IconInput
              label={t("labels.name_in_arabic")}
              // id="job_title"
              placeholder={t("placeholders.enter_name")}
              type="text"
              className="w100"
              searchHome={true}
              // value={currentRow?.name_ar}
              fieldName="name_ar"
              register={register}
              error={errors?.name_ar}
              onChange={(e) => {
                setValue("name_ar", e.target.value);
              }}
            />
          </Grid>
          {(modelName === "Country" || modelName === "Governorate") && (
            <Grid item xs={12}>
              <JLSelect
                control={control}
                fieldName={modelName === "Country" ? "countryStatus" : "governorateStatus"}
                label={"Status"}
                // selectLabel={"Disabled"}
                defaultValue={0}
                className="new-job-input"
                childs={[{id: 1, name_en: "Enabled"}, {id: 0, name_en: "Disabled"}]}
                error={errors?.status}
              />
            </Grid>
          )}
          <Grid item xs={12} container columnSpacing={2}>
            <Grid item xs={6}>
              <Button className="btn-cancel" onClick={handleCancel}>
                {t("buttons.cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className="btn-add" type="submit" disabled={loading}>
                {t("others.save")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default EditModal;
