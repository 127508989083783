import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Show from "../Show";
import "./PageBack.css";

const PageBack = ({ title, link, onClick }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <Box className="page-back">
      <Grid container direction="row" columnSpacing={1} alignItems="center">
        <Grid
          item
          onClick={() => onClick ? onClick() : navigate(link)}
        >
          <IconButton className="icon">
            <Show condition={i18n.language == "ar"}>
              <CaretRight size={20} />
            </Show>
            <Show condition={i18n.language !== "ar"}>
              <CaretLeft size={20} />
            </Show>
          </IconButton>
        </Grid>
        <Grid item>
          <Typography className="title">{t(title)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PageBack;
