

export const GET_PAGE_OF_APPLLICANT = 'GET_PAGE_OF_APPLLICANT'
export const GET_SEARCH_OF_APPLLICANT = 'GET_SEARCH_OF_APPLLICANT'
// export const GET_COUNTRY_BY_FILTER = ' GET_COUNTRY_BY_FILTER '
export const FILTER_APPLICANTS = "FILTER_APPLICANTS"

export const FILTER_APPLICANTS_BY_COUNTRY = "FILTER_APPLICANTS_BY_COUNTRY"
export const FILTER_APPLICANTS_BY_GENDER = "FILTER_APPLICANTS_BY_GENDER"

export const FILTER_APPLICANTS_BY_STATUS = "FILTER_APPLICANTS_BY_STATUS"

export const FILTER_APPLICANTS_BY_NATIONALITY = "FILTER_APPLICANTS_BY_NATIONALITY"


