import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import emptyPage from '../../assets/empty-page.json';
import DecisionButton from '../../components/decision-button/DecisionButton';
import ErrorPage from '../../components/error-page/ErrorPage';
import Loader from "../../components/loader/Loader";
import MiniJobCard from "../../components/mini-job-card/MiniJobCard";
import { readProfileOfApplicantAndRecruiter, translateField } from "../../services/Helper";
import "./CompanyProfile.scss";
import ProfileCard from "./components/company-profile-card/ProfileCard";

const CompanyProfile = () => {
  const navigate = useNavigate()
  const params = useParams();
  const { t, i18n } = useTranslation();

  const [profile, setProfile] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false)
  const [active, setActive] = useState(1);
  const activeTextColor = active ? "#fff" : "#3A3B81";
  const inactiveTextColor = active ? "#3A3B81" : "#fff";
  const [deleteFromMiniJobCard, setDeleteFromMiniJobCard] = useState()





  useEffect(() => {
    readProfileOfApplicantAndRecruiter(active, params.company_id).then((data) => {
      setProfile(data.recruiter);
      setJobs(data.job_list.rows);
      setJobsLoaded(true);
      setDeleteFromMiniJobCard(false)
    });
  }, [params.company_id, active, deleteFromMiniJobCard, fetchTrigger]);




  return (
    <Grid container direction="row" className="company-profile-page">
      <Loader data={jobsLoaded} width={"100%"} height={350} style={{ borderRadius: "1rem" }}>
        <Grid item xs={12}>
          <ProfileCard
            name={profile?.company_name}
            logo={profile?.image}
            phone={profile?.mobile}
            email={profile?.work_email}
            location={`${translateField(profile?.City?.Governorate?.Country, "name")}, ${translateField(profile?.City?.Governorate, "name")}, ${translateField(profile?.City, "name")}`}
            description={profile?.bio}
            industry={translateField(profile?.CompanyIndustry, "name")}
            companySize={translateField(profile?.CompanySize, "name")}
            followed={profile?.is_followed}
            id={profile?.id}
            blocked={profile?.is_blocked === null ? false : true}
            userData={profile}
            setFetchTrigger={setFetchTrigger}
          />
        </Grid>
      </Loader>

      <Loader data={jobsLoaded} width={"100%"} height={350} style={{ borderRadius: "1rem" }}>
        <Grid item xs={12}>

          <Typography mt={3} mb={2} className={"jobs-title"}>
            {t("labels.jobs")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"flex-start"}
          >
            <Box
              sx={{
                width: "118px"
              }}
            >
              <DecisionButton
                active={active}
                rounded
                onClick={() => setActive(1)}
              >
                <Typography color={activeTextColor} className="standard-text ">
                  {t("labels.active_jobs")}
                </Typography>
              </DecisionButton>
            </Box>
            <Box
              sx={{
                width: "118px"
              }}>
              <DecisionButton
                active={!active}
                rounded
                onClick={() => setActive(0)}
              >
                <Typography color={inactiveTextColor} className="standard-text">
                  {t("labels.inactive_jobs")}
                </Typography>
              </DecisionButton>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {jobs.length > 0 ? (
            <>
              <Grid container direction="column">
                <Grid item xs={12} style={{ margin: "20px 0" }}>
                  <Grid container direction="row" columnSpacing={1}>
                  </Grid>
                </Grid>
                <Grid item xs={12} container direction="row" spacing={2}>
                  {jobs.map((value, index) => {
                    console.log(value)
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={4} className="mb-16">
                        <MiniJobCard
                          onClick={() => {
                            navigate(`/jobs/${value?.id}`)
                          }}
                          showLogo
                          noOfApplicants={value?.count}
                          logo={value?.Recruiter?.image}
                          company={value?.Recruiter?.company_name}
                          date={value?.created_at}
                          location={`${translateField(value?.Country, "name")}, ${translateField(value?.Governorate, "name")}, ${translateField(value?.City, "name")}`}
                          jobTitle={translateField(value?.JobTitle, "name")}
                          type={translateField(value?.ExperienceType, "name")}
                          active={value?.status}
                          jobId={value?.id}
                          selectable
                          url={`https://${window.location.host}/jobs/${value?.id}`}
                          setDeleteFromMiniJobCard={setDeleteFromMiniJobCard}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </>

          ) : (
            <>
              <Box style={{ marginTop: "30px" }}>
                <ErrorPage
                  hideAction
                  title={t("labels.oops")}
                  description={t("labels.no_jobs")}
                  action={() => { navigate("/") }}
                  actionText={"Back To Home"}
                  animationData={emptyPage}
                />
              </Box>
            </>
          )}
        </Grid>
      </Loader >
    </Grid >
  );
};

export default CompanyProfile;
