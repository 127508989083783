export const FILTER = "FILTER";
export const SEARCH_FILTER = "SEARCH_FILTER";
export const SENIORITYLEVELS_FILTER = "SENIORITYLEVELS_FILTER";
export const NATIONALITIES_FILTER = "NATIONALITIES_FILTER";
export const DEGREES_FILTER = "DEGREES_FILTER";
export const GRADES_FILTER = "GRADES_FILTER";
export const COUNTRIES_FILTER = "COUNTRIES_FILTER";
export const GOVERNORATES_FILTER = "GOVERNORATES_FILTER";
export const CITIES_FILTER = "CITIES_FILTER";
export const JOBTYPES_FILTER = "JOBTYPES_FILTER";
export const SALARIES_FILTER = "SALARIES_FILTER";
export const MAX_FILTER = "MAX_FILTER";
export const MIN_FILTER = "MIN_FILTER";
export const CAREERLEVELS_FILTER = "CAREERLEVELS_FILTER";
export const JOBINDUSTRIES_FILTER = "JOBINDUSTRIES_FILTER";
export const COMPANYINDUSTRIES_FILTER = "COMPANYINDUSTRIES_FILTER";
export const PEOPLE = "PEOPLE";
export const COMPANIES = "COMPANIES";
export const JOBS = "JOBS";
export const RESPONSIVE_FILTER_TOGGLE = "RESPONSIVE_FILTER_TOGGLE";
export const RESPONSIVE_FILTER_SET = "RESPONSIVE_FILTER_SET";
export const RESPONSIVE_FILTER_UNSET = "RESPONSIVE_FILTER_UNSET";
export const RESPONSIVE_COUNTRIES_FILTER = "RESPONSIVE_COUNTRIES_FILTER";
export const RESPONSIVE_COUNTRIES_FILTER_REMOVE = "RESPONSIVE_COUNTRIES_FILTER_REMOVE";
export const RESPONSIVE_GOVERNORATES_FILTER = "RESPONSIVE_GOVERNORATES_FILTER";
export const RESPONSIVE_GOVERNORATES_FILTER_REMOVE = "RESPONSIVE_GOVERNORATES_FILTER_REMOVE";
