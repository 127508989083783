import { Avatar, Grid, IconButton, useMediaQuery } from '@mui/material';
import { Eye, Faders } from "phosphor-react";
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataTable from '../../components/data-table/DataTable';
import { translateField } from '../../services/Helper';
import { filterApplicantsByNationality, filterApplicantsByStatus, getPageOfAdminApplicant, getPageOfSearchAdminApplicant } from '../../state/actions/ApplicantSearchAdmin/ApplicantSearchAdmin';
import { RESPONSIVE_FILTER_SET } from '../../state/actions/filter/types';
import SearchBar from '../search-page/components/search-bar/SearchBar';
import { filterApplicantsByCountry, filterApplicantsByGender, generalFilter } from './../../state/actions/ApplicantSearchAdmin/ApplicantSearchAdmin';
import ApplicantsFilter from './components/applicants-filter/ApplicantsFilter';



const Applicants = () => {

    const { t, i18n } = useTranslation();

    const [search, setSearch] = useState("");

    const rows = useSelector((state) => state.applicantSearchAdmin.data);
    const meta = useSelector((state) => state.applicantSearchAdmin.meta);
    const pageNumber = useSelector((state) => state.applicantSearchAdmin.pageNumber);
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const applicantsColumns = [
        {
            field: 'id',
            headerName: '#',
            width: 80,
            align: "center",
            headerAlign: "center"
        },
        {
            field: 'applicant',
            headerName: t('labels.applicant'),
            width: 200,
            // align: "center" , 
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        <Avatar src={params.value.image} className="img" />
                        <p className='name'>{params.value.firstName} </p>
                    </>
                )
            }
        },
        {
            field: 'location',
            headerName: t('labels.location'),
            width: 180,
            headerAlign: "center"
        },
        {
            field: 'gender',
            headerName: t('labels.general_gender'),
            width: 140,
            align: "center",
            headerAlign: "center"
        },
        {
            field: 'nationality',
            headerName: t('others.nationality_placeholder'),
            width: 180,
            align: "center",
            headerAlign: "center"
        },

        {
            field: 'status',
            headerName: t('others.status'),
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) =>
                <p className={params.value === 'Blocked' ? 'red-color' : 'gray-color'}>
                    {params.value}
                </p>
        },
        {
            field: 'view',
            headerName: t('others.view'),
            minWidth: 90,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => <Eye size={19} onClick={() => navigate(`/applicants/${params.id}`)} />
        },
    ]
    const applicantRows = rows.map(row => (
        {
            id: row?.id,
            applicant: {
                image: row?.image,
                firstName: row?.first_name
            },
            location: `${translateField(row?.City, "name")} ,${translateField(row?.Governorate, "name")} ,${translateField(row?.Country, "name")} `,
            gender: row?.Gender.name_en,
            nationality: `${translateField(row?.UserNationalities?.[0]?.Nationality, "name")}`,
            status: `${row?.is_blocked === null ? 'Active' : 'Blocked'}`,
        }
    ));

    const {
        control,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const isMd = useMediaQuery('(min-width:1167px)');
    const isXs = useMediaQuery('(max-width:800px)');
    const handleSearch = (search) => {
        dispatch(getPageOfSearchAdminApplicant(search, 10, 1))
    }
    useEffect(() => {
        console.log("listening......")
        dispatch(generalFilter(searchParams.toString(), 10, 1))
        console.log(searchParams.toString())
    }, [searchParams])
    const getApplicants = () => {
        if (searchParams.get("country")) {
            dispatch(filterApplicantsByCountry(searchParams.get("country"), 10, 1))
            console.log(searchParams.get("country"))
        }
        if (searchParams.get("status")) {
            dispatch(filterApplicantsByStatus(searchParams.get("status"), 10, 1))
            console.log(searchParams.get("country"))
        }
        if (searchParams.get("gender")) {
            dispatch(filterApplicantsByGender(searchParams.get("gender"), 10, 1))
            console.log(searchParams.get("country"))
        }
        if (searchParams.get("nationality")) {
            dispatch(filterApplicantsByNationality(searchParams.get("nationality"), 10, 1))
            console.log(searchParams.get("nationality"))
        }
    }
    useEffect(() => {
        getApplicants()
    }, [])
    return (
        <div style={{ width: "100%" }}>
            <Grid
                container
                justifyContent={"flex-start"}
                spacing={2}
                alignItems={"center"}
                mb={2}
            >
                <Grid item xs={8} md={isMd ? 4 : 12} >
                    <SearchBar
                        fetchSearchPage={getPageOfSearchAdminApplicant}
                        fetchPage={getPageOfAdminApplicant}
                        handleSearch={handleSearch}
                        setSearch={setSearch}
                        search={search}
                        isPaginated={true}
                    />
                </Grid>
                {isXs ? (
                    <Grid item container justifyContent="flex-end" xs={4}>
                        <IconButton
                            className="btn"
                            onClick={() => {
                                dispatch({ type: RESPONSIVE_FILTER_SET });
                            }}
                            sx={{
                                borderRadius: "8px",
                                height: "40px"
                            }}
                        >
                            <Faders color="#3A3B81" size={20} weight="fill" />
                        </IconButton>
                    </Grid>
                ) :
                    <Grid item xs={12} md={isMd ? 6 : 12}  >
                        <ApplicantsFilter />
                    </Grid>
                }
                {/* <Show condition={responsiveFilterState}>
                    <Grid
                        item
                        mt={1}
                        component={"section"}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={4}
                    >
                        <ResponsiveFilter
                            setData={setDataToRender}
                            page={page}
                            setNumberOfPages={setNumberOfPages}
                            getValues={getValues}
                            reset={reset}
                            control={control}
                        />
                    </Grid>
                </Show> */}
            </Grid>
            <div>
                <DataTable
                    rows={applicantRows}
                    columns={applicantsColumns}
                    totalNumberOfPages={meta?.totalNumberOfPages}
                    filter={searchParams.toString()}
                    fetchPage={getPageOfAdminApplicant}
                    pageNumber={pageNumber}
                    onClick={() => console.log('ssssssssssssssss')}
                    isPaginated={meta?.totalNumberOfPages > 1}
                />
            </div>
        </div>
    );
};

export default Applicants;