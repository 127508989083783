import { ASSIGN_ROLE, DELETE_PROFILE, FETCH_PROFILE, ADD_SKILL, UPDATE_PROFILE, UPDATE_STEP_FINISHED, DELETE_PROFILE_IMAGE, LOAD_PROFILE, CHANGE_DEFAULT_ROLE } from '../../actions/profile/types';
import * as dotProp from "dot-prop-immutable"
import { INITAL_ROLE } from '../../../constants/permissions';
let initialState = {
    data: null,
    role: INITAL_ROLE,
    loginCompleted: false,
    loaded: false
}

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROFILE:
            return {
                ...state,
                data: {
                    ...state.data,
                    cv: {
                        ...state.data?.cv,
                        User: {
                            ...state.data?.cv?.User,
                            ...action.payload?.cv?.User
                        }
                    },
                    recruiter: action.payload?.recruiter,
                    azure_account: action.payload?.azure_account,
                    role: action.payload?.role,

                },
                loaded: true

            };
        case DELETE_PROFILE:
            return { ...state, data: null, role: INITAL_ROLE, loginCompleted: false, loaded: true };
        case UPDATE_PROFILE:

            return {
                ...state,
                data: {
                    ...state.data,
                    cv: {
                        ...state.data?.cv,
                        User: {
                            ...state.data?.cv?.User,
                            ...action.payload?.User
                        }
                    },
                    recruiter: {
                        ...state.data?.recruiter,
                        ...action.payload?.recruiter
                    },
                    role: {
                        ...state.data?.role,
                        ...action.payload?.role
                    }
                }
            }

            return state;
        case UPDATE_STEP_FINISHED:
            return {
                ...state,
                data: {
                    ...state.data,
                    cv: {
                        ...state.data.cv,
                        User: {
                            ...state.data.cv.User,
                            step_finished: action.payload.step_finished
                        }
                    }
                }
            }
        case ASSIGN_ROLE:
            return { ...state, role: action.payload, loginCompleted: true };
        case DELETE_PROFILE_IMAGE:
            return {
                ...state,
                data: {
                    ...state.data,
                    cv: {
                        ...state.data.cv,
                        User: {
                            ...state.data.cv.User,
                            image: null
                        }
                    }
                }
            }
        case CHANGE_DEFAULT_ROLE:
            return {
                ...state,
                data: {
                    ...state.data,
                    azure_account: action.payload
                },
            }
        case ADD_SKILL:
            return { ...state, user: action.payload };
        case LOAD_PROFILE:
            return { ...state, loaded: true }
        default:
            return state;
    }
}

export default profileReducer;
