import React from 'react';
import StaticPage from '../static-page/StaticPage';

const PrivacyPolicy = () => {


    return (
        <>
            <StaticPage pageKey={"PrivacyPolicy"} />
        </>
    );
};

export default PrivacyPolicy;