
import PageHeader from '../components/page-header/PageHeader';
import Playground from '../components/Playground';
import ApplicantCvProfile from "../views/applicant-cv-profile/ApplicantCvProfile";
import Applicants from "../views/applicants/Applicants";
import Category from "../views/category/Category";
import DashboardHome from "../views/dashboard-home/DashboardHome";
import JobDetails from "../views/job-details/JobDetails";
import ForgetPassword from '../views/pages/forget-password/ForgetPassword';
import VerifyOTP from '../views/pages/otp/VerifyOTP';
import Page400 from "../views/pages/page400/Page400";
import Page401 from "../views/pages/page401/Page401";
import Page403 from "../views/pages/page403/Page403";
import Page404 from "../views/pages/page404/Page404";
import ResetPasswordVerification from '../views/pages/reset-password-verification/ResetPasswordVerification';
import PrivacyPolicy from '../views/privacy-policy/PrivacyPolicy';
import Recruiters from "../views/recruiters/Recruiters";
import SendNotificationsHeader from "../views/send-notification/components/send-notifications-header/SendNotificationsHeader";
import SendNotification from "../views/send-notification/SendNotification";
import Settings from "../views/settings/Settings";
import TermsConditions from '../views/terms-conditions/TermsConditions';
import PageBack from './../components/page-back/PageBack';
import AppLanguages from './../views/app-languages/AppLanguages';
import CategoryHeader from './../views/category/components/header/CategoryHeader';
import CompanyProfile from "./../views/Company-profile-page/CompanyProfile";
import DashboardHomeHeader from './../views/dashboard-home/components/dashboard-header/DashboardHomeHeader';

export const dashboardRoutes = [
  {
    path: "/",
    name: "Dashboard Home",
    component: DashboardHome,
    pageHeader: DashboardHomeHeader,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "applicants",
    parent: "/",
    name: "Applicants List",
    component: Applicants,
    pageHeader: <PageHeader title={"others.applicants"} translate />,
    // pageHeader: null,
    permissionRequired: false,
    permission: null,
    activeSidebar: "applicants",
  },
  {
    path: "applicants/:applicant_id",
    parent: "/",
    name: "Applicant Cv Profile",
    component: ApplicantCvProfile,
    pageHeader: null,
    permissionRequired: false,
    permission: null,
    activeSidebar: "applicants",
  },
  {
    path: "recruiters",
    parent: "/",
    name: "Recruiters List",
    component: Recruiters,
    pageHeader: <PageHeader title={"labels.recruiters"} translate />,
    permissionRequired: false,
    permission: null,
    activeSidebar: "recruiters",
  },
  {
    path: "recruiters/:company_id",
    parent: "/",
    name: "Recruiter Profile",
    component: CompanyProfile,
    pageHeader: <PageHeader title={"labels.company_profile"} translate />,
    permissionRequired: false,
    permission: null,
    activeSidebar: "recruiters",
  },
  {
    path: "jobs/:job_id",
    parent: "/",
    name: "Job Detail",
    component: JobDetails,
    pageHeader: <PageBack title={"labels.job_details"} link={-1} />,
    permissionRequired: false,
    permission: null,
    activeSidebar: "recruiters",
  },
  {
    path: "send-notification",
    parent: "/",
    name: "Send Notification",
    component: SendNotification,
    pageHeader: SendNotificationsHeader,
    permissionRequired: false,
    permission: null,
    activeSidebar: "send-notification",
  },
  {
    path: "playground",
    parent: "/",
    name: "Playground",
    component: Playground,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "app-languages",
    parent: "/",
    name: "App Languages",
    component: AppLanguages,
    pageHeader: <PageBack title={"labels.app_language"} link={-1} />,
    permissionRequired: false,
    permission: null,
    activeSidebar: "app-languages",
  },
  {
    path: "category/:model_name",
    parent: "/",
    name: "Cateogry",
    component: Category,
    pageHeader: <CategoryHeader />,
    permissionRequired: false,
    permission: null,
    // activeSidebar: "send-notification",
  },
  {
    path: "settings",
    parent: "/",
    name: "Settings Page",
    component: Settings,
    pageHeader: <PageHeader title={"others.app_settings"} translate />,

    permissionRequired: false,
    permission: null,
    activeSidebar: "settings",
  },
  {
    path: "terms-and-conditions",
    parent: "/",
    name: "Terms & Conditions Page",
    component: TermsConditions,
    pageHeader: <PageHeader title={"labels.terms"} translate />,
    permissionRequired: false,
    permission: null,
    activeSidebar: "terms-conditions",
  },
  {
    path: "privacy-policy",
    parent: "/",
    name: "Privacy Policy Page",
    component: PrivacyPolicy,
    pageHeader: <PageHeader title={"labels.privacy_policy"} translate />,
    permissionRequired: false,
    permission: null,
    activeSidebar: "privacy-policy",
  },
  {
    path: "404",
    parent: "/",
    name: "Not Found",
    component: Page404,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "400",
    parent: "/",
    name: "Bad Request",
    component: Page400,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "401",
    parent: "/",
    name: "Unautharized",
    component: Page401,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "403",
    parent: "/",
    name: "Unautharized",
    component: Page403,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  // {
	// 	path: "/forget-password",
	// 	name: "Forget Password Page",
	// 	component: ForgetPassword,
	// 	permissionRequired: false,
	// 	permission: null,
	// },
  // {
  //   path: "/reset-password-verification",
  //   name: "Reset Password Verification Page",
  //   component: ResetPasswordVerification,
  //   permissionRequired: false,
  //   permission: null,
  // },
  {
    path: "/verify-otp",
    name: "Verify OTP Page",
    component: VerifyOTP,
    permissionRequired: false,
    permission: null,
  },
];
