import { Box, RadioGroup, Stack } from '@mui/material';
import React from 'react';

import { FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCompanyIndustry, getCountry, translateField } from '../../../../services/Helper';
import OneFilter from '../../../search-page/components/filter/one-filter/OneFilter';
import { getCity, getGovernorate } from './../../../../services/Helper';


// label, children, active, showActionBtns, disabled, control, id, reset, controlCloseMenu, closed, setClosed, applied



const statuses = [
    {
        name_en: "Active",
        name_ar: "مفعل",
        id: "0"
    },
    {
        name_en: "Blocked",
        name_ar: "محظور",
        id: "1"
    }
]


const RecruitersFilter = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [countries, setCountries] = useState([])
    const [countriesSearch, setCountriesSearch] = useState([]);
    const [governorates, setGovernorates] = useState([]);
    const [governoratesSearch, setGovernoratesSearch] = useState([]);
    const [cities, setCities] = useState([]);
    const [companyIndustries, setCompanyIndustries] = useState([]);
    const [companyIndustriesSearch, setCompanyIndustriesSearch] = useState([]);

    const [citiesSearch, setCitiesSearch] = useState([]);
    const [nats, setNats] = useState([])
    const {
        control,
        getValues,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getCompanyIndustry().then((companyIndustries) => {
            setCompanyIndustries(companyIndustries);
        });
        getCountry().then((countries) => {
            setCountries(countries);
        });
        // getCity().then((res) => {
        //     setCities(res)
        // })
    }, [])
    useEffect(() => {
        getGovernorate(searchParams.get("country")).then((res) => {
            setGovernorates(res)
        })
        // getCity().then((res) => {
        //     setCities(res)
        // })
    }, [searchParams.get("country")])

    useEffect(() => {
        if (searchParams.get("governorate"))
            getCity(searchParams.get("governorate")).then((res) => {
                setCities(res)
            })

    }, [searchParams.get("governorate")])
    const filterApplicantsByCity = () => {
        if (getValues().city) {
            if (searchParams.get("city"))
                searchParams.set("city", getValues().city)
            else
                searchParams.append("city", getValues().city)
            console.log(searchParams.get("city"))
        }

        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        console.log(searchParams.get("nationality"))
        navigate(`/recruiters?${searchParams.toString()}`)

    }
    const filterApplicantsByGovernorate = () => {
        console.log("applying governtrate", getValues())
        if (getValues().governorate) {
            if (searchParams.get("governorate"))
                searchParams.set("governorate", getValues().governorate)
            else
                searchParams.append("governorate", getValues().governorate)
            console.log(searchParams.get("governorate"))
        }
        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        console.log(searchParams.get("nationality"))
        navigate(`/recruiters?${searchParams.toString()}`)

    }
    const filterApplicantsByCountry = () => {
        if (getValues().country) {
            if (searchParams.get("country"))
                searchParams.set("country", getValues().country)
            else
                searchParams.append("country", getValues().country)
            console.log(searchParams.get("country"))
        }
        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        console.log(searchParams.get("nationality"))
        navigate(`/recruiters?${searchParams.toString()}`)


    }
    const filterRecruitersByIndustry = () => {
        if (getValues().company_industry) {
            if (searchParams.get("company_industry"))
                searchParams.set("company_industry", getValues().company_industry)
            else
                searchParams.append("company_industry", getValues().company_industry)
            console.log(searchParams.get("company_industry"))
        }

        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        console.log(getValues())
        console.log(searchParams.get("company_industry"))
        navigate(`/recruiters?${searchParams.toString()}`)

    }
    const filterApplicantsByStatus = () => {
        if (getValues().country) {
            if (searchParams.get("company_industry"))
                searchParams.set("company_industry", getValues().company_industry)
            else
                searchParams.append("company_industry", getValues().company_industry)
            console.log(searchParams.get("company_industry"))
        }
        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        console.log(searchParams.get("nationality"))
        navigate(`/recruiters?${searchParams.toString()}`)

    }

    const handleReset = (filterToReset) => {
        if (filterToReset === "governorate") {
            searchParams.delete(filterToReset)
            searchParams.delete("city")
        }
        if (filterToReset === "country") {
            searchParams.delete(filterToReset)
            searchParams.delete("governorate")
            searchParams.delete("city")


        }
        searchParams.delete(filterToReset)
        navigate(`/recruiters?${searchParams.toString()}`)
        reset()
    }
    return (
        <Stack
            direction={"row"}
            columnGap={2}
        >
            <Box>
                <OneFilter
                    label={t("labels.country")}
                    actionBtns={true}
                    active={searchParams.get("country")}
                    showActionBtns={true}
                    id={"country"}
                    reset={() => handleReset("country")}
                    control={getValues}
                    applyFilterFunction={filterApplicantsByCountry}
                >
                    {countries.map((country) => {
                        console.log(country)
                        return <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup aria-label={"country"}{...field}
                                    defaultValue={searchParams.get("country") && searchParams.get("country")}
                                >
                                    <FormControlLabel
                                        value={country.id}
                                        control={<Radio />}
                                        label={translateField(country, "name")}
                                    />
                                </RadioGroup>
                            )}
                        />
                    })}

                </OneFilter>
            </Box>
            <Box>

                <OneFilter
                    label={t("labels.governorate")}
                    actionBtns={true}
                    active={searchParams.get("governorate")}
                    showActionBtns={true}
                    id={"governorate"}
                    reset={() => handleReset("governorate")}
                    control={getValues}
                    applyFilterFunction={filterApplicantsByGovernorate}
                    disabled={searchParams.get("country") ? false : true}
                >
                    {governorates?.map((governorate) => {
                        return <Controller
                            name="governorate"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup aria-label={"governorate"}{...field}
                                    defaultValue={searchParams.get("governorate") && searchParams.get("governorate")}
                                >
                                    <FormControlLabel
                                        value={governorate.id}
                                        control={<Radio />}
                                        label={translateField(governorate, "name")}
                                    />
                                </RadioGroup>
                            )}
                        />
                    })}

                </OneFilter>
            </Box>

            <OneFilter
                label={t("labels.city")}
                actionBtns={true}
                active={searchParams.get("city")}
                showActionBtns={true}
                id={"city"}
                reset={() => handleReset("city")}
                control={getValues}
                applyFilterFunction={filterApplicantsByCity}
                disabled={searchParams.get("governorate") ? false : true}
            >
                {cities?.map((city) => {
                    console.log(city)
                    return <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup aria-label={"city"}{...field}
                                defaultValue={searchParams.get("city") && searchParams.get("city")}
                            >
                                <FormControlLabel
                                    value={city.id}
                                    control={<Radio />}
                                    label={translateField(city, "name")}
                                />
                            </RadioGroup>
                        )}
                    />
                })}

            </OneFilter>
            <OneFilter
                label={t("labels.review_industry")}
                active={searchParams.get("company_industry")}
                showActionBtns={true}
                id={"company_industry"}
                reset={() => handleReset("company_industry")}
                control={getValues}
                applyFilterFunction={filterRecruitersByIndustry}
            >
                {companyIndustries?.map((company_industry) => {
                    return <Controller
                        name="company_industry"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                aria-label="company_industry"
                                {...field}
                                defaultValue={searchParams.get("company_industry") && searchParams.get("company_industry")}
                            >
                                <FormControlLabel
                                    value={company_industry.id}
                                    control={<Radio />}
                                    label={translateField(company_industry, "name")}
                                />
                            </RadioGroup>
                        )}
                    />
                })}
            </OneFilter>
            <OneFilter
                label={t("others.status")}
                active={searchParams.get("status")}
                showActionBtns={true}
                id={"123"}
                reset={() => handleReset("status")}
                control={getValues}
                applyFilterFunction={filterApplicantsByStatus}
            >
                {statuses?.map((status) => {
                    return <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup aria-label="status" {...field}
                                defaultValue={searchParams.get("status") && searchParams.get("status")}
                            >
                                <FormControlLabel
                                    value={status.id}
                                    control={<Radio />}
                                    label={translateField(status, "name")}
                                />
                            </RadioGroup>
                        )}
                    />
                })}
            </OneFilter>
        </Stack >
    )
}

export default RecruitersFilter