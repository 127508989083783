import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../services/auth/Auth';
import { CLOSE_LOGIN_MODAL } from '../../state/actions/login-modal/types';
import DecisionButton from '../decision-button/DecisionButton';
import "./LoginModal.css";

const LoginModal = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const loginModalOpened = useSelector((state) => state.loginModal.opened);

    const cancelAction = () => {
        dispatch({ type: CLOSE_LOGIN_MODAL })
    }

    const loginAction = () => {
        cancelAction();
        login();
    }

    return (
        <>
            <Dialog
                open={loginModalOpened}
                onClose={cancelAction}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="login-modal"
                maxWidth={"406px"}
            >
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} marginBottom={5}>
                            <Typography className="title" marginBottom={0.5}>
                                {t("labels.you_not_signed_in")}
                            </Typography>
                            <Typography className="subtitle">
                                {t("labels.need_an_account")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} marginBottom={3}>
                            <DecisionButton active onClick={() => loginAction()}>
                                <Typography color="#fff" fontSize={"14px"} fontWeight={"500"} fontFamily={"Urbanist"}>
                                    {t("buttons.login")}
                                </Typography>
                            </DecisionButton>
                        </Grid>
                        <Grid item xs={12}>
                            <DecisionButton onClick={() => cancelAction()}>
                                <Typography color="#919191" fontSize={"14px"} fontWeight={"500"} fontFamily={"Urbanist"}>
                                    {t("buttons.cancel")}
                                </Typography>
                            </DecisionButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default LoginModal;