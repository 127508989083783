import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { MapPin, GenderMale, Cake, GlobeHemisphereWest, GenderFemale } from 'phosphor-react'
import IconText from '../../../../components/icon-with-text/IconText'
import moment from 'moment'
import MaritalIcon from "../../../../assets/Marital Status.js"

import MilitaryIcon from "../../../../assets/Military Status.js"
import { translateField } from '../../../../services/Helper'
import { useTranslation } from 'react-i18next'
//user.City.Governorate.name_en
const ProfileBasicDetails = ({ user }) => {
    const { t, i18n } = useTranslation();

    const icons = [
        {
            icon: <MapPin size={17} />,
            text: `${translateField(user?.City?.Governorate?.Country, "name")}, ${translateField(user?.City?.Governorate, "name")} ,${translateField(user.City, "name")}`
        },
        {
            icon: user?.gender_id ? <GenderMale size={17} /> : <GenderFemale size={17} />,
            text: translateField(user?.Gender, "name")
        },
        {
            icon: <Cake size={17} />,
            text: user.birth_of_date ? `${t("labels.years", { count: moment().diff(user?.birth_of_date, "years") })}` : null
        },
        {
            icon: <GlobeHemisphereWest size={17} />,
            text: user?.UserNationalities?.map((nat) => translateField(nat.Nationality, "name"))
        },
        {
            icon: MaritalIcon,
            text: translateField(user?.Marital, "name")
        },

        {
            icon: MilitaryIcon,
            text: translateField(user?.Military, "name")
        },
    ]
    return (
        <Box component="section">
            <Typography component="span" className="profile-section-title">{t("labels.basic_details")}</Typography>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                my={1}
            >
                {icons.map((icon) => {
                    console.log(icon)
                    if (icon.text)
                        return <IconText
                            key={Math.random()}
                            icon={icon.icon}
                            text={typeof icon.text === "string" ? icon.text : icon.text?.map((nat, index) => {
                                let natToRender = nat
                                if ((index + 1 === icon.text.length))
                                    return natToRender
                                else
                                    return natToRender + (", ")
                            })}
                        />
                    else
                        return null
                })}
            </Stack>
        </Box>
    )
}

export default ProfileBasicDetails