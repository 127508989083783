import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { Eye, PencilSimple, Trash } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DataTable from "../../components/data-table/DataTable";
import {
  adminSearchModel,
  getDocumentDirection,
  listModelsByModelName,
  translateField
} from "../../services/Helper";
import SearchBar from "../search-page/components/search-bar/SearchBar";
import "./Category.scss";
import AddModal from "./components/add-modal/AddModal";
import DeleteModal from "./components/delete-modal/DeleteModal";
import EditModal from "./components/edit-modal/EditModal";

const Category = () => {
  const elementAlign = getDocumentDirection();
  const navigate = useNavigate();
  const { model_name } = useParams();

  let [searchParams, setSearchParams] = useSearchParams();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowsToRender, setRowsToRender] = useState([]);
  const [rowsSubset, setRowsSubset] = useState([[]]);
  const [currentRow, setCurrentRow] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState(null);
  const [governorate, setGovernorate] = useState(null);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);

  let rowsPerPage = 10;

  const getActions = (model_name) => {
    if (["Country", "Governorate"].includes(model_name)) {
      return [
        {
          field: "view",
          headerName: t("others.view"),
          width: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (data) => {
            const onClick = (e) => {
              e.stopPropagation();
              const redirect = getRedirectUrl(model_name, data.row.id);
              localStorage.setItem(model_name, JSON.stringify(data.row));
              if (model_name === "Country") {
                setCountry(data.row);
              } else if (model_name === "Governorate") {
                setGovernorate(data.row);
              }
              navigate(redirect);
            };
            return (
              <>
                <Eye
                  style={{ cursor: "pointer" }}
                  color="black"
                  size="15px"
                  onClick={onClick}
                />
              </>
            );
          },
        },
        {
          field: "edit",
          headerName: t("others.edit"),
          width: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (options) => {
            const onClick = (e) => {
              e.stopPropagation();
              handleOpenEditModal(options);
            };
            return (
              <>
                <PencilSimple
                  style={{ cursor: "pointer" }}
                  color="black"
                  size="15px"
                  onClick={onClick}
                />
              </>
            );
          },
        },
        {
          field: "delete",
          headerName: t("others.delete"),
          width: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (options) => {
            const onClick = (e) => {
              e.stopPropagation();
              handleOpenDeleteModal(options);
            };
            return (
              <Trash
                style={{ cursor: "pointer" }}
                color="red"
                size="15px"
                onClick={onClick}
              />
            );
          },
        },
      ];
    } else {
      return [
        {
          field: "edit",
          headerName: t("others.edit"),
          width: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (options) => {
            const onClick = (e) => {
              e.stopPropagation();
              handleOpenEditModal(options);
            };
            return (
              <>
                <PencilSimple
                  style={{ cursor: "pointer" }}
                  color="black"
                  size="15px"
                  onClick={onClick}
                />
              </>
            );
          },
        },
        {
          field: "delete",
          headerName: t("others.delete"),
          width: 200,
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (options) => {
            const onClick = (e) => {
              e.stopPropagation();
              handleOpenDeleteModal(options);
            };
            return (
              <Trash
                style={{ cursor: "pointer" }}
                color="red"
                size="15px"
                onClick={onClick}
              />
            );
          },
        },
      ];
    }
  };

  const getColumns = (model_name) => {
    const actions = getActions(model_name);
    let moreForModels = [];
    if (model_name === "Country") {
      moreForModels.push([
        {
          field: "dial_code",
          headerName: "Dial Code",
          width: 250,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "utc_offset",
          headerName: "UTC Offset",
          width: 250,
          align: "center",
          headerAlign: "center",
        },
      ]);
    }
    return [
      {
        field: "id",
        headerName: "#",
        width: 80,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "name_en",
        headerName: t("others.name_in_english"),
        width: 250,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "name_ar",
        headerName: t("labels.name_in_arabic"),
        width: 250,
        align: "center",
        headerAlign: "center",
      },
      // ...moreForModels
      ...actions,
    ];
  };

  const getRedirectUrl = (model_name, id) => {
    let redirect = "";
    if (model_name === "Country") {
      redirect = `/category/Governorate?country=${id}`;
    } else if (model_name === "Governorate") {
      redirect = `/category/City?country_id=${searchParams.get(
        "country"
      )}&governorate_id=${id}`;
    }
    return redirect;
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleOpenEditModal = (options) => {
    console.log("handleOpenEditModal", options.row);
    setCurrentRow(options?.row);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (options) => {
    console.log("handleOpenDeleteModal", options);
    setOpenDeleteModal(true);
    setCurrentRow(options);
  };
  const handleSearch = (searchTerm) => {
    setLoading(true);
    let searchData = {
      searchTerm: searchTerm,
      model_name,
    };
    if (searchParams.get("country"))
      searchData.country = searchParams.get("country");
    if (searchParams.get("governorate_id"))
      searchData.governorate_id = searchParams.get("governorate_id");
    adminSearchModel(searchData, searchTerm)
      .then((res) => {
        setPageNumber(1);
        setRowsToRender(res ? res : []);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const renderModals = () => {
    return (
      <>
        <AddModal
          open={openAddModal}
          openHandler={setOpenAddModal}
          modelName={model_name}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <EditModal
          openEditModal={openEditModal}
          openHandler={setOpenEditModal}
          modelName={model_name}
          currentRow={currentRow}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <DeleteModal
          open={openDeleteModal}
          openHandler={setOpenDeleteModal}
          modelName={model_name}
          currentRow={currentRow}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </>
    );
  };
  const columns = getColumns(model_name);
  useEffect(() => {
    setRowsToRender([]);
    if (model_name !== "Governorate" && model_name !== "City") {
      setCountry(null);
    }
    if (model_name !== "City") {
      setGovernorate(null);
    }
    if (model_name) {
      if (search?.trim().length > 0) {
        handleSearch(search);
      } else {
        setLoading(true);
        listModelsByModelName(
          model_name,
          searchParams.get("country"),
          searchParams.get("governorate_id")
        )
          .then((data) => {
            setRowsToRender(data ? data : []);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [model_name, refresh]);

  useEffect(() => {
    let newRows = [...rowsToRender];
    let newUpper = (newRows.length - rowsPerPage) < 0 ? newRows.length : rowsPerPage
    let newLower = 0;
    let rowsAfterSlicing = [];
    let numberOfPages = Math.ceil(newRows.length/rowsPerPage);

    for(let i = 0; i < numberOfPages; i++){
        rowsAfterSlicing.push([...newRows.slice(newLower, newUpper)]);
        newLower = newUpper;
        newUpper = (newRows.length - newUpper - rowsPerPage) < 0 ? newRows.length : newUpper + rowsPerPage;
        console.log(newRows.length)
    }

    console.log("rowsAfterSlicing", rowsAfterSlicing);
    setPageNumber(1);
    setTotalNumberOfPages(numberOfPages);
    setRowsSubset(rowsAfterSlicing);
  }, [rowsToRender]);

  return (
    <>
      <Grid container className="category-page-wrapper">
        <Grid
          item
          xs={12}
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={1}
          mb={1}
        >
          <Grid item xs={12} sm={4}>
            <SearchBar
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              loading={loading}
              reset={() => setRefresh(!refresh)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align={elementAlign === "right" ? "left" : "right"}
          >
            <Box>
              <Button onClick={handleOpenAddModal} className="add-btn">
                {t("buttons.add_new")}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} alignSelf={"flex-end"}>
            <Typography className="category-breadcrumbs">
              {country && translateField(country, "name")}
              {governorate && ` - ${translateField(governorate, "name")}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ height: "60vh" }} mb={1}>
          <DataTable
            rows={rowsSubset[pageNumber - 1] ? rowsSubset[pageNumber - 1] : []}
            columns={columns}
            isPaginated={totalNumberOfPages > 1}
            totalNumberOfPages={totalNumberOfPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "55px" }}>
          {/* <Typography> <Pagination /></Typography> */}
        </Grid>
      </Grid>
      {renderModals()}
    </>
  );
};

export default Category;