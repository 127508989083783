import React from 'react';
import ErrorPage from '../../../components/error-page/ErrorPage';
import Error404 from "../../../assets/lootie-files/404.json"
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
const Page404 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <Box sx={{ height: "90vh", width: "100%" }}>
            <Grid container justifyContent="center" alignItems="center" spacing={1} height="100%">
                <Grid item xs={12}>
                    <ErrorPage
                        title={t("labels.404")}
                        description={t("labels.404_message")}
                        animationData={Error404}
                        actionText={t("labels.back_to_home")}
                        action={() => navigate("/")}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Page404;