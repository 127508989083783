//Native CSS
import "./HomePage.scss"

//MUI imports
import { Grid } from "@mui/material"

//component re-usable content
import HomePageBanner from "./components/home-page-banner/HomePageBanner"
import HomePageSection from "./components/home-page-section/HomePageSection";
import Redirecting from "../pages/redirecting/Redirecting"
import Show from './../../components/Show';
import { useSelector } from "react-redux";
import { perm, permOr, searchInArray, getJobsByType, getFeaturedCompanies } from "../../services/Helper";
import Permissions from "../../constants/permissions";
import Jobs from "../jobs/Jobs";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { getToken } from 'firebase/messaging';
import { onMessageListener, requestForToken } from './../../firebase';
import { toastr } from 'react-redux-toastr'
import { getNotificationList } from './../../services/Helper';
import { useTranslation } from "react-i18next";
import { acquireToken } from "../../services/auth/Auth";

const companies = Array(5).fill("", 0)


const HomePage = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [jobsBasedOnInterests, setJobsBasedOnInterests] = useState([])
    const [jobsBasedOnInterestsLoaded, setJobsBasedOnInterestsLoaded] = useState(false)
    const [jobsFromFollwedCompanies, setJobsFromFollowedCompanies] = useState([])
    const [jobsFromFollwedCompaniesLoaded, setJobsFromFollowedCompaniesLoaded] = useState(false)
    const [recentlyAddedJobs, setRecentlyAddedJobs] = useState([])
    const [recentlyAddedJobsLoaded, setRecentlyAddedJobsLoaded] = useState(false)
    const [featuredJobs, setFeaturedJobs] = useState([])
    const [featuredCompanies, setFeaturedCompanies] = useState([])
    const [featuredCompaniesLoaded, setFeaturedCompaniesLoaded] = useState(false)
    const permissions = useSelector((state) => state.profile.role?.permissions);
    const loaded = useSelector((state) => state.profile.loaded);
    const role = useSelector((state) => state.profile?.role);
    const isCvCreated = useSelector((state) => state.profile?.data?.cv?.User?.is_cv_created);
    const isCompanyCreated = useSelector((state) => state.profile?.data?.recruiter?.is_company_created);

    const notCompleted = (isCvCreated == 0 || isCompanyCreated == 0)

    useEffect(() => {
        const getTokenFromB2C = async () => {
            const token = await acquireToken();
            return token;
        }
        if (role?.name !== "REGISTERD_USER") {
            if (perm(permissions, Permissions.viewInterestsJobs)) {
                getJobsByType("Interests", 10, role?.name, notCompleted).then(response => {
                    setJobsBasedOnInterests(response);
                    setJobsBasedOnInterestsLoaded(true);
                })
            }
            if (perm(permissions, Permissions.viewFollowedCompanies)) {
                getJobsByType("FollowedCompany", 6, role?.name, notCompleted).then(response => {
                    setJobsFromFollowedCompanies(response);
                    setJobsFromFollowedCompaniesLoaded(true);
                })
            }
            getTokenFromB2C().then((res) => {
                if ((res && role?.name !== "GUEST_USER") || (!res && role?.name === "GUEST_USER")) {
                    if (perm(permissions, Permissions.viewRecentlyAddedJobs)) {
                        getJobsByType("RecentlyAdded", 6, role?.name, notCompleted).then(response => {
                            console.log(response);
                            setRecentlyAddedJobs(response);
                            setRecentlyAddedJobsLoaded(true);
                        })
                    }
                }
            })
            if (perm(permissions, Permissions.viewFeaturedCompanies)) {
                getFeaturedCompanies(role?.name, notCompleted).then((response) => {
                    setFeaturedCompanies(response);
                    setFeaturedCompaniesLoaded(true);
                })
            }
        }

        return () => {
            setJobsBasedOnInterests([])
            setJobsFromFollowedCompanies([])
            setRecentlyAddedJobs([])
            setFeaturedCompanies([])
        }
        // return () => { isMounted = false };

    }, [role])


    useEffect(() => {
        if (role && role?.name === "REGISTERD_USER")
            navigate("/join-us-reason");
    }, [role])

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className="home-page"
        >
            <Show condition={permOr(permissions, Permissions.viewGuestBanner, Permissions.viewUserBanner)}>
                <Grid item xs={12}>
                    <HomePageBanner user={perm(permissions, Permissions.viewUserBanner)} />
                </Grid>
            </Show>
            {/* <Show condition={perm(permissions, Permissions.viewFeaturedJobs)}>
                <Grid item xs={12}>
                    <HomePageSection
                        title={t("labels.featured_jobs")}
                        url={"/browsing-jobs"}
                        data={featuredJobs}
                        loaded={true}
                        emptySectionData={{
                            hideAction: true
                        }}
                    />
                </Grid>
            </Show> */}
            <Show condition={perm(permissions, Permissions.viewInterestsJobs)}>
                <Grid item xs={12}>
                    <HomePageSection
                        title={t("labels.recommended_jobs")}
                        url={"/browsing-jobs?type=Interests"}
                        data={jobsBasedOnInterests}
                        loaded={jobsBasedOnInterestsLoaded}
                        emptySectionData={{
                            description: t("labels.recommended_jobs_empty"),
                            actionText: t("labels.recommended_jobs_action"),
                            action: () => {
                                navigate('/career-interests')
                            }
                        }}
                    />
                </Grid>
            </Show>
            <Show condition={perm(permissions, Permissions.viewFromFollowedJobs)}>
                <Grid item xs={12}>
                    <HomePageSection
                        title={t("labels.followed_jobs")}
                        url={"/browsing-jobs?type=FollowedCompany"}
                        data={jobsFromFollwedCompanies}
                        loaded={jobsFromFollwedCompaniesLoaded}
                        emptySectionData={{
                            description: t("labels.followed_jobs_empty"),
                            actionText: t("labels.followed_jobs_action"),
                        }}
                    />
                </Grid>
            </Show>
            <Show condition={perm(permissions, Permissions.viewFeaturedCompanies)}>
                <Grid item xs={12}>
                    <HomePageSection
                        companies={true}
                        title={t("labels.followed_companies")}
                        url={"/browsing-jobs?type=RecentlyAdded"}
                        data={featuredCompanies}
                        loaded={featuredCompaniesLoaded}
                        emptySectionData={{
                            hideAction: true
                        }}
                    />
                </Grid>
            </Show>
            <Show condition={perm(permissions, Permissions.viewRecentlyAddedJobs)}>
                <Grid item xs={12}>
                    <HomePageSection
                        micro={true}
                        title={t("labels.recent_jobs")}
                        url={"/browsing-jobs?type=RecentlyAdded"}
                        data={recentlyAddedJobs}
                        loaded={recentlyAddedJobsLoaded}
                    />
                </Grid>
            </Show>
            {/* recruiter  */}
            <Show condition={searchInArray(permissions, Permissions.viewAllJobs)}>
                <Grid item xs={12}>
                    <Jobs />
                </Grid>
            </Show>
        </Grid>
    )
}

export default HomePage