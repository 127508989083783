import { Button, Dialog, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { deleteLocale, deleteLocaleFile } from '../../services/Helper';

const DeleteModal = ({ open, row, locale, closeHandler, setTrigger }) => {

    const handleDelete = () => {
        if (locale) {
            console.log("fefefe", row)
            deleteLocaleFile(row.id).then((res) => { }).finally(() => {
                setTrigger((prev) => !prev)
                closeHandler()
            })
        }
        else {
            deleteLocale(row.id).then((res) => { }).finally(() => {
                setTrigger((prev) => !prev)
                closeHandler()
            })
        }
    }
    useEffect(() => {
        console.log(row)
    }, [])
    return (
        <Dialog open={open}>
            <Grid container
                sx={{ width: "450px", height: "fit-content", padding: "20px" }}
                rowSpacing={2}
                columnSpacing={2}
                justifyContent={"center"}
            >
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "18px" }}>
                        Are you sure you want to delete
                        <Typography sx={{ color: "red", fontSize: "18px", fontWeight: "bold" }}
                            component={"span"}>  {" "}{row.name} {locale && `${row.local_side} File`}
                        </Typography> </Typography>
                </Grid>
                <Grid item container xs={12} columnSpacing={1} justifyContent={"flex-end"}>
                    <Grid item xs={3}>
                        <Button
                            sx={{
                                '&:hover': {
                                    backgroundColor: "red",
                                    opacity: "0.6"
                                },
                                backgroundColor: "red",
                                textTransform: "capitalize",
                                width: "100%",
                                color: "white"
                            }}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button sx={{
                            '&:hover': {
                                backgroundColor: "#3A3B81",
                                opacity: "0.6"
                            },
                            backgroundColor: "#3A3B81",
                            textTransform: "capitalize",
                            width: "100%",
                            color: "white"
                        }}
                            onClick={closeHandler}
                        >
                            Cancel
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </Dialog>
    )
}

export default DeleteModal