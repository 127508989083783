import React, { useState, useTransition } from "react";
import { Button, Stack, Dialog, Grid, Typography } from "@mui/material";
import IconInput from "./../../../../components/IconInput";
import { t } from "i18next";
import "./DeleteModal.css";
import { useTranslation } from "react-i18next";
import { deleteModal, translateField } from "../../../../services/Helper";

const DeleteModal = ({
  open,
  openHandler,
  modelName,
  currentRow,
  refresh,
  setRefresh,
}) => {
  console.log(currentRow);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    openHandler(false);
    console.log(currentRow);
  };
  const handleModelDelete = () => {
    setLoading(true);
    console.log("handleModelDelete", currentRow);
    const modelData = {
      model_name: modelName,
      model_id: currentRow?.id,
    };
    deleteModal(modelData, true)
      .then((res) => {
        console.log(res);
        setRefresh(!refresh);
        openHandler(false);
      })
      .catch((error) => {
        console.log(error);
        openHandler(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} className="delete-category-modal" disableScrollLock={true}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography className="add-modal-title">
            {t(`labels.sure_delete`, { name: translateField(currentRow?.row, "name") })}
          </Typography>
        </Grid>
        <Grid item xs={12} container columnSpacing={2}>
          <Grid item xs={6}>
            <Button className="btn-cancel" onClick={handleCancel}>
              {t("buttons.cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className="btn-add"
              style={{ color: "white", backgroundColor: "#ff3939" }}
              onClick={handleModelDelete}
              disabled={loading}
            >
              {t("others.delete")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteModal;
