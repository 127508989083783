import "./SidebarElement.css";

import { Icon, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, matchRoutes, useLocation, useNavigate, useParams } from "react-router-dom";
import Show from "../../../../../components/Show";
import { dashboardRoutes } from "../../../../../routes/dashboard";

const SidebarElement = ({ id, icon, title, path, parent, opened, onClick, active }) => {
    const location = useLocation();
    const [{ route }] = matchRoutes(dashboardRoutes, location)

    const params = useParams();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const currentLang = i18n.language;

    let isActive = false;

    if (active && (active.includes(route.path) || active.includes(location.pathname)))
        isActive = true
    // if (location.pathname == path) {
    //     active = true
    // }
    // else {
    //     const currentPath = getRoutePath(location, params);
    //     const route = getRouteByPath(dashboardRoutes, currentPath);
    //     const currentSidebarElement = searchInArrayWithKey(sidebarElements, "id", route?.activeSidebar, true);
    //     // debugger
    //     console.log({
    //         currentPath,
    //         route,
    //         currentSidebarElement,
    //     })
    //     active = currentSidebarElement?.id === id;
    // }

    // console.log("testingg",{
    //     active,
    //     route,
    //     location: location.pathname
    // })


    const className = ["layout-sidebar-element", isActive ? "active" : " "].join(" ");

    return (
        <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            key={id}
            className={className}
            paddingX={opened ? 2 : 1.25}
            paddingY={1.25}
            component={Link}
            to={path}
            onClick={onClick}
            // justifyContent={opened ? "space-evenly" : "center"}
            sx={{
                width: "100%",
                // padding: opened ? "8px 16px" : "8px 8px"
            }}
        >
            <Icon className="icon">
                {icon}
            </Icon>
            <Show condition={opened}>
                <Typography className="element-title">{title}</Typography>
            </Show>
        </Stack>
    );
}
export default SidebarElement;