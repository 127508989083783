import { useEffect, useState } from "react"
import { Grid, Typography, Button, Chip, Box, Rating, Checkbox, useMediaQuery, FormControl, FormControlLabel, FormGroup, FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getStep } from "../../../services/Stepper";
import { nextStep } from "../../../state/actions/stepper/stepper";
import { stepperData } from "./stepper";
import { useForm, Controller } from "react-hook-form";
import { Flag, Hash, Plus, X } from "phosphor-react"
import IconInput from "../../../components/IconInput";
import JLSelect from "../../../components/JLSelect";
import SaveBox from "./skills/SaveBox";
import EditBox from "./skills/EditBox";
import { addSkill, deleteSkill, editSkill, goToNextStep } from "../../../state/actions/profile/profile";
import { getYearsOfExperience } from "../../../services/Helper";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Show from "../../../components/Show";
import Hide from './../../../components/Hide';
import { RTLProvider } from "../../../components/RTLProvider";
import { createTheme, ThemeProvider } from '@mui/material/styles';




const Skills = ({ lastStep, handleSubmit }) => {
    const { t, i18n } = useTranslation();
    const theme = createTheme({
        direction: i18n.language == "ar" ? "rtl" : "ltr", // Both here and <body dir="rtl">
    });
    const matches = useMediaQuery('(min-width:1400px)');
    const dispatch = useDispatch();
    const currentStepNo = useSelector((state) => state.stepper.currentStep);
    const finalStepNo = useSelector((state) => state.stepper.finalStep);
    const current = getStep(stepperData, currentStepNo);
    const stepFinished = useSelector((state) => state.profile.data?.cv?.User?.step_finished);
    const skills = useSelector((state) => state.profile?.data?.cv?.User?.CvCareerSkills)
    const langs = useSelector((state) => state.profile?.data?.cv?.User?.CvLanguageSkills)
    const motherTongueChecker = langs?.filter(lang => lang.mother_tongue).length > 0 ? true : false
    // component State
    const [isAddingSkill, setIsAddingSkill] = useState(true)
    const [isEditingSkill, setIsEditingSkill] = useState(false)
    const [isAddingLang, setIsAddingLang] = useState(true)
    const [isEditingLang, setIsEditingLang] = useState(false)
    const [currentSkill, setCurrentSkill] = useState(null)
    const [yearsOfExperience, setYearsOfExperience] = useState()
    const [currentLang, setCurrentLang] = useState(null)
    const [checkedMotherTonuge, setCheckedMotherTongue] = useState(false)
    const [showAddBtnSkills, setShowAddBtnSkills] = useState(false);
    const [showAddBtnLangs, setShowAddBtnLangs] = useState(false);
    useEffect(() => {
        if (skills == null || skills == undefined || skills?.length == 0) {
            setShowAddBtnSkills(false);
            setIsAddingSkill(true);
        } else {
            setShowAddBtnSkills(true);
            setIsAddingSkill(false);
        }
    }, [skills?.length])

    useEffect(() => {
        if (langs == null || langs == undefined || langs?.length == 0) {
            setShowAddBtnLangs(false);
            setIsAddingLang(true);
        } else {
            setShowAddBtnLangs(true);
            setIsAddingLang(false);
        }
    }, [langs?.length])
    //Form & Validation Schema
    const skillSchema = yup.object({
        skillName:
            yup
                .string()
                .required(t("validations.required_input"))
                .min(3, t("validations.min_input", { number: 3 }))
                .max(30, t("validations.max_input", { number: 30 }))
                .trim(t("validations.required_input"))
                .matches(/^[a-zA-Z\u0600-\u06FF]+.?/, t("validations.contain_alphapet"))
        ,
        proficiency: yup
            .number(t("validations.required_input"))
            .oneOf([1, 2, 3, 4, 5], t("validations.required_input"))
            .required(t("validations.required_input"))
            .typeError(t("validations.required_input"))
    }).required();
    const langSchema = yup.object({
        languageName:
            yup.string()
                .required(t("validations.required_input"))
                .min(3, t("validations.min_input", { number: 3 }))
                .max(30, t("validations.max_input", { number: 30 }))
                .trim(t("validations.required_input"))
                .matches(/^[a-zA-Z\u0600-\u06FF]+.?/, t("validations.contain_alphapet"))
        ,
        langProficiencyRating:
            yup.number().nullable().when("motherTongue", (motherTongue, schema) => {
                if (!motherTongue) {
                    return yup.number(t("validations.required_input"))
                        .oneOf([1, 2, 3, 4, 5], t("validations.required_input"))
                        .required(t("validations.required_input"))
                        .typeError(t("validations.required_input"))
                }
            }),
        motherTongue:
            yup
                .boolean()
    }).required();
    const { register: skillRegister,
        setValue: setSkillValue,
        reset: resetSkills,
        formState: { errors: skillErrors },
        control,
        handleSubmit: skillSubmitHandler } =
        useForm({
            mode: 'all',
            reValidateMode: 'onBlur',
            resolver: yupResolver(skillSchema),
        });
    const { register: langsRegister,
        reset: resetLangs,
        setValue: setLangvalue,
        formState: { errors: langsErrors },
        control: languageControl,
        clearErrors,
        handleSubmit: langsSubmitHandler }
        = useForm({
            mode: 'all',
            reValidateMode: 'onBlur',
            resolver: yupResolver(langSchema)
        });
    const onSubmitSkill = async (event) => {
        console.log(event)
        // rearrange data in action
        let dataToSend = {
            type: "career",
            name: event.skillName,
            proficiency: event.proficiency,
        }
        if (event.years_of_experience_id) {
            dataToSend.years_of_experience_id = event.years_of_experience_id
        }
        await dispatch(addSkill(dataToSend)).then(() => {
            console.log("added")
            resetSkills();
            setIsAddingSkill(false);
        })
    }
    const onSubmitEditingSkill = async (event) => {
        let dataToSend = {
            type: "career",
            name: event.skillName,
            proficiency: event.proficiency,
        }
        if (event.years_of_experience_id) {
            dataToSend.years_of_experience_id = event.years_of_experience_id
        }
        if (currentSkill.id) {
            dataToSend.id = currentSkill.id
        }
        await dispatch(editSkill(dataToSend)).then(() => {
            setIsEditingSkill(false);
        })
    }
    const onSubmitEditingLang = async (event) => {
        await dispatch(editSkill({
            type: "language",
            name: event.languageName,
            proficiency: event.motherTongue ? 5 : event.langProficiencyRating,
            id: currentSkill.id,
            mother_tongue: event.motherTongue ? 1 : 0
        })).then(() => {
            setIsEditingLang(false);
        })
    }
    const onSubmitLang = async (event) => {
        console.log(event)
        await dispatch(addSkill({
            type: "language",
            name: event.languageName,
            proficiency: event.motherTongue ? 5 : event.langProficiencyRating,
            mother_tongue: event.motherTongue ? 1 : 0
        })).then(() => {
            resetLangs()
            setIsAddingLang(false);
        })
    }
    const handleClick = (skill, type) => {
        console.log("Clicked", skill)
        if (type === "career") {
            setIsEditingSkill(true)
            setSkillValue("skillName", skill.name)
            setSkillValue("proficiency", skill.proficiency)
            if (skill.years_of_experience_id)
                setSkillValue("years_of_experience_id", skill.years_of_experience_id)
            else
                setSkillValue("years_of_experience_id", "")
            setCurrentSkill(skill)
            setIsAddingSkill(false)
        }
        else {
            if (skill.mother_tongue) {
                setCheckedMotherTongue(true)
            }
            else {
                setCheckedMotherTongue(false)
            }
            console.log(skill)
            setCurrentLang(skill)
            setIsEditingLang(true)
            setLangvalue("languageName", skill.name)
            setLangvalue("langProficiencyRating", skill.proficiency)
            setCurrentSkill(skill)
            setIsAddingLang(false)
        }
    }
    const handleSkillDelete = (skill, type) => {
        console.log("deleted", skill)
        if (type === "career") {
            dispatch(deleteSkill(skill.id, "career")).then(() => {
                setIsEditingSkill(false);
                resetSkills();
            })
        }
        else {
            dispatch(deleteSkill(skill.id, "language")).then(() => {
                setIsEditingLang(false)
                resetLangs();
            })
        }
    }
    const handleCloseEditSkill = () => {
        setCurrentSkill(null)
        resetSkills()
        setIsEditingSkill(false);
    }
    const handleCloseEditLang = () => {
        setCheckedMotherTongue(false)
        setIsEditingLang(false);
    }
    const handleBack = () => {
        if (current?.prev) {
            dispatch(nextStep({ currentStep: current?.prev, finalStep: finalStepNo }));
        }
    }
    const handleNext = async () => {
        if (current.next) {
            if (current.no > stepFinished) {
                await dispatch(goToNextStep());
            }
            dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
        }
    }
    const renderSkillsForm = () => {
        return (
            <Grid item container
                direction="row"
                spacing={1}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={matches ? 6 : 12}
                    xl={4}
                >
                    <IconInput
                        label={t("others.skill_name")}
                        placeholder={t("others.skill_name")}
                        icon={Hash}
                        className="mb16 w100"
                        fieldName="skillName"
                        register={skillRegister}
                        error={skillErrors?.skillName}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={matches ? 6 : 12}
                    xl={4.5}
                >
                    <JLSelect
                        fieldName="years_of_experience_id"
                        label={t("labels.skill_yearsOfexp")}
                        selectLabel={t("placeholders.skill_yearsOfexp")}
                        control={control}
                        childs={yearsOfExperience}
                        onChange={(e) => {
                            setSkillValue("years_of_experience_id", e.target.value)
                            console.log(e.target.value)
                        }}
                    >
                    </JLSelect>
                </Grid>
                <Grid item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3.5}
                    xl={3}
                    className="skill-input-rating"
                >
                    <Typography className="label" component="div" sx={{}}>{t("labels.proficiency")}</Typography>
                    <Controller
                        control={control}
                        name="proficiency"
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                                <>
                                    <ThemeProvider theme={theme}>
                                        <RTLProvider>
                                            <Rating {...field} size="large" />
                                        </RTLProvider>
                                    </ThemeProvider>
                                </>
                            )
                        }}
                    />
                    {skillErrors.proficiency && (
                        <FormHelperText style={{ color: "red" }}>{skillErrors.proficiency.message}</FormHelperText>
                    )}
                </Grid>
            </Grid>
        );
    }
    const renderLangsForm = () => {
        return (
            <Grid item container spacing={1} direction="row">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <IconInput
                        label={t("labels.skill_language")}
                        placeholder={t("placeholders.skill_language")}
                        icon={Flag}
                        className="mb16 w100"
                        fieldName="languageName"
                        register={langsRegister}
                        error={langsErrors.languageName}
                    />
                </Grid>
                <Grid item xs={12} md={6} className="skill-input-rating">
                    {!checkedMotherTonuge && <>
                        <Typography className="label">{t("labels.proficiency")}</Typography>
                        <Controller
                            control={languageControl}
                            name="langProficiencyRating"
                            defaultValue={checkedMotherTonuge ? 5 : null}
                            rules={{ required: true }}
                            render={({ field }) => {
                                return (
                                    <>
                                        <ThemeProvider theme={theme}>
                                            <RTLProvider>
                                                <Rating {...field} size="large" />
                                            </RTLProvider>
                                        </ThemeProvider>
                                    </>
                                )
                            }}
                        />
                    </>
                    }
                    {langsErrors.langProficiencyRating && (
                        <FormHelperText style={{ color: "red" }}>{langsErrors.langProficiencyRating.message}</FormHelperText>
                    )}
                </Grid>
                {currentSkill?.mother_tongue && !isAddingLang ?
                    <Grid item xs={true}>
                        <Box alignConten="center">
                            <Controller
                                control={languageControl}
                                name="motherTongue"
                                render={({ field }) => {
                                    return <Checkbox
                                        onChange={(e) => {
                                            setCheckedMotherTongue((prev) => !prev)
                                            if (motherTongueChecker) {
                                                setLangvalue("langProficienc    yRating", 0)
                                            }
                                            field.onChange(e.target.checked)
                                        }}
                                        defaultChecked={motherTongueChecker}
                                        checked={field.value}
                                        sx={{
                                            color: "#3a3b81",
                                            '&.Mui-checked': {
                                                color: "#3a3b81",
                                            },
                                        }}
                                    />
                                }}
                            />
                            <label> {t("labels.mother_tongue")}</label>
                        </Box>
                    </Grid>
                    : !motherTongueChecker && <Grid item xs={true}>
                        <Box className="mother-tongue-wrapper">
                            <Controller
                                control={languageControl}
                                name="motherTongue"
                                render={({ field }) => {
                                    return <Checkbox
                                        sx={{
                                            color: "#3a3b81",
                                            '&.Mui-checked': {
                                                color: "#3a3b81",
                                            },
                                        }}
                                        color="secondary"
                                        onChange={(e) => {
                                            setCheckedMotherTongue((prev) => !prev)
                                            if (motherTongueChecker) {
                                                setLangvalue("langProficiencyRating", 0)
                                            }
                                            field.onChange(e.target.checked)
                                        }}
                                        defaultChecked={motherTongueChecker}
                                        checked={field.value}
                                    />
                                }}
                            />
                            <label className="mother-tongue"> {t("labels.mother_tongue")}</label>
                        </Box>
                    </Grid>}
            </Grid>
        );
    }
    useEffect(() => {
        getYearsOfExperience().then((years) => {
            setYearsOfExperience(years)
        })
    }, [])
    useEffect(() => {
        clearErrors()
    }, [checkedMotherTonuge])
    return (
        <Grid container direction="row" className='step-content skills' >
            <Grid item xs={12}>
                <Typography className='title'>{t("titles.skills_title")}</Typography>
                <Typography className='text'>
                    {t("subTitles.skills_text")}
                </Typography>
                <Typography className="subtitle" component="span">
                    {t("titles.skills_title_carrer")}
                </Typography>
                <Box className="skills-chips-wrapper mb16">
                    {skills?.map((skill, index) => {
                        return <Chip
                            key={index++}
                            className={`choice-chips ${isEditingSkill && currentSkill === skill && "clicked-chip"}`}
                            label={skill.name}
                            onClick={() => handleClick(skill, "career")}
                            onDelete={() => handleSkillDelete(skill, "career")}
                            deleteIcon={<X color="white" size={18} />}
                        />
                    })}
                </Box>
                {isAddingSkill && (
                    <SaveBox
                        title={t("titles.skill_add_title")}
                        handleSubmit={skillSubmitHandler(onSubmitSkill)}
                    >
                        {renderSkillsForm()}
                    </SaveBox>
                )}
                {isEditingSkill && (
                    <EditBox
                        title={t("titles.skills_edit_title")}
                        handleSubmit={skillSubmitHandler(onSubmitEditingSkill)}
                        handleCloseEdit={handleCloseEditSkill}
                        handleDelete={() => handleSkillDelete(currentSkill, "career")}
                    >
                        {renderSkillsForm()}
                    </EditBox>
                )}

                {showAddBtnSkills && (
                    <Button fullWidth className="add-btn" onClick={() => {
                        resetSkills()
                        setIsEditingSkill(false)
                        setIsAddingSkill(true)
                    }}> <Plus />{t("buttons.add_skill")}</Button>
                )}

                <Typography mt={2} className="subtitle mb16" component="span">
                    {t("titles.skill_subtitle_lang")}
                </Typography>
                <Box className="skills-chips-wrapper mb16">
                    {langs?.map((lang, index) => {
                        return <Chip
                            key={index++}
                            className={`choice-chips ${isEditingLang && currentLang === lang && "clicked-chip"}`}
                            label={lang.name}
                            onClick={() => handleClick(lang, "lang")}
                            onDelete={() => handleSkillDelete(lang)}
                            deleteIcon={<X color="white" size={18}
                            />}
                        />
                    })}
                </Box>
                {isAddingLang &&
                    <SaveBox
                        title={t("titles.add_new_lang")}
                        handleSubmit={langsSubmitHandler(onSubmitLang)}
                    >
                        {renderLangsForm()}
                    </SaveBox>
                }
                {isEditingLang &&
                    <EditBox
                        title={t("titles.language_edit")}
                        handleSubmit={langsSubmitHandler(onSubmitEditingLang)}
                        handleCloseEdit={handleCloseEditLang}
                        handleDelete={() => handleSkillDelete(currentSkill, "language")}
                    >
                        {renderLangsForm()}
                    </EditBox>
                }

                {showAddBtnLangs && (
                    <Button fullWidth className="add-btn" onClick={() => {
                        setCheckedMotherTongue(false)
                        resetLangs()
                        setIsEditingLang(false)
                        setIsAddingLang(true)
                    }}> <Plus />{t("titles.add_language_skill")}</Button>
                )}

                <Grid mt={1} container justifyContent='flex-end'>
                    {current?.prev && (
                        <button className='back-btn' onClick={handleBack}>{t("buttons.back_btn")}</button>
                    )}
                    <Hide condition={lastStep} >
                        <button className='next-btn' onClick={handleNext}>
                            {t("buttons.next_btn")}
                        </button>
                    </Hide>
                    <Show condition={lastStep}>
                        <button className='next-btn' onClick={handleSubmit}>
                            {/* Submit */}
                            {/* {t("buttons.next_btn")} */}
                            {t("buttons.submit_btn")}
                        </button>

                    </Show>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Skills;