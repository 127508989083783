import { Box, RadioGroup, Stack } from '@mui/material';
import React from 'react';

import { FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCountry, getNationality, translateField } from './../../../../services/Helper';
import OneFilter from './../../../search-page/components/filter/one-filter/OneFilter';
import './ApplicantFilter.css';



// label, children, active, showActionBtns, disabled, control, id, reset, controlCloseMenu, closed, setClosed, applied

const genders = [
    {
        name_en: "Male",
        name_ar: "ذكر",
        id: "1"
    },
    {
        name_en: "Female",
        name_ar: "انثى",
        id: "2"
    }
]

const statuses = [
    {
        name_en: "Active",
        name_ar: "مفعل",
        id: "0"
    },
    {
        name_en: "Blocked",
        name_ar: "محظور",
        id: "1"
    }
]

const ApplicantsFilter = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [countries, setCountries] = useState([])
    const [nats, setNats] = useState([])
    const {
        control,
        getValues,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getCountry().then((res) => {
            setCountries(res)
        })
        getNationality().then((res) => {
            console.log(res)

            setNats(res)
        })
    }, [])

    const filterApplicantsByCountry = () => {

        if (getValues().country) {
            if (searchParams.get("country"))
                searchParams.set("country", getValues().country)
            else
                searchParams.append("country", getValues().country)
            console.log(searchParams.get("country"))
        }

        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        if (getValues().gender) {
            if (searchParams.get("gender"))
                searchParams.set("gender", getValues().gender)
            else
                searchParams.append("gender", getValues().gender)
            console.log(searchParams.get("gender"))
        }
        if (getValues().nationality) {
            if (searchParams.get("nationality"))
                searchParams.set("nationality", getValues().nationality)
            else
                searchParams.append("nationality", getValues().nationality)
            console.log(searchParams.get("nationality"))
        }

        console.log(searchParams.get("nationality"))
        navigate(`/applicants?${searchParams.toString()}`)


    }
    const filterApplicantsByNationality = () => {
        if (getValues().country) {
            if (searchParams.get("country"))
                searchParams.set("country", getValues().country)
            else
                searchParams.append("country", getValues().country)
            console.log(searchParams.get("country"))
        }

        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        if (getValues().gender) {
            if (searchParams.get("gender"))
                searchParams.set("gender", getValues().gender)
            else
                searchParams.append("gender", getValues().gender)
            console.log(searchParams.get("gender"))
        }
        if (getValues().nationality) {
            if (searchParams.get("nationality"))
                searchParams.set("nationality", getValues().nationality)
            else
                searchParams.append("nationality", getValues().nationality)
            console.log(searchParams.get("nationality"))
        }

        console.log(searchParams.get("nationality"))
        navigate(`/applicants?${searchParams.toString()}`)

    }

    const filterApplicantsByGender = () => {
        if (getValues().country) {
            if (searchParams.get("country"))
                searchParams.set("country", getValues().country)
            else
                searchParams.append("country", getValues().country)
            console.log(searchParams.get("country"))
        }

        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        if (getValues().gender) {
            if (searchParams.get("gender"))
                searchParams.set("gender", getValues().gender)
            else
                searchParams.append("gender", getValues().gender)
            console.log(searchParams.get("gender"))
        }
        if (getValues().nationality) {
            if (searchParams.get("nationality"))
                searchParams.set("nationality", getValues().nationality)
            else
                searchParams.append("nationality", getValues().nationality)
            console.log(searchParams.get("nationality"))
        }

        console.log(searchParams.get("nationality"))
        navigate(`/applicants?${searchParams.toString()}`)

    }
    const filterApplicantsByStatus = () => {
        if (getValues().country) {
            if (searchParams.get("country"))
                searchParams.set("country", getValues().country)
            else
                searchParams.append("country", getValues().country)
            console.log(searchParams.get("country"))
        }

        if (getValues().status) {
            if (searchParams.get("status"))
                searchParams.set("status", getValues().status)
            else
                searchParams.append("status", getValues().status)
            console.log(searchParams.get("status"))
        }
        if (getValues().gender) {
            if (searchParams.get("gender"))
                searchParams.set("gender", getValues().gender)
            else
                searchParams.append("gender", getValues().gender)
            console.log(searchParams.get("gender"))
        }
        if (getValues().nationality) {
            if (searchParams.get("nationality"))
                searchParams.set("nationality", getValues().nationality)
            else
                searchParams.append("nationality", getValues().nationality)
            console.log(searchParams.get("nationality"))
        }

        console.log(searchParams.get("nationality"))
        navigate(`/applicants?${searchParams.toString()}`)

    }
    const handleReset = (filterToReset) => {
        searchParams.delete(filterToReset)

        navigate(`/applicants?${searchParams.toString()}`)

        // setSearchParams({})
        reset()
    }
    return (

        <Stack
            direction="row"
            gap={2}
        >
            <Box >
                <OneFilter
                    label={t("labels.country")}
                    active={searchParams.get("country")}
                    showActionBtns={true}
                    id={"123"}
                    reset={() => handleReset("country")}
                    control={getValues}
                    applyFilterFunction={filterApplicantsByCountry}
                >
                    {countries?.map((country) => {
                        return <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup aria-label="gender" {...field}

                                    defaultValue={searchParams.get("country") && searchParams.get("country")}
                                >
                                    <FormControlLabel
                                        value={country.id}
                                        control={<Radio />}
                                        label={translateField(country, "name")}
                                    />
                                </RadioGroup>
                            )}
                        />
                    })}
                </OneFilter>
            </Box>
            <Box>
                <OneFilter
                    label={t("others.nationality_placeholder")}
                    active={searchParams.get("nationality")}
                    showActionBtns={true}
                    id={"123"}
                    reset={() => handleReset("nationality")}
                    control={getValues}
                    applyFilterFunction={filterApplicantsByNationality}
                >
                    {nats?.map((nat) => {
                        return <Controller
                            name="nationality"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup aria-label="gender" {...field}
                                    defaultValue={searchParams.get("nationality") && searchParams.get("nationality")}

                                >
                                    <FormControlLabel
                                        value={nat.id}
                                        control={<Radio />}
                                        label={translateField(nat, "name")}
                                    />
                                </RadioGroup>
                            )}
                        />
                    })}
                </OneFilter>
            </Box>
            <Box>

                <OneFilter
                    label={t("labels.general_gender")}
                    active={searchParams.get("gender")}
                    showActionBtns={true}
                    id={"123"}
                    reset={() => handleReset("gender")}
                    control={getValues}
                    applyFilterFunction={filterApplicantsByGender}
                >
                    {genders?.map((gender) => {
                        return <Controller
                            name="gender"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup aria-label="gender" {
                                    ...field}
                                    defaultValue={searchParams.get("gender") && searchParams.get("gender")}

                                >
                                    <FormControlLabel
                                        value={gender.id}
                                        control={<Radio />}
                                        label={translateField(gender, "name")}
                                    />
                                </RadioGroup>
                            )}
                        />
                    })}
                </OneFilter>
            </Box>
            <Box>
                <OneFilter
                    label={t("others.status")}
                    active={searchParams.get("status")}
                    showActionBtns={true}
                    id={"123"}
                    reset={() => handleReset("status")}
                    control={getValues}
                    applyFilterFunction={filterApplicantsByStatus}
                >
                    {statuses?.map((status) => {
                        return <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup aria-label="status" {...field}
                                    defaultValue={searchParams.get("status") && searchParams.get("status")}

                                >
                                    <FormControlLabel
                                        value={status.id}
                                        control={<Radio />}
                                        label={translateField(status, "name")}
                                    />
                                </RadioGroup>
                            )}
                        />
                    })}
                </OneFilter>
            </Box>

        </Stack>

    )
}

export default ApplicantsFilter


