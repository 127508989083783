import { Avatar, Typography, Grid, Box } from "@mui/material";
import "./UserAvatar.scss";
import { User } from "phosphor-react";



const UserAvatar = ({
  showAvatar,
  showTitle,
  showName,
  isCompany,
  avatar,
  title,
  name,
  rounded,
  size,
  bolded,
  companySize,
  applicantCardAvatar,
  fontWeight,
  changeNameStyle,
  changeTitleStyle,
  viewProfile
}) => {

  const sizes = {
    miniSmall: 30,
    small: 38,
    large: 50
  };
  const fontWeights = {

  }
  const changesInNameStyle = {
    applicantAvatarCardName: "applicant-card-avatar-name",
    applicantAvatarName: "applicant-avatar-name"
  }
  const changesInTitleStyle = {
    applicantAvatarCardTitle: "applicant-card-avatar-title",
    applicantAvatarTitle: "applicant-avatar-title"
  }

  return (
    <>
      <Grid container direction={"row"} alignItems={"center"} spacing={1} sx={{ justifyContent: "center" }}>
        <Grid item xs="auto">
          {showAvatar && (
            <Avatar
              sx={{ height: sizes[size], width: sizes[size], marginBottom: '5px' }}
              className="avatar-image" src={avatar}
              variant={rounded ? "rounded" : "circular"}
            />
          )}
        </Grid>
        {(showName || showTitle) && (
          <Grid item xs>
            <Grid container alignItems={"center"}>
              <Grid item style={{ textTransform: "capitalize" }} xs={12}>
                {showName &&
                  <Typography
                    sx={{ fontWeight: bolded ? "600" : "400" }}
                    className={`avatar-name ${changesInNameStyle[changeNameStyle]}`}
                  >
                    {name}
                  </Typography>}
              </Grid>
              <Grid item xs={12}>
                {showTitle &&
                  <Typography
                    className={`avatar-title ${changesInTitleStyle[changeTitleStyle]}`}
                  >
                    {title}
                  </Typography>}
              </Grid>
            </Grid>
          </Grid>
        )}

      </Grid>
    </>
  );
};
export default UserAvatar;
