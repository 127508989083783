import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import { Checks } from "phosphor-react";
import ErrorPage from "../../../../../components/error-page/ErrorPage";
import OneNotification from "./components/one-notification/OneNotification";
import emptyPage from "../../../../../assets/empty-page.json";
import { useNavigate } from "react-router-dom";
import { getNotificationList, readSingleNotification, translateField } from '../../../../../services/Helper'

import "./NotificationsDropdown.css"
import { useDispatch, useSelector } from 'react-redux';
import Show from '../../../../../components/Show';
import Loader from '../../../../../components/loader/Loader';
import { getNotifications, readAllNotifications } from '../../../../../state/actions/notifications/notifications';
import { useTranslation } from 'react-i18next';

const NotificationsDropdown = ({ seeAllClick, handleClose }) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const [data, setData] = useState(null);
    // const [dataLoaded, setDataLoaded] = useState(false);

    const role = useSelector((state) => state.profile?.role?.name);
    let notifications = useSelector((state) => state.notifications.data);
    notifications = notifications?.slice(0, 4);
    const dataLoaded = useSelector((state) => state.notifications.loaded);
    const limit = useSelector((state) => state.notifications.limit);

    useEffect(() => {
        dispatch(getNotifications(limit))
    }, [])

    const handleOneClick = (id, redirect) => {
        readSingleNotification(role, id).then((response) => {
            navigate(redirect);
            handleClose();
        });

    }

    const handleReadAll = () => {
        dispatch(readAllNotifications());
    }

    return (
        <>
            <Box component="div" className="notifications-dropdown">
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="card-header">
                            <Grid container direction="row" justifyContent="center">
                                <Grid item xs="auto">
                                    <Typography className="title">
                                        {t("labels.notifications")}
                                    </Typography>
                                </Grid>
                                <IconButton className="action" onClick={() => handleReadAll()}>
                                    <Checks />
                                </IconButton>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="card-content">
                            <Loader data={dataLoaded} width={"100%"} height={"40px"} ><></></Loader>
                            <Loader data={dataLoaded} width={"100%"} height={"40px"} ><></></Loader>
                            <Loader data={dataLoaded} width={"100%"} height={"40px"} ><></></Loader>
                            <Loader data={dataLoaded} width={"100%"} height={"40px"} ><></></Loader>

                            <Show condition={dataLoaded && notifications?.length > 0}>
                                <>
                                    {notifications?.map((item) => {
                                        return (
                                            <OneNotification
                                                active={item.status == 0 ? true : false}
                                                name={() => {
                                                    if (item.User)
                                                        return item.User.first_name + " " + item.User.second_name;
                                                    else
                                                        return item.Recruiter.company_name;
                                                }}
                                                image={item.User ? item.User.image : item.Recruiter.image}
                                                message={translateField(item, "message_property")}
                                                onClick={() => handleOneClick(item?.id, item.redirect)}

                                            />
                                        )
                                    })}
                                </>
                            </Show>
                            <Show condition={dataLoaded && notifications?.length == 0}>
                                <Box component="div" padding={2}>
                                    <ErrorPage
                                        mini
                                        title={t("labels.oops")}
                                        description={t("labels.no_notifications")}
                                        action={() => { console.log("test"); }}
                                        animationData={emptyPage}
                                    />
                                </Box>
                            </Show>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="card-footer">
                            <Button className="action" onClick={() => {
                                navigate("/notifications");
                                handleClose();
                            }}>
                                {t("labels.see_all")}
                            </Button>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}
export default NotificationsDropdown;