import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sentenceCase } from "change-case";
import { titleCase } from "title-case";
import "./CategoryHeader.scss";
import { IconButton, Typography } from "@mui/material";
import { CaretLeft } from "phosphor-react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Notification } from "phosphor-react";

const CategoryHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { model_name } = useParams();

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {(model_name === "Governorate" || model_name === "City") && (
        <IconButton
          className="back-btn"
          p={0}
          onClick={() => {
            navigate(-1);
          }}
        >
          <CaretLeft color="#000" size={20} />
        </IconButton>
      )}

      <Typography className="category-header">
        {t(`models.${model_name}_title`)}
      </Typography>
    </Stack>
  );
};

export default CategoryHeader;
