import React from 'react'
import "./IconText.scss"
import { Box, Typography } from '@mui/material'

const IconText = ({ icon, text, className, iconRight }) => {
    console.log(className)
    return (
        <Box
            className="icon-text-wrapper"
        >
            {iconRight ?
                <>
                    <Typography mr={6} component="span" className={` ${className ? className : "icon-text-subtitle"}`}>{text}</Typography>
                    {icon}
                </> :
                <>
                    {icon}
                    <Typography ml={0.5} mr={0.5} component="span" className={` ${className ? className : "icon-text-subtitle"}`}>{text}</Typography>
                </>
            }
        </Box >
    )
}

export default IconText