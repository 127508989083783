import { combineReducers } from 'redux'

import { loadingBarReducer } from 'react-redux-loading-bar'
import { reducer as toastrReducer } from 'react-redux-toastr'
import stepperReducer from './stepper/stepperReducer';
import profileReducer from './profile/profileReducer';
import loadingReducer from './loading/loadingReducer';
import { sidebarReducer } from './sidebar/sidebar';
import { notificationReducer } from './notification/notificationReducer';
import notificationsReducer from './notifications/notificationsReducer';
import b2cReducer from './b2c/b2cReducer';
import loginModalReducer from './login-modal/loginModalReducer';
import { searchReducer } from './search/searchReducer';
import { filterReducer } from './filter/filterReducer';
import { CategoryListReducer } from './category-list/CategoryListReducer';
import { ApplicantListReducer } from './applicant-list/ApplicantListReducer';
import { ApplicantSearchAdminReducer } from './applicant-search-admin/ApplicantSearchAdminReducer';
import {RecruiterSearchAdminReducer} from './recruiter-search-admin/RecruiterSearchAdminReducer'
import authReducer from './auth/authReducer';
import otpReducer from './otp/otpReducer';
import processReducer from './process/processReducer';

const reducer = combineReducers({
    loadingBar: loadingBarReducer,
    stepper: stepperReducer,
    toastr: toastrReducer,
    profile: profileReducer,
    loading: loadingReducer,
    sidebar: sidebarReducer,
    newNotification: notificationReducer,
    notifications: notificationsReducer,
    b2cLogin: b2cReducer,
    loginModal: loginModalReducer,
    search: searchReducer,
    filter: filterReducer,
    categoryList: CategoryListReducer,
    applicantList:ApplicantListReducer,
    auth:authReducer,
    otp: otpReducer,
    process: processReducer,
    applicantSearchAdmin:ApplicantSearchAdminReducer,
    recruiterSearchAdmin: RecruiterSearchAdminReducer
})

export default reducer;