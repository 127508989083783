import {
    GET_CATEGORIES_LIST,
    ADD_CATEGORY,
    EDIT_CATEGORY,
    DELETE_CATEGORY,
    GET_ID_CATEGORY,
    SET_ACTIVE
}
    from '../../actions/categoriesList/types'

let initialState = {
    data: [],
    idCategory: 0,
    active: 0,
    loaded: false
}

export const CategoryListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CATEGORIES_LIST:
            return {
                ...state,
                data: payload.data,
                loaded: true
            }
        case ADD_CATEGORY:
            return {
                ...state,
                data: [
                    ...state.data,
                    payload
                ]
            }
        case EDIT_CATEGORY:
            return {
                ...state,
                data: payload
            }

        case GET_ID_CATEGORY:
            return {
                ...state,
                idCategory: payload
            }
        case SET_ACTIVE:
            return {
                ...state,
                active: payload
            }
        case DELETE_CATEGORY:
            return {
                ...state,
                data: payload.data,
                active: 0
            }
        default:
            return state
    }
}