import React from "react";
import IconInput from "./../../../../../../../components/IconInput";
import { Box, Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { TextAlignLeft, TextAlignRight } from "phosphor-react";
import "./BlockModal.scss";
import {
  block_unblock,
  getDocumentDirection,
} from "../../../../../../../services/Helper";

import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

const BlockModal = ({
  open,
  handleClose,
  userToBlock,
  setOpen,
  userEmail,
  setFetchTrigger,
  loading,
  setLoading
}) => {
  const align = getDocumentDirection();

  const blockSchema = yup
    .object({
      blockReason: yup
        .string()
        .required(t("validations.required_input"))
        .min(3, t("validations.min_input", { number: 3 }))
        .max(30, t("validations.max_input", { number: 30 }))
        .trim(t("validations.required_input")),
    })
    .required();
  const {
    register,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(blockSchema),
  });
  const onSubmit = (data) => {
    setLoading(true);
    block_unblock(userEmail, data.blockReason, "block")
      .then((res) => {
        setOpen(false);
        setFetchTrigger((prev) => !prev);
        console.log(userEmail, "Blocked");
        reset();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setOpen(false);
    reset();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="invite-job-dialog block-confirmation-modal"
      component={Grid}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography className="block-confirmation-text">
              {t("titles.are_you_sure you_want_to_block")} "{userToBlock}{" "}
              {align === "right" ? "؟" : "?"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IconInput
              label={t("labels.reason")}
              placeholder={t("labels.write_here")}
              type="text"
              icon={align === "right" ? TextAlignRight : TextAlignLeft}
              className="w100 new-job-input"
              multiline={true}
              fieldName="blockReason"
              rows={5}
              iconTop
              register={register}
              error={errors?.blockReason}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Button onClick={handleCancel} className="btn-cancel">
                  {t("buttons.cancel")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button className="btn-block" type="submit" disabled={loading}>
                  {t("buttons.block")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default BlockModal;
