import { Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'

import "./SendNotificationsHeader.scss"

const SendNotificationsHeader = () => {
    return (
        <Typography className="category-header">
            {t("titles.notification_page_header")}
        </Typography>
    )
}

export default SendNotificationsHeader