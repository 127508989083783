import React, { useState, useEffect } from "react";
import "./JobDetailsCard.scss";
import {
  Grid,
  Divider,
  Typography,
  Box,
  IconButton,
  Button,
  Stack
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Circle,
  PencilSimple,
  Trash,
  ShareNetwork,
  BookmarkSimple,
} from "phosphor-react";
import TitlesAndDescreption from ".././titles-and-descreption/TitlesAndDescreption";
import { useTranslation } from "react-i18next";
import Hide from '../Hide';
import Show from '../Show';
import JobTag from '../job-tag/JobTag';
import ApplicationStatus from '../application-status/ApplicationStatus';
import FollowedCard from './components/FollowedCard';
import ShareJobPost from '../../views/job-details/components/share-job-post/ShareJobPost';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { perm, permOr, applyJob, saveJob, translateField, changeCompanyStatus } from '../../services/Helper';
import Permissions from '../../constants/permissions';
import Modals from '../../components/modals/Modals';
import { useParams } from 'react-router-dom';
import { deleteJob } from '../../services/Helper';
import DeleteJobModal from "./components/delete-job-modal/DeleteJobModal";


const JobDetailsCard = ({
  jobId,
  status,
  jobTitle,
  experienceType,
  date,
  location,
  careerLevel,
  yearsOfEx,
  jobCategory,
  slaryRange,
  jobDescreption,
  recruiterId,
  jobRequirement,
  showStatus,
  workStyle,
  showBottomShare,
  companyName,
  companyLogo,
  followed,
  companySize,
  type,
  isApplied,
  isSaved,
  noOfApplicants,
  ApplicantApplyJobs,
  followHandler,
  jobStatus,
  viewDetails,

}) => {
  const { t } = useTranslation();
  console.log(recruiterId)

  const navigate = useNavigate();
  const params = useParams();
  // const [is_followed, setIsFollowed] = useState(followed);
  const [apply, setApply] = useState(isApplied);
  const [save, setSave] = useState(isSaved);
  // const [jobStatus, setJobStatus] = useState()
  const [openShare, setOpenShare] = useState(false);
  const [openDeleteModals, setOpenDeleteModals] = useState(false);
  const [openApplyModals, setOpenApplyModals] = useState(false);
  const toggleDivider = useMediaQuery("(max-width:890px)");
  const matches = useMediaQuery("(max-width:900px)");
  const [adminDeleteModalOpen, setAdminDeleteModalOpen] = useState(false)
  const permissions = useSelector((state) => state.profile.role?.permissions);

  useEffect(() => {
    setApply(isApplied);
    setSave(isSaved);
    // if (ApplicantApplyJobs?.length > 0) {
    //   setJobStatus(ApplicantApplyJobs[0].JobStatus);
    // }
  }, [jobId]);

  const getDaysAgo = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  const daysAgo = getDaysAgo(date)

  const handleClickApply = async () => {
    const data = await applyJob(jobId);
    setApply(data?.is_applied)
  }
  const handleClickSave = async () => {
    const data = await saveJob(jobId);
    setSave(data?.is_saved)
  }

  //share dialog
  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseShare = (value) => {
    setOpenShare(false);
  };
  //Modals
  const handleClickOpenDeleteModals = () => {
    setOpenDeleteModals(true);
  };

  const handleCloseDeleteModals = (value) => {
    setOpenDeleteModals(false);
  };
  const handleClickOpenApplyModals = () => {
    if (!apply)
      setOpenApplyModals(true);
  };
  const handleCloseApplyModals = (value) => {
    setOpenApplyModals(false);
  };
  const viewDetailsClickHandler = () => {
    console.log(jobId, "cc")
    navigate(`/jobs/${jobId}`)

  }
  const handleDeleteModal = () => {
    setAdminDeleteModalOpen(true)
  }
  return (
    <Box sx={{ padding: matches ? '1rem' : ' 2.125rem 2rem 2rem 2rem' }} className="details-card">
      <Grid container direction="row">
        <Grid container item xs={6} direction="row">
          <Grid item sx={{marginTop:"5px"}}>
            <Circle className={status ? "active-icon" : "inactive-icon"} />
          </Grid>
          <Grid item >
            <Typography className="job-name">{jobTitle}</Typography>
            <Stack  className="publish-time" component="div">
              {daysAgo === 0 ? t("labels.today") : t("labels.days", { count: daysAgo })}
            </Stack>
          </Grid>

         
          {/* <Grid item xs={12}>
            <Stack
              direction={"row"}
              alignItems={"center"}
            >
              <Stack >
                <Circle className={status ? "active-icon" : "inactive-icon"} />
              </Stack>
              <Stack
                direction={"column"}
                justifyContent={"center"}
                className="publish-time" component="div">
                <Typography className="job-name">{jobTitle}</Typography>
                {daysAgo === 0 ? t("labels.today") : t("labels.days", { count: daysAgo })}
              </Stack>
            </Stack>
          </Grid> */}

        </Grid>

        <Grid item container justifyContent="flex-end" xs={6}>
          <Button className="delete-job-details-btn" onClick={handleDeleteModal}>{t("buttons.delete-job")}</Button>
        </Grid>
        
      </Grid>

      <Grid
        container
        direction="row"
        sx={{ marginTop: "1.0625rem" }}
        spacing={2}
      >
        <Grid item>
          <JobTag text={experienceType} />
        </Grid>
        <Grid item>
          <JobTag text={workStyle} />
        </Grid>
        <Grid item>
          <JobTag text={careerLevel} />
        </Grid>
        <Grid item>
          <JobTag
            text={t("labels.applicants", { count: noOfApplicants })}
            bold
          />
        </Grid>
      </Grid>
      {/*  */}
      {/* <Hide
        condition={permOr(
          permissions,
          Permissions.createJob,
          Permissions.editJob
        )}
      >
        <Box>
          <FollowedCard
            followed={followed}
            avatar={companyLogo}
            name={companyName}
            onFollow={followHandler}
            companySize={companySize}
          />
        </Box>
      </Hide> */}
      {/*  */}
      <Grid container direction="row">
        {/* first grid in row */}
        <Grid item xs={12} md={5} container direction="column">
          <Grid item>
            <TitlesAndDescreption
              title={t("labels.location")}
              descreption={location}
            />
          </Grid>
          <Grid item>
            <TitlesAndDescreption
              title={t("others.years_of_experience")}
              descreption={yearsOfEx}
            />
          </Grid>
        </Grid>
        {/* divider  */}
        <Divider
          orientation="vertical"
          flexItem
          className="divider"
          sx={{
            marginRight: "2rem",
            display: toggleDivider ? "none" : "block",
          }}
        />
        {/*second grid in row  */}
        <Grid item xs={12} md={6} container direction="column">
          <Grid item>
            <TitlesAndDescreption
              title={t("others.job_category")}
              descreption={jobCategory}
            />
          </Grid>
          <Grid item>
            <TitlesAndDescreption
              title={t("others.salary_range")}
              descreption={slaryRange}
            />
          </Grid>
        </Grid>
        {/* ending of row grid*/}
      </Grid>
      {/*  */}
      <Grid item xs={12}>
        <TitlesAndDescreption
          title={t("others.job_desc")}
          descreption={jobDescreption}
        />
      </Grid>
      <Grid xs={12}>
        <TitlesAndDescreption
          title={t("others.job_requirments")}
          descreption={jobRequirement}
        />
      </Grid>
      {/* browsing Pages */}
      <Hide condition={false}
      >
        <Grid
          container
          direction="row"
          className="browsing-page-footer"
          justifyContent="flex-end"
        >
          <Show condition={showStatus}>
            <Grid item xs={6}>
              <Box className="title" component="div">
                {t("others.status")}
              </Box>
              <Box component="div">
                <ApplicationStatus
                  status={jobStatus}
                />
              </Box>
            </Grid>
          </Show>
          <Grid item xs={6} container justifyContent="flex-end">
            <Box className="puplished-and-saved">
              <Show condition={showBottomShare}>
                <Box component="div">
                  <IconButton className="shared" onClick={() => handleClickOpenShare()}>
                    <ShareNetwork />
                  </IconButton>
                </Box>
              </Show>
              <Show condition={perm(permissions, Permissions.canSaveJob)}>
                <Box component="div">
                  <IconButton className="save">
                    <BookmarkSimple
                      onClick={handleClickSave}
                      weight={save ? "fill" : null}
                    />
                  </IconButton>
                </Box>
              </Show>
              <Show condition={perm(permissions, Permissions.canApplyJob)}>
                <Button
                  onClick={handleClickOpenApplyModals}
                  className={apply ? "applied" : "not-applied"}
                >
                  {apply ? t("others.applied") : t("others.apply")}
                </Button>
              </Show>
            </Box>
          </Grid>
        </Grid>
      </Hide>
      <ShareJobPost
        // selectedValue={selectedValue}
        open={openShare}
        onClose={handleCloseShare}
        id={jobId}
      />
      <Modals
        open={openDeleteModals}
        handleClose={handleCloseDeleteModals}
        handleAgree={() => {
          deleteJob(params.job_id).then((res) => {
            navigate("/");
          })
        }}
        title={t("labels.sure_delete_job")}
        content={t("labels.if_you_delete_job")}
        disagree={t("labels.no_keep")}
        agree={t("labels.yes_delete")}
        type="delete"
      />
      <DeleteJobModal
        open={adminDeleteModalOpen}
        setOpen={setAdminDeleteModalOpen}
        jobId={jobId}
        recruiterId={recruiterId}
      />
      <Modals
        open={openApplyModals}
        handleClose={handleCloseApplyModals}
        handleAgree={!apply && handleClickApply}
        title={t("labels.sure_apply_job")}
        content={t("labels.if_you_apply_job")}
        disagree={t("buttons.cancel")}
        agree={t("labels.yes_apply")}
        type="confirmation"
      />
    </Box >
  );
};

export default JobDetailsCard;
