import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { getDataForStatsChart } from './../../../../services/Helper';




const ChartWrapper = ({ title, stats_type, variant, donut, }) => {

    const [chartData, setChartData] = useState()
    const [firstRow, setFirstRow] = useState(title.split(" "))
    const [secondRow, setSecondRow] = useState()
    const getData = () => {
        getDataForStatsChart(stats_type).then((res) => {
            console.log("CHART DATA", res);
            setChartData(res?.map((option) => {
                return [option.name_en, option.count]
            }))

        }).finally(() => {

        })
    }

    useEffect(() => {
        getData()

    }, [])

    const options = {
        pieHole: donut && 0.5
    }

    return <>
        <Box sx={{
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.08)",
            borderRadius: "16px",
        }}
            component={Grid}
            container justifyContent={"flex-start"} alignItems={"flex-start"} rowSpacing={1} columnSpacing={1}
        >
            <Grid item xs={12}
                sx={{
                    backgroundColor: "#3a3b81",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                    height: "50px"
                }}
            >
                <Typography sx={{ fontSize: "24px", color: "white" }}>{title}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: "0px 16px" }}>
                {chartData && <>
                    <Chart
                        chartEvents={variant === "GeoChart" && [
                            {
                                eventName: "select",
                                callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart();
                                    const selection = chart.getSelection();
                                    if (selection.length === 0) return;
                                    const region = chartData[selection[0].row + 1];
                                    console.log("Selected : " + region);
                                },
                            },
                        ]}
                        chartType={variant}
                        data={
                            [
                                [...firstRow],
                                ...chartData
                            ]
                        }
                        width="100%"
                        options={options}
                        height="300px"
                        legendToggle
                    />

                </>
                }
            </Grid>
        </Box>
    </>


}

export default ChartWrapper