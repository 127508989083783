import "./LinkText.scss"

import { Box, Typography } from "@mui/material"
import { Link } from 'phosphor-react'

const LinkText = ({ text }) => {
    return (
        <Box component="div" className="link-text-wrapper">
            <Link size={20} />
            <Typography ml={0.5} className="link-text"><a className="common-link" target="_blank" href={`${text}`}> {text}</a></Typography>
        </Box>
    )
}

export default LinkText