import {
    FILTER_RECRUITERS,
    GET_PAGE_OF_RECRUITER,
    GET_SEARCH_OF_RECRUITER
} from '../../actions/RecruiterSearchAdmin/types';

let initialState = {
    data: [],
    meta: {},
    pageNumber: 1,
}


export const RecruiterSearchAdminReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_PAGE_OF_RECRUITER:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                pageNumber: payload.pageNumber
            }
        case GET_SEARCH_OF_RECRUITER:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                pageNumber: payload.pageNumber,
            }
            case FILTER_RECRUITERS:
                return {
                    ...state,
                    data: payload.data,
                    meta: payload.meta,
                    pageNumber: payload.pageNumber,
                }
        default:
            return state
    }

}