import { Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { IdentificationBadge } from "phosphor-react";
import React, { useEffect, useState } from 'react';
import { ReactComponent as List } from "../../assets/list.svg";
import { ReactComponent as Resume } from "../../assets/Resume.svg";
import { getAdminStats, makeDataForTreeMap } from '../../services/Helper';
import ChartWrapper from './components/charts/ChartWrapper';
import StatsCard from './components/stats-card/StatsCard';

import EChartsReact from 'echarts-for-react';
import { getDataForStatsChart } from './../../services/Helper';
import "./DashboardHome.scss";


function getLevelOption() {
    return [
        {

            itemStyle: {
                borderColor: '#3a3b81',

                borderWidth: 0,
                gapWidth: 0
            }
        },
        {
            itemStyle: {
                gapWidth: 0
            }
        },
        {
            colorSaturation: [0.35, 0.5],
            itemStyle: {
                gapWidth: 0,
                borderColorSaturation: 0.6
            }
        }
    ];
}


const DashboardHome = () => {

    const [stats, setStats] = useState()
    const [treeMapData, setTreeMapData] = useState()
    const [rootNodes, setRootNodes] = useState()


    const getDataForTreeMap = () => {
        getDataForStatsChart("job-regions").then((res) => {
            setRootNodes(makeDataForTreeMap(res))
        })

    }
    useEffect(() => {
        getAdminStats().then((res) => {
            console.log(res)
            setStats(res)
        }).catch((error) => {
            console.log(error)
        })
        getDataForTreeMap()
    }, [])
    useEffect(() => {
        setTreeMapData({
            title: {
                text: 'Global',
                left: 'center'
            },
            tooltip: {
                formatter: function (info) {
                    var value = info.value;
                    var treePathInfo = info.treePathInfo;
                    var treePath = [];
                    for (var i = 1; i < treePathInfo.length; i++) {
                        treePath.push(treePathInfo[i].name);
                    }
                    return ["Jobs : ",
                        info.value
                    ].join('');
                }
            },
            series: {
                label: {
                    show: true,
                    formatter: '{b}'
                },
                upperLabel: {
                    show: true,
                    height: 40
                },
                type: "treemap",
                tooltip: {},
                data: rootNodes,
                levels: getLevelOption(),
            }
        })
        console.log({ rootNodes })
    }, [rootNodes])


    return (
        <Box className="dashboard-home">
            <Grid container spacing={3} rowGap={3} mt={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <StatsCard
                        iconEnd={<Resume width={40} height={40} />}
                        cardTitle={t("labels.jobs-added")}
                        stats={stats?.job}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <StatsCard
                        iconEnd={<IdentificationBadge size={40} color="white" weight="regular" />}
                        cardTitle={t("others.applicants")}
                        stats={stats?.applicant}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <StatsCard
                        iconEnd={<List width={40} height={40} />}
                        cardTitle={t("labels.recruiters")}
                        stats={stats?.recruiter}
                    />
                </Grid>
                <Grid item xs={12} container columnSpacing={3} rowSpacing={3} >
                    <Grid item xs={8}>
                        <ChartWrapper title={"Followed Companies"} stats_type={"followed-companies"} variant={"Bar"} treeMap={false} />
                    </Grid>
                    <Grid item xs={4}>
                        <ChartWrapper title={"Work Type"} stats_type={"work-type"} variant={"PieChart"} treeMap={false} />
                    </Grid>
                    <Grid item xs={8}>
                        <ChartWrapper title={"Applicant Countries"} stats_type={"applicant-countries"} variant={"GeoChart"} treeMap={false} />
                    </Grid>
                    <Grid item xs={4}>
                        <ChartWrapper title={"Active/Inactive Jobs"} stats_type={"active-inactive"} variant={"PieChart"} donut={true} treeMap={false} />
                    </Grid>
                    <Grid item xs={8}>
                        <ChartWrapper title={"Companies Countries"} stats_type={"company-countries"} variant={"GeoChart"} />
                    </Grid>
                    <Grid item xs={4}>
                        <ChartWrapper title={"Application Status"} stats_type={"application-status"} variant={"PieChart"} donut={true} treeMap={false} />
                    </Grid>
                    <Grid item xs={8}
                    >
                        <Box sx={{
                            width: "100%",
                            backgroundColor: "white",
                            boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.08)",
                            borderRadius: "16px",
                        }}
                            component={Grid}
                            container justifyContent={"flex-start"} alignItems={"flex-start"} rowSpacing={1} columnSpacing={1}
                        >
                            <Grid item xs={12}
                                sx={{
                                    backgroundColor: "#3a3b81",
                                    borderTopLeftRadius: "16px",
                                    borderTopRightRadius: "16px",
                                    height: "50px"
                                }}
                            >
                                <Typography sx={{ fontSize: "24px", color: "white" }}>Job Regions</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {treeMapData &&
                                    <EChartsReact option={treeMapData} />
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardHome;