import React from 'react';
import ErrorPage from '../../../components/error-page/ErrorPage';
import animateData from "../../../assets/empty-page.json"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
const Page401 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <Box sx={{ height: "100%" }}>
            <Grid container justifyContent="center" alignItems="center" spacing={1} paddingTop={3}>
                <Grid item xs={12}>
                    <ErrorPage
                        title={t("labels.401")}
                        animationData={animateData}
                        actionText={t("labels.back_to_home")}
                        action={() => navigate("/")}
                    />
                </Grid>
            </Grid>
        </Box>

    );
};

export default Page401;