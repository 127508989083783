

import { Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { CaretLeft, CaretRight } from "phosphor-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import DecisionButton from "../decision-button/DecisionButton";
import Show from '../Show';


const DataTable = ({
  rows,
  columns,
  fetchPage,
  totalNumberOfPages,
  pageNumber,
  setPageNumber,
  isPaginated,
  filter
}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch()
  useEffect(() => {
    if (isPaginated)
      if (fetchPage)
        dispatch(fetchPage(10, currentPage, filter));
  }, []);

  useEffect(() => {
    if (isPaginated)
      if (pageNumber !== currentPage) {
        setCurrentPage(pageNumber)
      }
  }, [pageNumber])
  const handleNext = () => {
    if (currentPage < totalNumberOfPages) {

      setCurrentPage(currentPage + 1);
      setPageNumber?.(currentPage + 1);
      dispatch(fetchPage(10, pageNumber + 1, filter));
    }
    console.log(currentPage)

  };

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageNumber?.(currentPage - 1);
      dispatch(fetchPage(10, pageNumber - 1, filter));

    }
    console.log(currentPage)
  };


  function CustomPagination() {
    const { t, i18n } = useTranslation();

    return (
      <Grid
        container
        direction={"row"}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item sx={{ marginBottom: "5px" }}>
          <DecisionButton onClick={handleBack} disabled={currentPage <= 1} >
            <>
              <Grid container direction={"row"} spacing={1} alignItems="center">
                <Grid item>
                  <Show condition={i18n.language == "ar"}>
                    <CaretRight />
                  </Show>
                  <Show condition={i18n.language !== "ar"}>
                    <CaretLeft />
                  </Show>
                </Grid>
                <Grid item>
                  <Typography className="change-page-text">
                    {t("labels.back")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          </DecisionButton>
        </Grid>
        <Grid
          item
          container
          className="page-number"
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: "5px" }}
        >
          <Typography>{currentPage}</Typography>
        </Grid>
        <Grid item >
          <DecisionButton onClick={handleNext} disabled={currentPage >= totalNumberOfPages} >
            <>
              <Grid container direction={"row"} spacing={1} alignItems="center">
                <Grid item>
                  <Typography className="change-page-text">
                    {t("labels.next")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Show condition={i18n.language == "ar"}>
                    <CaretLeft />
                  </Show>
                  <Show condition={i18n.language !== "ar"}>
                    <CaretRight />
                  </Show>
                </Grid>
              </Grid>
            </>
          </DecisionButton>
        </Grid>
      </Grid>
    )

  }


  return (
    <div style={{ height: "350px", width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        disableDensitySelector
        // pageSize={10}  
        // page={currentPage}
        components={{
          Pagination: isPaginated ? CustomPagination : null,
        }}
        sx={{
          height: "100%",
          border: "none",
          fontFamily: 'Urbanist',
          color: "#404040",
          cursor: "pointer",
        }}
        autoHeight={true}
        className="data-table"
      />
    </div>
  )
}

export default DataTable