import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { At, CaretDown } from "phosphor-react";
import { checkErrorInValidation, translateField } from "../services/Helper";
import { Controller } from "react-hook-form";
import Hide from "./Hide";
import Show from "./Show";

const JLSelect = (props) => {
  // const isError = checkErrorInValidation(props);
  //
  const matched = useMediaQuery("(max-width:900px)");
  return (
    <>
      <FormControl
        variant="standard"
        fullWidth
        className={["standart-form-controller-select", props?.className].join(
          " "
        )}
      >
        <Hide condition={matched && props?.label === " "}>
          <label className="label">
            {props?.label}
            {!matched && props?.label === " " ? <span>&nbsp;</span> : null}
          </label>
        </Hide>

        <Controller
          defaultValue={props?.defaultValue !== undefined ? props?.defaultValue : ""}
          control={props?.control}
          name={props?.fieldName}
          render={({ field }) => {
            return (
              <Select
                disabled={props.disabled}
                value={field.value ? field.value : props?.defaultValue}
                // displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="select"
                variant="outlined"
                error={props.error}
                IconComponent={CaretDown}
                onChange={(data) => {
                  field.onChange(data);
                  props?.onChange?.(data);
                }}
              >
                {props?.defaultValue === undefined && (
                  <MenuItem key={-1} value="">
                    {props?.selectLabel}{" "}
                  </MenuItem>
                )}
                {props?.childs &&
                  props?.childs?.map((child) => (
                    <MenuItem key={child.id} value={child.id}>
                      {translateField(child, "name")}
                    </MenuItem>
                  ))}
              </Select>
            );
          }}
        />
        {props.error && (
          <FormHelperText style={{ color: "red" }}>
            {props.error.message}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
export default JLSelect;
