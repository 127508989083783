import { Box, Grid, useMediaQuery, Typography } from '@mui/material'

import RefuseAndShortList from "../refuse-and-shortlist/RefuseAndShortList"
import ProfileInformation from '../../../../components/profile-information/ProfileInformation'
import RatingBox from '../../../../components/rating-box/RatingBox'
import ProfileExperience from '../profile-experience/ProfileExperience'
import Show from '../../../../components/Show'
import EditProfile from '../edit-profile/EditProfile'
import { useSelector } from 'react-redux';
import { perm, translateField } from '../../../../services/Helper';
import Permissions from '../../../../constants/permissions';
import moment from "moment"
import { useTranslation } from 'react-i18next';
import AddAndInviteProfile from '../../../applicant-cv-profile/components/Add-invite-profile/AddAndInviteProfile'
import { useEffect, useState } from 'react'
import Hide from './../../../../components/Hide';
import BlockAndUnblock from '../../../applicant-cv-profile/components/Add-invite-profile/components/modals/block-unblock-btn/BlockAndUnblock'
import BlockHistoryBtn from '../../../../components/block-history-btn/BlockHistoryBtn'

const ProfileContent = ({ userData, hideActionBtns, showAddAndInviteProfile, setFetchTrigger }) => {
    const [isOwnProfile, setIsOwnProfile] = useState(false);
    const matched = useMediaQuery("(min-width:900px)")
    const permissions = useSelector((state) => state.profile.role?.permissions);
    const user_id = useSelector((state) => state.profile.data?.cv?.User?.id);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setIsOwnProfile(user_id === userData?.User?.id)
    }, [userData])

    return (
        <Grid container wrap="wrap">
            <Show condition={matched && !hideActionBtns}>
                {/* <Show condition={isOwnProfile && perm(permissions, Permissions.editApplicantProfile)}>
                    <EditProfile />
                </Show>
                {showAddAndInviteProfile ? (
                    <Hide condition={perm(permissions, Permissions.canApplyJob)}>
                        <AddAndInviteProfile
                            setFetchTrigger={setFetchTrigger}
                            cvBankStatus={userData.User.cv_bank} />
                    </Hide>
                ) : (
                    <Show condition={perm(permissions, Permissions.viewJobApplicants)}>
                        <RefuseAndShortList
                            id={userData?.id}
                            jobStatus={userData?.JobStatus}
                            jobStatusId={userData?.job_status_id}
                        />
                    </Show>
                )} */}
                <Grid item xs={12} >
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <BlockHistoryBtn 
                                style="applicant"
                                user={userData?.User?.UserBlocks}
                            />
                        </Grid>
                        <Grid item>
                            <BlockAndUnblock
                                blocked={userData?.User?.is_blocked === null ? false : true}
                                user={userData?.User}
                                setFetchTrigger={setFetchTrigger}
                            />
                        </Grid>
                    </Grid>
               
                </Grid>
            </Show>
            <Grid item xs={12}>
                <ProfileInformation
                    showTitle
                    title={t("labels.bio")}
                >
                    <Typography className="profile-exp-text">
                        {userData?.User?.bio}
                    </Typography>
                </ProfileInformation>
                {userData?.User?.CvExperiences?.map((exp, index) => {
                    return <ProfileInformation
                        showTitle={index === 0 ? true : false}
                        title={t("labels.work_experience")}
                    >
                        <ProfileExperience
                            startDate={`${moment().month(exp?.from_month - 1).format("MMM")} ${exp?.from_year}`}
                            from_year={exp?.from_year}
                            to_year={exp?.to_year}
                            from_month={moment().month(exp?.from_month - 1).format("MMM")}
                            to_month={moment().month(exp?.to_month - 1).format("MMM")}
                            endDate={exp?.to_year ? `${moment().month(exp?.to_month - 1).format("MMM")} ${exp?.to_year}` : "Present"}
                            expTitle={translateField(exp?.JobTitle, "name")}
                            expPlace={exp?.company_name}
                            companyType={translateField(exp?.JobCategory, "name")}
                            workStyle={translateField(exp?.ExperienceType, "name")}
                            expInfo={exp?.description}
                        />
                    </ProfileInformation>
                })}
                {userData?.User?.CvEducations?.map((edu, index) => {
                    return <ProfileInformation
                        showTitle={index == 0}
                        title={t("labels.education")}
                    >
                        <ProfileExperience
                            education
                            startDate={`${moment().month(edu?.from_month - 1).format("MMM")} ${edu?.from_year}`}
                            from_year={edu?.from_year}
                            to_year={edu?.to_year}
                            from_month={moment().month(edu?.from_month - 1).format("MMM")}
                            to_month={moment().month(edu?.to_month - 1).format("MMM")}
                            endDate={edu?.to_year ? `${moment().month(edu?.to_month - 1).format("MMM")} ${edu?.to_year}` : "Present"}
                            expTitle={translateField(edu?.EducationDegree, "name")}
                            expType={edu?.study_field}
                            expPlace={edu?.university_name}
                            degree={translateField(edu?.Grade, "name")}
                        />
                    </ProfileInformation>
                })}
            </Grid>
            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "1rem",
                columnGap: "1rem"
            }}
            >
                <Typography component="span" className="applicant-profile-title" mt={2} mb={2} >{t("labels.skills")}</Typography>
                {userData?.User?.CvCareerSkills?.map((skill) => {
                    return <RatingBox
                        title={skill?.name}
                        value={skill?.proficiency}
                        yearsOfExp={translateField(skill?.YearsOfExperience, "name")}
                    />
                })}
            </Box>
            <Typography className="applicant-profile-title" mt={2} mb={2} >{t("labels.languages")}</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    rowGap: "1rem",
                    columnGap: "1rem"
                }}
            >
                {userData?.User?.CvLanguageSkills?.map((lang) => {
                    return <RatingBox
                        title={lang?.name}
                        value={lang?.proficiency}
                    />
                })}
            </Box>
        </Grid>
    )
}

export default ProfileContent