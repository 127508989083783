import { Grid, Typography, ButtonBase } from "@mui/material";
import {
  LinkSimple,
  FacebookLogo,
  TwitterLogo,
  LinkedinLogo,
  WhatsappLogo,
} from "phosphor-react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import "./ShareJob.scss";

const ShareJob = (props) => {
  const { url, type } = props;

  const { t, i18n } = useTranslation();

  console.log("url", url);

  return (
    <Grid
      container
      direction={"row"}
      justifyContent="space-between"
      onClick={(event) => event.stopPropagation()}
    >
      <Grid item>
        <Typography className="share-text">
          {t("labels.share" + (type === "Company" ? "_company" : ""))}
        </Typography>
      </Grid>
      <Grid
        item
        onClick={() => {
          navigator.clipboard.writeText(url);
          toastr.info(t("labels.clipboard-copy"));
        }}
      >
        <LinkSimple className="share-icon" />
      </Grid>
      <Grid item>
        <FacebookShareButton url={url} hashtag={"#joblink"}>
          <FacebookLogo className="share-icon" />
        </FacebookShareButton>
      </Grid>
      <Grid item>
        <TwitterShareButton
          url={url}
          hashtags={["joblink", "recruitment", "jobs"]}
        >
          <TwitterLogo className="share-icon" />
        </TwitterShareButton>
      </Grid>
      <Grid item>
        <LinkedinShareButton url={url}>
          <LinkedinLogo className="share-icon" />
        </LinkedinShareButton>
      </Grid>
      <Grid item>
        <WhatsappShareButton url={url}>
          <WhatsappLogo className="share-icon" />
        </WhatsappShareButton>
      </Grid>
    </Grid>
  );
};
export default ShareJob;
