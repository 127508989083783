import {
  END_OTP_VERIFICATION,
  START_OTP_VERIFICATION,
  SUBMIT_OTP_TOKEN,
} from "../../actions/otp/types";

let initialState = {
  title: null,
  email: null,
  type: null,
  token: null,
};

export const otpReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_OTP_VERIFICATION:
      return {
        ...state,
        title: payload.title,
        type: payload.type,
        email: payload.email,
      };
    case SUBMIT_OTP_TOKEN:
      return {
        ...state,
        token: payload.access_token,
      };
    case END_OTP_VERIFICATION:
      return {
        ...state,
        title: null,
        email: null,
        type: null,
        token: null,
      };
    default:
      return state;
  }
};

export default otpReducer;
