import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { BrowserRouter, Routes, Route, useLocation, useParams, matchPath } from "react-router-dom";
import i18n from "./i18n";
import { routes } from './routes/pages';
import { useNavigate } from "react-router-dom";
import { getPermissionsArray, getRoutePath, searchInArray } from "./services/Helper";
import { assignRole, fetchProfile, loadProfileFromRedux } from "./state/actions/profile/profile"
import Layout from "./containers/dashboard/layout/Layout";
import ApplicantProfile from './views/applicant-profile/ApplicantProfile';
import JobDetails from "./views/job-details/JobDetails";
import CareerInterests from "./views/career-interests/CareerInterests";
import Notifications from "./views/send-notification/SendNotification";
import HomePage from "./views/home-page/HomePage";
import JoinUsReason from "./views/pages/join-us-reason/JoinUsReason";
import LoginAs from "./views/pages/login-as/LoginAs";
import Submit from "./views/pages/recutiersubmit/information/Submit";
import ApplicantSubmit from "./views/pages/applicant-submit/ApplicantSubmit";
import DefaultLayout from "./containers/default/layout/Layout";
import ApplicantsList from "./views/applicants-list/ApplicantsList";
import Jobs from "./views/jobs/Jobs";
import NewJob from "./views/new-job/NewJob";
import CandidatesBank from "./views/candidates-bank/CandidatesBank";
import Followers from "./views/followers/Followers";
import Settings from "./views/settings/Settings";
import Hide from "./components/Hide";
import Show from "./components/Show";
import { dashboardRoutes } from "./routes/dashboard";
import Permissions, { INITAL_ROLE } from "./constants/permissions";
import ModalsComponents from "./views/pages/zeinnab/ModalsComponents";
import Page401 from "./views/pages/page401/Page401";
import Page404 from "./views/pages/page404/Page404";
import { acquireToken } from "./services/auth/Auth";
import LoginModal from "./components/login-modal/LoginModal";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import Signin from "./views/pages/signin/Signin";
import ForgetPassword from "./views/pages/forget-password/ForgetPassword";
import ResetPasswordVerification from "./views/pages/reset-password-verification/ResetPasswordVerification";
import ResetPassword from "./views/pages/reset-password/ResetPassword";

const Main = () => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile.data);
    const role = useSelector((state) => state.profile.role);
    const permissionsString = useSelector((state) => state.profile.role?.permissions);
    const rehydrated = useSelector((state) => state._persist.rehydrated);
    const permissions = getPermissionsArray(permissionsString);

    useEffect(() => {
        console.log(window.location);
        const match = matchPath({ path: "/jobs/:job_id", exact: true }, window.location.pathname)
    }, [])

    useEffect(() => { if (rehydrated) setLoaded(true) }, [rehydrated])

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='*' element={<Page404 />} />
                    <Route path="/" element={<Layout />}>
                        {dashboardRoutes.map((route, id) => {
                            return <Route
                                key={id}
                                path={route.path}
                                name={route.name}
                                element={
                                    <ProtectedRoute permission={route.permission} permissions={permissions} loaded={loaded} >
                                        <route.component />
                                    </ProtectedRoute>
                                }
                                state={{ [route]: route.id }}
                            />
                        })}

                    </Route>
                    <Route path="/login" element={<Signin/>} />
                    <Route path="/forget-password" element={<ForgetPassword/>} />
                    <Route path="/reset-password-verification" element={<ResetPasswordVerification/>} />
                    <Route path="/reset-password" element={<ResetPassword/>} />
                </Routes>
            </BrowserRouter>
            <LoginModal />
        </>
    )
}
export default Main;