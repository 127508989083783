import { Typography, Grid, ButtonBase } from "@mui/material";
import { Eye, Spinner, X, CheckSquareOffset } from "phosphor-react";
import { useTranslation } from "react-i18next";
import Hide from "../Hide";
import "./ApplicationStatus.scss";

const ApplicationStatus = (props) => {
  const { status , hideText } = props;

  const { t, i18n } = useTranslation();

  const colors = {
    Pending: "#919191",
    Shortlisted: "#329f28",
    Refused: "#823a3d",
    Seen: "#3a3b81",
  };

  const text = {
    Pending: t("labels.pending_status"),
    Shortlisted: t("labels.shortlisted_status"),
    Refused: t("labels.refused_status"),
    Seen: t("labels.seen_status"),
  };

  const icons = {
    Pending: <Spinner />,
    Shortlisted: <CheckSquareOffset />,
    Refused: <X />,
    Seen: <Eye />,
  };

  return text[status] ? (
    <Grid
      container
      direction={"row"}
      alignItems={"center"}
      sx={{ color: colors[status] }}
    >
      <Grid item className="status-icon">
        {icons[status]}
      </Grid>
      <Hide condition={hideText}>
        <Grid item>
          <Typography className="status-text">{text[status]}</Typography>
        </Grid>
      </Hide>
    </Grid>
  ) : null;
};
export default ApplicationStatus;
