import {
    FILTER_RECRUITERS,
    GET_PAGE_OF_RECRUITER,
    GET_SEARCH_OF_RECRUITER
} from './types';

import { filterRecruiters, getPageOfRecruiter, getPageOfSearchRecruiter } from '../../../services/Helper';

export const getPageOfAdminRecruiter = (limit, pageNumber) => async (dispatch) => {
    const data = await getPageOfRecruiter(limit, pageNumber);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber
    }
    dispatch({ type: GET_PAGE_OF_RECRUITER, payload })

}

export const getPageOfSearchAdminRecruiter = (searchName, limit, pageNumber) => async (dispatch) => {
    const data = await getPageOfSearchRecruiter(searchName, limit, pageNumber);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,

    }
    dispatch({ type: GET_SEARCH_OF_RECRUITER, payload })
}
export const filterRecruitersAction = (filter, limit, pageNumber) => async (dispatch) => {
    const data = await filterRecruiters(filter, limit, pageNumber);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,

    }
    dispatch({ type: FILTER_RECRUITERS, payload })
}