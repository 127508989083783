import {
    FILTER_APPLICANTS_BY_COUNTRY,
    GET_SEARCH_OF_APPLLICANT,
    GET_PAGE_OF_APPLLICANT,
    FILTER_APPLICANTS,
    FILTER_APPLICANTS_BY_GENDER,
    FILTER_APPLICANTS_BY_STATUS,
    FILTER_APPLICANTS_BY_NATIONALITY
} from '../../actions/ApplicantSearchAdmin/types';

let initialState = {
    data: [],
    meta: {},
    pageNumber: 1,
}


export const ApplicantSearchAdminReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_PAGE_OF_APPLLICANT:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                pageNumber: payload.pageNumber
            }
        case GET_SEARCH_OF_APPLLICANT:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                pageNumber: payload.pageNumber,
            }
        case FILTER_APPLICANTS:
            return {
                ...state,
                data: payload.data,
                meta: payload.meta,
                pageNumber: payload.pageNumber,
            }


        default:
            return state
    }

}