import "./Sidebar.css";

import { Box, Button, Divider, Grid, IconButton, useMediaQuery } from "@mui/material";
import { ArrowSquareLeft, ArrowSquareRight } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangeLang from "../../../components/ChangeLang/ChangeLang";
import ColoredLogo from "../../../components/colored-logo/ColoredLogo";
import Show from "../../../components/Show";
import { logout } from "../../../services/auth/Auth";
import { perm, translateField } from "../../../services/Helper";
import { closeSidebar, toggleSidebar } from "../../../state/actions/sidebar/sidebar";
import CollapsedCategroy from "./components/collapsed-category/CollapsedCategroy";
import SidebarElement from "./components/sidebar-element/SidebarElement";
import { sidebarElements } from "./sidebarElements";

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();


    const [open, setOpen] = useState()
    const handleClick = (groupTitle) => {
        setOpen((prev) => !prev)
    }
    const mdMatch = useMediaQuery('(max-width:899.99px)');
    const [sidebarToggleClass, setSidebarToggleClass] = useState(mdMatch ? "display-none" : "w-86");
    useEffect(() => {
        setSidebarToggleClass(mdMatch ? "display-none" : "w-86")
    }, [mdMatch])
    const sidebarOpened = useSelector((state) => state.sidebar.opened);
    const permissions = useSelector((state) => state.profile.role?.permissions);
    const sidebarClassName = ["layout-sidebar", !sidebarOpened ? sidebarToggleClass : "fixed-position", mdMatch ? "fixed hvh-100" : ""].join(" ")

    useEffect(() => {
        if (mdMatch && sidebarOpened) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [mdMatch, sidebarOpened])
    const onToggleClick = () => {
        dispatch(toggleSidebar())
    }
    const closeSidebarOnResponsive = () => {
        // if (mdMatch) {
        dispatch(closeSidebar())
        // }
    }
    return (
        <Box component="div" className={sidebarClassName} >
            <Grid container direction="column" sx={{ height: "100%" }}  >
                <Grid container direction="column" marginBottom={3} >
                    <Grid container item sx={{ justifyContent: sidebarOpened ? "space-between" : "center", alignItems: "center" }} >
                        <Show condition={sidebarOpened}>
                            <Grid item>
                                <ColoredLogo />
                            </Grid>
                        </Show>
                        <Grid item>
                            <Box component="div">
                                <IconButton onClick={onToggleClick}>
                                    {sidebarOpened ? (
                                        <ArrowSquareLeft style={{ transform: `rotate(${i18n.language == 'ar' ? '180' : '0'}deg)` }} />
                                    ) : (
                                        <ArrowSquareRight style={{ transform: `rotate(${i18n.language == 'ar' ? '180' : '0'}deg)` }} />
                                    )}
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    flexGrow={1}
                    className="layout-sidebar-scroll"
                    paddingRight={1}
                >
                    {sidebarElements.map((element, index) => {
                        console.log("element", element)
                        if (element.permission && perm(permissions, element.permission)) {
                            if (element.children) {
                                return (
                                    <>
                                        <CollapsedCategroy
                                            sidebarOpened={sidebarOpened}
                                            element={element}
                                            closeSidebarOnResponsive={closeSidebarOnResponsive}
                                        />
                                        {
                                            element.divider && (
                                                <Divider flexItem light sx={{ marginY: 2 }} />
                                            )
                                        }
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <SidebarElement
                                            id={element.id}
                                            icon={<element.icon />}
                                            title={translateField(element, "title")}
                                            path={element.link}
                                            svg={element.svg}
                                            // parent={route.parent}
                                            opened={sidebarOpened}
                                            active={element.active}
                                            onClick={() => closeSidebarOnResponsive()}
                                        />
                                        {element.divider && (
                                            <Divider light sx={{ marginY: 2 }} />
                                        )}
                                    </>
                                )
                            }
                        }
                    })}
                </Grid>
                <Show condition={mdMatch}>
                    <Grid item marginBottom={2}>
                        <Grid container spacing={1} >
                            <Grid item>
                                <Button
                                    className="btn btn-home"
                                    onClick={() => {
                                        navigate("/");
                                        logout()
                                    }}
                                >
                                    {t("buttons.log_out")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <ChangeLang />
                            </Grid>
                        </Grid>
                    </Grid>
                </Show>
                {/* <Grid item>
                    <CurrentUser />
                </Grid> */}
            </Grid>
        </Box >
    );
}
export default Sidebar;