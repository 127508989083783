import {
    GET_PAGE_OF_APPLLICANT,
    GET_SEARCH_OF_APPLLICANT,
    GET_COUNTRY_BY_FILTER,
    FILTER_APPLICANTS_BY_COUNTRY,
    FILTER_APPLICANTS_BY_GENDER,
    FILTER_APPLICANTS_BY_STATUS,
    FILTER_APPLICANTS_BY_NATIONALITY,
    FILTER_APPLICANTS

} from './types';

import {
    getPageOfApplicant,
    getPageOfSearchApplicant,
    getPageOfApplicantCountry,
    filterApplicantsByCountryId,
    filterApplicantsByStatusId,
    filterApplicantsByGenderId,
    filterApplicantsByNationailtyId,
    filterApplicants
} from '../../../services/Helper';

export const getPageOfAdminApplicant = (limit, pageNumber, filter) => async (dispatch) => {
    const data = await getPageOfApplicant(limit, pageNumber, filter);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber
    }
    dispatch({ type: GET_PAGE_OF_APPLLICANT, payload })

}

export const getPageOfSearchAdminApplicant = (searchName, limit, pageNumber) => async (dispatch) => {
    const data = await getPageOfSearchApplicant(searchName, limit, pageNumber);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,
    }
    dispatch({ type: GET_SEARCH_OF_APPLLICANT, payload })
}

// export const getPageOfApplicantFilter = () => async (dispatch) => {
//     const data = await getPageOfApplicantCountry();
//     const payload = {
//         data: data.rows,
//         meta: data.meta,
//         pageNumber: data.meta.pageNumber,
//     }
//     dispatch({ type: GET_COUNTRY_BY_FILTER, payload })
// }

// export const filterApplicantsAction = (filterType, limit, pageNumber) => async (dispatch) => {
//     const data = await filterApplicants(filterType, limit, pageNumber);
//     const payload = {
//         data: data.rows,
//         meta: data.meta,
//         pageNumber: data.meta.pageNumber,
//     }
//     dispatch({ type: GET_SEARCH_OF_APPLLICANT, payload })
// }


export const filterApplicantsByCountry = (countryId, limit, pageNumber) => async (dispatch) => {
    const data = await filterApplicantsByCountryId(countryId, limit, pageNumber);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,
    }
    dispatch({ type: FILTER_APPLICANTS_BY_COUNTRY, payload })
}


export const filterApplicantsByStatus = (statusID, limit, pageNumber) => async (dispatch) => {
    const data = await filterApplicantsByStatusId(statusID, limit, pageNumber)
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,
    }
    dispatch({ type: FILTER_APPLICANTS_BY_GENDER, payload })
}
export const generalFilter = (searchParams, limit, pageNumber) => async (dispatch) => {
    const data = await filterApplicants(searchParams, limit, pageNumber)
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,
    }
    dispatch({ type: FILTER_APPLICANTS, payload })
}


export const filterApplicantsByGender = (genderID, limit, pageNumber) => async (dispatch) => {
    const data = await filterApplicantsByGenderId(genderID, limit, pageNumber);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,
    }
    dispatch({ type: FILTER_APPLICANTS_BY_STATUS, payload })
}


export const filterApplicantsByNationality = (nationalityID, limit, pageNumber) => async (dispatch) => {
    const data = await filterApplicantsByNationailtyId(nationalityID, limit, pageNumber);
    const payload = {
        data: data.rows,
        meta: data.meta,
        pageNumber: data.meta.pageNumber,
    }
    dispatch({ type: FILTER_APPLICANTS_BY_NATIONALITY, payload })
}