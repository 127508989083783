import React from 'react';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BlockHistoryTable from '../block-history-table/BlockHistoryTable';
import './BlockHistory.css'

const BlockHistory = ({ onClose, open , user}) => {
  var date = new Date('2015-02-10T10:12:50.5000z');
  date.toDateString();
  console.log(date)


  const historyColumns = [
    {
        field: 'id',
        headerName: '#',
        width: 90,
        align: "center",
        headerAlign: "center"
    },
 
    {
        field: 'date',
        headerName: "Date",
        width: 220,
        headerAlign: "center"
    },
    {
        field: 'reason',
        headerName: "Reason",
        width: 220,
        headerAlign: "center"
    },
]
const historyRows = user.map(row => (
  {
      id: row?.id,
      date: row?.created_at,
      reason: row?.reason
  }
))
  const {t} = useTranslation()
  return (
    <Dialog onClose={onClose} open={open} className="history-dialog-wrapper">
        <Typography className='title'>{t('others.block_history')}</Typography>
        <BlockHistoryTable
          rows = {historyRows}
          columns = {historyColumns}
        />
    </Dialog>
  )
}

export default BlockHistory