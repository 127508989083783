import { FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { EditorState } from 'draft-js';
import { convertFromHTML, convertToHTML } from "draft-convert";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect } from 'react';
import { editStaticPage, getStaticPages } from '../../services/Helper';
import DecisionButton from '../../components/decision-button/DecisionButton';
import { useTranslation } from 'react-i18next';
import Show from '../../components/Show';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import "./StaticPage.css";

const StaticPage = ({ pageKey }) => {
    const { t } = useTranslation();

    const [editorStateAR, setEditorStateAR] = useState(() => EditorState.createEmpty());
    const [editorStateEN, setEditorStateEN] = useState(() => EditorState.createEmpty());

    const onStateARChange = (state) => setEditorStateAR(state);
    const onStateENChange = (state) => setEditorStateEN(state);

    const schema = yup.object().shape({
        name_ar:
            yup
                .string()
                .required(t("validations.required_input")),
        name_en:
            yup
                .string()
                .required(t("validations.required_input")),
    });

    const { register,
        setValue,
        reset,
        control,
        formState: { errors },
        handleSubmit } =
        useForm({
            mode: 'all',
            reValidateMode: 'onBlur',
            resolver: yupResolver(schema),
            defaultValues: {
                name_ar: "",
                name_en: ""
            }
        });

    const onSubmit = (data) => {

        let editorAR = convertToHTML(editorStateAR.getCurrentContent());
        let editorEN = convertToHTML(editorStateEN.getCurrentContent());
        console.log(editorAR);
        editStaticPage({ key: pageKey, name_ar: data.name_ar, name_en: data.name_en, value_ar: editorAR, value_en: editorEN }).then((data) => {
            setValue("name_ar", data.name_ar);
            setValue("name_en", data.name_en);
            setEditorStateAR(() => EditorState.createWithContent(convertFromHTML(data.value_ar)));
            setEditorStateEN(() => EditorState.createWithContent(convertFromHTML(data.value_en)));
        })
    }

    useEffect(() => {
        const handleStaticPage = async (keyParam) => {
            return await getStaticPages(keyParam);
        }


        handleStaticPage(pageKey).then((data) => {
            if (data.length > 0) {
                setValue("name_ar", data[0].name_ar);
                setValue("name_en", data[0].name_en);
                setEditorStateAR(() => EditorState.createWithContent(convertFromHTML(data[0].value_ar)));
                setEditorStateEN(() => EditorState.createWithContent(convertFromHTML(data[0].value_en)));
            }
        })
    }, [pageKey])

    return (
        <>
            <Box component={"div"} className="static-page">
                <Stack spacing={2}>
                    <Typography className="title">{t("labels.name_in_arabic")}</Typography>
                    <Controller
                        name={"name_ar"}
                        control={control}
                        className="form-controller"
                        render={({ field: { onChange, value } }) => (
                            <>
                                <TextField
                                    className='input'
                                    placeholder={"Name in arabic"}
                                    onChange={onChange}
                                    value={value}
                                />
                                <FormHelperText className="error" >{errors.name_ar?.message}</FormHelperText>
                            </>
                        )}
                    />
                    <Typography className="title">{t("labels.value_in_arabic")}</Typography>
                    <Editor
                        editorState={editorStateAR}
                        wrapperClassName="editor-wrapper"
                        editorClassName="editor-placeholder"
                        onEditorStateChange={onStateARChange}
                    />
                </Stack>
            </Box>
            <Box component={"div"} className="static-page" marginTop={4}>
                <Stack spacing={2}>
                    <Typography className="title">{t("labels.name_in_english")}</Typography>
                    <Controller
                        name={"name_en"}
                        control={control}
                        className="form-controller"
                        render={({ field: { onChange, value } }) => (
                            <>
                                <TextField
                                    className='input'
                                    placeholder={"Name in english"}
                                    onChange={onChange}
                                    value={value}
                                />
                                <FormHelperText className="error" >{errors.name_en?.message}</FormHelperText>
                            </>
                        )}
                    />
                    <Typography className="title">{t("labels.value_in_english")}</Typography>
                    <Editor
                        editorState={editorStateEN}
                        wrapperClassName="editor-wrapper"
                        editorClassName="editor-placeholder"
                        onEditorStateChange={onStateENChange}
                    />
                </Stack>
            </Box>
            <Grid container marginTop={4} onClick={handleSubmit(onSubmit)}>
                <Grid item xs={2.5}>
                    <DecisionButton active>
                        <Typography sx={{ color: "#fff" }}>
                            Save
                        </Typography>
                    </DecisionButton>
                </Grid>
            </Grid>
        </>
    );
};

export default StaticPage;