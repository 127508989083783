import { toastr } from "react-redux-toastr";
import {
  changeEmail,
  login,
  refreshToken,
  resetPassword,
  signup,
  submitOTP,
} from "../../../services/AuthHelper";
import { finishLoading, startLoading } from "../loading/loading";
import { CHANGE_MODE } from "../mode/types";
import { startOTPVerification } from "../otp/otp";
import { SUBMIT_OTP_TOKEN } from "../otp/types";
import { resetProcess, startProcess, stopProcess } from "../process/process";
import { DELETE_PROFILE } from "../profile/types";
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SUBMIT_OTP_SUCCESSFULL,
} from "./types";

export const authLogin = (navigate, email, password) => async (dispatch) => {
  try {
    dispatch(startProcess("login"));
    const result = await login(email, password);
    console.log("OTP LOGIN", result);
    dispatch({ type: AUTH_LOGIN_SUCCESS, payload: result.data });
    navigate("/");
  } catch (error) {
    console.log("AUTH ERROR", error.response.data.error.message);
    const errorType = error.response.data.error.message;
    toastr.error(errorType);
    if (errorType && errorType == "Your email is not verified") {
      dispatch(
        startOTPVerification(
          "Enter OTP To Verify Email",
          email,
          "VERIFY_EMAIL"
        )
      );
      navigate("/verify-otp");
    }
    dispatch(stopProcess("login"));
  }
};

export const authSignup =
  (navigate, firstName, lastName, email, password) => async (dispatch) => {
    try {
      const result = await signup(firstName, lastName, email, password);
      dispatch(
        startOTPVerification("Enter OTP To Verify Email", email, "VERIFY_EMAIL")
      );
      toastr.success(result.message);
      navigate("/verify-otp");
    } catch (error) {
      toastr.error(error.response.data.error.message);
    }
  };

export const authSubmitOTP =
  (navigate, email, otp, type, redirect) => async (dispatch) => {
    try {
      const submitOTPResult = await submitOTP(email, otp, type);
      dispatch({
        type: SUBMIT_OTP_TOKEN,
        payload: submitOTPResult,
      });

      dispatch({ type: AUTH_SUBMIT_OTP_SUCCESSFULL, payload: submitOTPResult.data });

      toastr.success(submitOTPResult.message);
      navigate(redirect);
    } catch (error) {
      toastr.error(error.response.data.error.message);
    }
  };

export const authLogout = (navigate) => (dispatch) => {
  dispatch({ type: AUTH_LOGOUT_SUCCESS });
  dispatch({ type: DELETE_PROFILE, payload: null });
  dispatch({
    type: CHANGE_MODE,
    payload: { name: "guestApplicant" },
  });
  dispatch(resetProcess("login"));
  if (navigate) {
    navigate("/");
  }
};

export const authResetPassowrd =
  (navigate, newPassword, confirmPassword, email) => async (dispatch) => {
    try {
      const result = await resetPassword(newPassword, confirmPassword, email);
      toastr.success(result.message);
      navigate("/login");
    } catch (error) {
      toastr.error(error.response.data.error.message);
    } finally {
      dispatch(authLogout(navigate));
    }
  };

export const authChangeEmail = (navigate, email, token) => async (dispatch) => {
  try {
    const result = await changeEmail(email, token);
    dispatch(
      startOTPVerification(
        "Enter OTP To Change Email",
        email,
        "SUBMIT_CHANGE_EMAIL"
      )
    );
    toastr.success(result.message);
    navigate("/verify-otp");
  } catch (error) {
    toastr.error(error.response.data.error.message);
  }
};

export const authRefreshToken = () => async (dispatch, getState) => {
  const token = getState().auth.refreshToken;
  if (token) {
    const result = await refreshToken(token);
    dispatch({ type: AUTH_LOGIN_SUCCESS, payload: result });
  }
};
