import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    Box,
    Typography,
    Grid,
    Button,
    FormHelperText
} from "@mui/material";
import ShareJob from "../../../../components/share-job/ShareJob";
import "./ShareJobPost.scss";
import { X } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { shareJob } from "../../../../services/Helper";
import Show from "../../../../components/Show";
import { useSelector } from "react-redux";



const ShareJobPost = (props) => {

    const { t, i18 } = useTranslation();
    const { onClose, open } = props;
    const [value, setValue] = useState('');
    const [links, setLinks] = useState([]);
    const [linksError, setLinksError] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const role = useSelector((state) => state.profile.role?.name);

    const { id, type } = props;


    const handleChang = (e) => {
        setValue(e.target.value)
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(e.target.value)) {
            setValidEmail(false)
        } else {
            setValidEmail(true)
        }
    }

    const handleClick = () => {
        if (validEmail === false) {
            if (value !== '') {
                const newArr = [...links]
                newArr.push(value);
                setLinks(newArr)
                setValue('')
                if (newArr.length > 0)
                    setLinksError(false);
            }
        }
    }
    const handleDelete = (index) => {
        const newArr = [...links]
        newArr.splice(index, 1)
        //    links.splice(index,1);
        setLinks(newArr)
    }
    const handleSend = () => {
        if (links.length == 0) {
            setLinksError(true);
        } else {
            setLinksError(false);
            shareJob(id, links).finally(() => {
                onClose();
                setLinks([])
            })
        }

    }

    return (

        <Dialog onClose={onClose} open={open} className="shareJobPost-dialog">
            <DialogTitle sx={{ padding: "0" }} className="title">{t(`others.share_${type === "Company" ? "company" : "job"}_post_title`)}</DialogTitle>
            <Box sx={{
                border: "1px solid rgba(34, 34, 34, 0.1)",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "24px"
            }}
            >
                <ShareJob url={`https://${window.location.host}/${type === "Company" ? "company" : "jobs"}/${id}`} type={type} />
            </Box>
            <Show condition={role == "RECRUITER"}>
                <Box className="send-email-invitation">
                    <Typography className="title">{t("others.send_email_invitation_title")}</Typography>
                    <div className="send-email-box">
                        <input value={value} onChange={e => handleChang(e)} type="email"
                            placeholder={t("placeholders.enter-email")} />
                        <button onClick={handleClick}>{t("buttons.add")}</button>
                    </div>
                    {validEmail ? <FormHelperText style={{ color: "red", margin: "10px 0" }}>{t("validations.email_format")}</FormHelperText> : null}
                    <div className="sended-emails">
                        {
                            links.map((link, index) => {
                                return (
                                    <Grid container className="links" key={index}>
                                        <Grid item className="link">{link}</Grid>
                                        <Grid item className="icon" >
                                            <X onClick={() => handleDelete(index)} />
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </div>
                    {linksError && <FormHelperText style={{ color: "red", margin: "10px 0" }}>{t("validations.at_least_one")}</FormHelperText>}
                </Box>
                <Box>
                    <Grid container >
                        <Grid item container justifyContent={"flex-end"}>
                            <Grid item>
                                <Button className="cancel-btn" onClick={onClose}>{t("buttons.cancel")}</Button>
                            </Grid>
                            <Grid item>
                                <Button className="send-btn" onClick={() => { handleSend(); }}>{t("buttons.send")}</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Box>
            </Show>

        </Dialog>
    )
}

export default ShareJobPost