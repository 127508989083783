import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Button,
  FormHelperText,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  updateSettingsAdmin,
  getExpirationPeriod,
  getMobileVersions,
  updateMobileVersions,
  editStaticPage,
} from "../../services/Helper";
import "./Settings.css";

const Settings = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    period: yup
      .string()
      .matches(/^\d+$/, t("validations.must_be_number"))
      .required(t("validations.required_input")),
  });
  const {
    register,
    setValue,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    setLoading(true);
    console.log(data);
    updateSettingsAdmin(data.period)
      .then((res) => {
        // setJobExpirationPeriod(res.value)
        console.log(res);
      })
      .finally(async () => {
        delete data.period;

        for (const key in data) {
          await editStaticPage({ key, value_en: data[key], value_ar: data[key] }).catch((err) =>
            console.log(err)
          );
        }

        setLoading(false);
      });
  };
  useEffect(() => {
    getExpirationPeriod()
      .then((res) => {
        console.log(res.value);
        // setJobExpirationPeriod(res.value)
        setValue("period", res.value);
      })
      .catch((err) => {
        console.log(err);
      });

    getMobileVersions()
      .then((res) => {
        console.log(res);
        res.forEach((version) => {
          console.log(version.key);
          setValue(version.key.toLowerCase(), version.value_en);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="settings">
      <Grid container>
        <Grid item xs={12} sm={9}>
          <form>
            <Box>
              <Typography className="title">
                {t("others.job_expiration")}
              </Typography>
              <Typography className="label">{t("labels.period")}</Typography>
              <Controller
                name={"period"}
                control={control}
                className="form-controller"
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      className="input"
                      placeholder={t("placeholders.enter_period")}
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" className="days">
                            {t("placeholders.days")}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText className="error">
                      {errors.period?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </Box>

            <Box mt={7}>
              <Box>
                <Typography className="title">
                  {t("others.mobile_versions")}
                </Typography>

                <Typography className="label">
                  {t("labels.applicant_ios")}
                </Typography>
                <Controller
                  name={"mobile_applicant_ios_version"}
                  control={control}
                  className="form-controller"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        className="input"
                        placeholder={t("placeholders.enter_version")}
                        onChange={onChange}
                        value={value}
                      />
                      <FormHelperText className="error">
                        {errors.applicant_ios?.message}
                      </FormHelperText>
                    </>
                  )}
                />
                <Typography className="label">
                  {t("labels.applicant_android")}
                </Typography>
                <Controller
                  name={"mobile_applicant_android_version"}
                  control={control}
                  className="form-controller"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        className="input"
                        placeholder={t("placeholders.enter_version")}
                        onChange={onChange}
                        value={value}
                      />
                      <FormHelperText className="error">
                        {errors.applicant_android?.message}
                      </FormHelperText>
                    </>
                  )}
                />
                <Typography className="label">
                  {t("labels.applicant_huawei")}
                </Typography>
                <Controller
                  name={"mobile_applicant_huawei_version"}
                  control={control}
                  className="form-controller"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        className="input"
                        placeholder={t("placeholders.enter_version")}
                        onChange={onChange}
                        value={value}
                      />
                      <FormHelperText className="error">
                        {errors.applicant_huawei?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>

              <Box mt={3}>
                <Typography className="label">
                  {t("labels.recruiter_ios")}
                </Typography>
                <Controller
                  name={"mobile_recruiter_ios_version"}
                  control={control}
                  className="form-controller"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        className="input"
                        placeholder={t("placeholders.enter_version")}
                        onChange={onChange}
                        value={value}
                      />
                      <FormHelperText className="error">
                        {errors.recruiter_ios?.message}
                      </FormHelperText>
                    </>
                  )}
                />
                <Typography className="label">
                  {t("labels.recruiter_android")}
                </Typography>
                <Controller
                  name={"mobile_recruiter_android_version"}
                  control={control}
                  className="form-controller"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        className="input"
                        placeholder={t("placeholders.enter_version")}
                        onChange={onChange}
                        value={value}
                      />
                      <FormHelperText className="error">
                        {errors.recruiter_android?.message}
                      </FormHelperText>
                    </>
                  )}
                />
                <Typography className="label">
                  {t("labels.recruiter_huawei")}
                </Typography>
                <Controller
                  name={"mobile_recruiter_huawei_version"}
                  control={control}
                  className="form-controller"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        className="input"
                        placeholder={t("placeholders.enter_version")}
                        onChange={onChange}
                        value={value}
                      />
                      <FormHelperText className="error">
                        {errors.recruiter_huawei?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={3} className="btn-container">
          <Button
            className="btn"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            {t("buttons.save_changes")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
