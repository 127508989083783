import { useState } from 'react'

import { Paper, Typography, Divider, Stack, Button, IconButton, useMediaQuery, Grid } from '@mui/material'

//component re-usable content
import UserAvatar from '../../../../components/user-avatar/UserAvatar'
import { BookmarkSimple } from 'phosphor-react'
import ProfileOtherLinks from '../profile-other-links/ProfileOtherLinks'
import RefuseAndShortList from '../refuse-and-shortlist/RefuseAndShortList'
import Show from '../../../../components/Show'
import ProfileContactInfo from './../profile-contact-info/ProfileContactInfo';
import ProfileBasicDetails from './../profile-basic-details/ProfileBasicDetails';
import EditProfile from '../edit-profile/EditProfile'
import Hide from '../../../../components/Hide'
import { useSelector } from 'react-redux';
import { perm, translateField } from './../../../../services/Helper';
import Permissions from '../../../../constants/permissions'
import { useTranslation } from 'react-i18next';
import AddAndInviteProfile from '../../../applicant-cv-profile/components/Add-invite-profile/AddAndInviteProfile'
import BlockAndUnblock from '../../../applicant-cv-profile/components/Add-invite-profile/components/modals/block-unblock-btn/BlockAndUnblock'
import BlockHistoryBtn from '../../../../components/block-history-btn/BlockHistoryBtn'


const ProfileInfo = ({ userData, hideSaveIcon, showAddAndInviteProfile, setFetchTrigger }) => {

    console.log(userData?.User?.UserBlocks)
    const matched = useMediaQuery("(max-width:900px)")
    const role = useSelector((state) => state.profile.role?.name)
    const permissions = useSelector((state) => state.profile.role?.permissions)
    const [isApplicant, setIsApplicant] = useState(() => {
        if (role === "APPLICANT")
            return true
        else
            return false
    })
    const { t, i18n } = useTranslation();
    console.log(isApplicant)

    return <>
        <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
        >
            <Grid item xs={matched ? 8 : 12} mb={2.25}>
                <Typography component="span" className="applicant-profile-title">
                    {t("labels.applicant_profile")}
                </Typography>
            </Grid>
                <Show condition={matched}>
                    <Grid item xs={4}>
                        <Grid container direction={"row"}>
                            <Grid item>
                                <BlockHistoryBtn 
                                    style="applicant"
                                    user={userData?.User?.UserBlocks}
                                 />
                            </Grid>
                            <Grid item>
                            <BlockAndUnblock
                                blocked={userData?.User?.is_blocked === null ? false : true}
                                user={userData?.User}
                                setFetchTrigger={setFetchTrigger}
                            />
                            </Grid>

                        </Grid>
                    </Grid>
                </Show>
        </Grid>
        <Paper className="profile-info">
            <Stack
                mb={10}
                direction="column"
                spacing={2}
                divider={<Divider orientation="horizontal" flexItem />}
            >
                <UserAvatar
                    showAvatar
                    showTitle
                    showName
                    name={userData?.User?.first_name + " " + userData?.User?.second_name}
                    title={translateField(userData?.User?.JobTitle, "name")}
                    size="large"
                    avatar={userData?.User?.image}
                />
                <ProfileContactInfo
                    user={userData?.User}
                    email={userData?.User?.work_email}
                    phone={userData?.User?.mobile ? userData?.User?.mobile : null}
                />
                <ProfileBasicDetails
                    user={userData?.User}
                />
                {userData?.User?.CvSocialMedia?.length > 0 &&
                    <ProfileOtherLinks
                        user={userData?.User}
                    />
                }
            </Stack>
            {/* <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Button className="btn btn-dark download-cv">{t("buttons.download_cv")}</Button>
                <Hide condition={!perm(permissions, Permissions.editCompanyProfile) && hideSaveIcon}>
                    <IconButton className="save-cv"><BookmarkSimple /></IconButton>
                </Hide>

            </Stack> */}
        </Paper>
    </>

}

export default ProfileInfo