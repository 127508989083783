import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./scss/style.scss";
import LoadingBar from "react-redux-loading-bar";
import ReduxToastr from "react-redux-toastr/lib/ReduxToastr";
import Main from "./Main";
import { use } from "i18next";
import { Helmet } from "react-helmet";
//import './i18n';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
// import ChangeLang from "./components/ChangeLang/ChangeLang"


const App = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let currentLang = i18n.language.split("-")[0]
    i18n.changeLanguage(currentLang)
    if (currentLang === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [i18n.language])
  return (
    <div className="App">
      <Helmet>
        <title>JOBLINK</title>
      </Helmet>
      {/* <h1>{t("validations.common.required_input")}</h1> */}
      {/* <ChangeLang /> */}
      <LoadingBar direction={i18n.language == "ar" ? "rtl" : "ltr"} style={{ position: "fixed", top: "0" }} />
      <ReduxToastr
        timeOut={2500}
        newestOnTop={false}
        preventDuplicates
        position={i18n.language == "ar" ? "top-left" : "top-right"}
        getState={(state) => state.toastr} // This is the default
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar
        closeOnToastrClick
      />
      <Main />
    </div>
  );
};

export default App;
