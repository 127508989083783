import { Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import { adminDeleteJob } from "../../../../services/Helper";
import "./DeleteJobModal.scss";
import { useNavigate } from "react-router-dom";
const DeleteJobModal = ({
  open,
  setOpen,
  jobId,
  recruiterId,
  deleteFromMiniJobCard,
  setDeleteFromMiniJobCard,
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    console.log(recruiterId);
  };
  const [loading, setLoading] = useState(false);
  const handleDeleteJob = () => {
    setLoading(true);
    if (deleteFromMiniJobCard) {
      adminDeleteJob(jobId)
        .then((res) => {
          handleClose();
          setDeleteFromMiniJobCard(true);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      adminDeleteJob(jobId)
        .then((res) => {
          handleClose();
          navigate(`/recruiters/${recruiterId}`);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Dialog open={open} className="delete-modal">
      <Grid
        container
        rowSpacing={4}
        justifyContent={"center"}
        justifyItems={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} align="flex-start">
          <Typography className="delete-title">
            {t("titles.delete_modal_title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"space-evenly"}
            columnGap={2}
          >
            <Button className="cancel-btn" onClick={handleClose}>
              {t("buttons.cancel")}
            </Button>
            <Button className="delete-btn" onClick={handleDeleteJob} disabled={loading}>
              {t("buttons.delete")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteJobModal;
