import axios from "axios";
import { DownloadSimple, Eye, PencilSimple, Trash } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAppLanguages,
  getLanguageList,
  getLocales,
} from "./../../services/Helper";
import DeleteModal from "./DeleteModal";
import LanguagesModal from "./LanguagesModal";
import LocalesModal from "./LocalesModal";

const sv =
  "?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2030-03-04T20:24:46Z&st=2022-03-04T12:24:46Z&spr=https&sig=wxQMD3PrF%2BWJ3JzJ8s%2F64sPTWcg7PMNbvRju9JR8h9Y%3D";

const getLocaleRedirectURL = (id) => {
  return `/app-languages?app_language_id=${id}`;
};
const useAppLanguages = () => {
  const languagesColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Name",
      width: 350,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "locale",
      headerName: "Locale",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const onClick = () => {
          setCurrentRow(row);
          setOpenEditlModal(true);
        };
        return (
          <>
            <PencilSimple onClick={onClick} />
          </>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      width: 250,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const onClick = () => {
          console.log("View", { row });

          navigate(getLocaleRedirectURL(row.id));
        };
        return (
          <>
            <Eye onClick={onClick} />
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 250,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const onClick = () => {
          setCurrentRow(params?.row);
          setOpenDeleteModal(true);
        };
        return (
          <>
            <Trash onClick={onClick} />
          </>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [columns, setColumns] = useState(languagesColumns);
  const [langs, setLangs] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditlModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [languagesDropDownList, setLanguageDropDownList] = useState([]);

  const changeColumnsToLocales = () => {
    setColumns([
      {
        field: "id",
        headerName: "ID",
        width: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "locale_name",
        headerName: "Name",
        width: 350,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "locale_code",
        headerName: "Code",
        width: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "local_side",
        headerName: "Side",
        width: 250,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "download",
        headerName: "Download",
        width: 250,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          const onClick = async () => {
            const json_file_link = params.row.download_link;
            // window.open(json_file_link)
            // const file = await axios.get(json_file_link);
            var element = document.createElement("a");
            element.setAttribute("href", json_file_link);
            element.setAttribute("target", "_blank");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          };
          return (
            <>
              <DownloadSimple onClick={onClick} />
            </>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit",
        width: 250,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          const onClick = () => {
            setOpenEditlModal(true);
            console.log("Edit");
            console.log(params);
            setCurrentRow(params.row);
          };
          return (
            <>
              <PencilSimple onClick={onClick} />
            </>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 250,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          const onClick = () => {
            setCurrentRow(params?.row);
            setOpenDeleteModal(true);
          };
          return (
            <>
              <Trash onClick={onClick} />
            </>
          );
        },
      },
    ]);
  };
  const makeRow = (lang) => {
    return {
      id: lang.id,
      lang_id: lang.Language.id,
      name: lang.name,
      locale: lang.locale,
      status: lang.status === 1 ? "Ready" : "Not Ready",
      status_code: lang.status,
    };
  };
  const makeLocaleRow = (locale) => {
    return {
      id: locale?.id,
      name: locale?.AppLanguage.name,
      locale_code: locale?.AppLanguage.locale,
      locale_name: locale?.AppLanguage.name,
      local_side: locale?.LanguageSide.side,
      local_side_id: locale?.side_id,
      download_link: locale?.json_file,
    };
  };
  const handleClose = (trigger) => {
    setOpenAddModal(false);
    setOpenEditlModal(false);
    setOpenDeleteModal(false);
    if (trigger) setTrigger((prev) => !prev);
  };
  const AddModal = () => {
    return searchParams.get("app_language_id") ? (
      <LocalesModal open={openAddModal} closeHandler={handleClose} />
    ) : (
      <LanguagesModal
        languages_list={languagesDropDownList}
        open={openAddModal}
        langs={langs}
        closeHandler={handleClose}
      />
    );
  };
  const EditModal = () => {
    return searchParams.get("app_language_id") ? (
      <>
        <LocalesModal
          open={openEditModal}
          row={currentRow}
          closeHandler={handleClose}
          edit={true}
        />
      </>
    ) : (
      <LanguagesModal
        languages_list={languagesDropDownList}
        open={openEditModal}
        row={currentRow}
        langs={langs}
        closeHandler={handleClose}
        edit={true}
      />
    );
  };
  const RenderDeleteModal = () => {
    if (searchParams.get("app_language_id")) {
      return (
        <DeleteModal
          open={openDeleteModal}
          locale={true}
          setTrigger={setTrigger}
          row={currentRow}
          closeHandler={handleClose}
        />
      );
    } else {
      return (
        <DeleteModal
          row={currentRow}
          setTrigger={setTrigger}
          open={openDeleteModal}
          closeHandler={handleClose}
        />
      );
    }
  };
  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const get_and_set_locales = () => {
    getLocales(searchParams.get("app_language_id")).then((res) => {
      changeColumnsToLocales();
      if (res)
        setLangs(
          res.map((locale) => {
            return makeLocaleRow(locale);
          })
        );
      else {
        setLangs([]);
      }
    });
  };

  useEffect(() => {
    if (searchParams.get("app_language_id")) {
      get_and_set_locales();
    } else {
      getAppLanguages().then((res) => {
        setLangs(res.map((lang) => makeRow(lang)));
        setColumns(languagesColumns);
        console.log(res);
      });
      getLanguageList().then((res) =>
        console.log(setLanguageDropDownList(res))
      );
    }
  }, []);
  useEffect(() => {
    if (searchParams.get("app_language_id")) get_and_set_locales();
    else {
      getAppLanguages().then((res) => {
        setLangs(res.map((lang) => makeRow(lang)));
        setColumns(languagesColumns);
        console.log(res);
      });
    }
    console.log(searchParams);
  }, [searchParams, trigger]);

  return {
    langs,
    handleOpenAddModal,
    AddModal,
    EditModal,
    RenderDeleteModal,
    columns,
  };
};

export default useAppLanguages;
