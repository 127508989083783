import React from 'react';
import "./TermsConditions.css";
import StaticPage from '../static-page/StaticPage';

const TermsConditions = () => {


    return (
        <>
            <StaticPage pageKey={"Terms"} />
        </>
    );
};

export default TermsConditions;