import "./OneNotification.css";

import { Box, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import { Checks } from "phosphor-react";
import UserAvatar from "../../../../../../../components/user-avatar/UserAvatar";
import { Link, useNavigate } from "react-router-dom";
import Show from "../../../../../../../components/Show";
import { useSelector } from "react-redux";

const OneNotification = ({ active, message, link, name, image, onClick }) => {

    const navigate = useNavigate();

    const renderDesc = (role) => {

        return (
            <>
                <Box component="div">
                    {message}.{" "}
                    {/* <Link to={link}>
                        See application
                    </Link> */}
                </Box>
            </>
        )
    }

    return (
        <>
            <Box component="div" className={["one-notification", active ? "active" : ""].join(" ")} onClick={onClick}>
                <UserAvatar
                    showAvatar
                    showName
                    showTitle
                    size="small"
                    name={name}
                    title={renderDesc()}
                    avatar={image}
                />
                <Show condition={active} >
                    <Box component="div" className="round"></Box>
                </Show>
            </Box>
        </>
    )
}
export default OneNotification;