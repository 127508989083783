// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';

import { store } from "./state/store";
import { NEW_NOTIFICATION } from './state/actions/notification/types';
import { toastr } from 'react-redux-toastr';
import { translateField } from "./services/Helper";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyBIQkr24o1Yr4l8h8vvoZGGFq22VYOhp_A",
    authDomain: "joblinks-337812.firebaseapp.com",
    projectId: "joblinks-337812",
    storageBucket: "joblinks-337812.appspot.com",
    messagingSenderId: "640542811327",
    appId: "1:640542811327:web:f5fa50858a27c48a92520a",
    measurementId: "G-WD7QFZQYSK"
};

// Initialize Firebase
initializeApp(firebaseConfig);


const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BJaBIIqziVQLYpvK0xJ8sPqOCYK80K-yZpLOQo_LYcmxFCfiwmMbZddyzAjMG8fK_lWoujSlU5eQXQrjJXlTKaU" })
        .then((currentToken) => {
            return currentToken
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

export const deleteFirebaseToken = () => {
    return deleteToken(messaging);
}

export const requestNewToken = async () => {
    await deleteToken(messaging);
    return await requestForToken();
}

export const onMessageListener = () => {
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            const notificationToRender = {
                title: payload.notification.title,
                body: payload.notification.body.length > 45 ? payload.notification.body.slice(0, 45) + "..." : payload.notification.body
            }
            store.dispatch({ type: NEW_NOTIFICATION })
            toastr.success(translateField(payload.data, "message_property"))
            console.log("payload", payload)
            resolve(payload);
        });
    })
};