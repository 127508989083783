import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from '@mui/material';
import { X } from 'phosphor-react';
import { useNavigate } from "react-router-dom";
import './Modals.scss'

const Modals = (props) => {
  const {
    open,
    handleClose,
    title,
    content,
    disagree,
    agree,
    type,
    handleAgree
  } = props;
  const colors = {
    delete: "#ffffff",
    apply: "#ffffff",
    confirmation: "#ffffff",
    unfollow: "#3a3b81",
  }
  const backgrounds = {
    delete: "#de3b43",
    apply: "#3a3b81",
    confirmation: "#3a3b81",
    unfollow: "rgba(58, 59, 129, 0.1)",
  }

  const navigate = useNavigate();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='dialog'
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton onClick={handleClose}
            sx={{
              // position: 'absolute',
              // right: 8,
              // top: 8,
              marginLeft: "28px"
            }}

          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="disagree-btn"
          // sx={{
          //     color: colors[type]
          // }}
          >
            {disagree}
          </Button>
          <Button onClick={() => { handleAgree(); handleClose(); }}
            className='agree-btn'
            sx={{
              color: colors[type],
              background: backgrounds[type],
              "&:hover": {
                background: backgrounds[type]
              }
            }}
          >
            {agree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Modals