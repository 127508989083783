import axios, { Axios } from "axios";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { toastr } from 'react-redux-toastr'
import { STATUS_CODES } from "../constants/constants"
import { finishLoading, startLoading } from "../state/actions/loading/loading";
import { store } from "../state/store";
import { acquireToken, login, logout } from "./auth/Auth";
import i18n from "../i18n";
import { deleteProfile } from "../state/actions/profile/profile";
import { OPEN_LOGIN_MODAL } from "../state/actions/login-modal/types";

const { dispatch, getState } = store;

export const Api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    // headers: { type: getState()?.profile?.role?.name }
});



Api.defaults.params = {};
Api.interceptors.request.use(
    async (config) => {
    console.log(config.switchBaseUrlFlag)
        if (config.switchBaseUrlFlag) {
            config.baseURL = process.env.REACT_APP_ADMIN_API_URL
        }
        dispatch(startLoading());
        let token = await acquireToken();
        console.log(token)
        if (token)
            config.headers['Authorization'] = 'Bearer ' + token;
        if (config.params["openPopup"] == true) {
            store.dispatch({ type: OPEN_LOGIN_MODAL });
            throw new axios.Cancel("Request need to be canceled");
        }
        if (config.params["stopRequest"] == true) {
            throw new axios.Cancel("Request need to be canceled");
        }
        config.params['lang'] = i18n.language;
        dispatch(showLoading())

        return config;
    },
    (error) => {
        dispatch(finishLoading());
        dispatch(hideLoading())
        toastr.error(error);
        return Promise.reject(error);
    }
);

Api.interceptors.response.use(
    (response) => {
        dispatch(finishLoading());
        dispatch(hideLoading())
        
        const statusCode = response.data.statusCode;
        switch (statusCode) {
            case STATUS_CODES.SUCCESS:
                if (response.data.message)
                    toastr.success(response.data.message);
                break;
            case STATUS_CODES.NOT_FOUND:
                // window.location = "/404"
                break;
            default:
                break;
        }
        return response?.data?.data;
    },
    (error) => {
        console.log(error)
        dispatch(finishLoading());
        dispatch(hideLoading())
        const statusCode = error?.response?.data?.error?.status;
        const message = error?.response?.data?.error?.message;
        switch (statusCode) {
            case STATUS_CODES.UNAUTHORIZED:
                // if (message && message !== "null")
                //     toastr.error(message);
                store.dispatch(deleteProfile())
                window.location = "/login";
                break;
            case STATUS_CODES.NOT_FOUND:
                if (message && message !== "null")
                    toastr.error(message);
                // window.location = "/404"
                break;
            case STATUS_CODES.BAD_REQUEST:
                if (message && message !== "null")
                    toastr.error(message);
                break;
            default:
                if (message && message !== "null")
                    toastr.error(message);
                break;
        }
        return Promise.reject(error);
    }
);