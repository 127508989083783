import { useSelector } from "react-redux";
import { perm, searchInArray } from "../../services/Helper"
import { Route, Navigate } from "react-router-dom";
import Page401 from "../../views/pages/page401/Page401";

const ProtectedRoute = ({ permission, permissions, children, loaded }) => {
    let pass = false;
    if (permission == null) {
        pass = true
    } else {
        pass = perm(permissions, permission)
    }
    if (loaded) {
        return pass ? (
            children
        ) : (
            <Navigate to="/403" />
        )
    } else {
        return <></>
    }



}
export default ProtectedRoute;