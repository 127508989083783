import { Box, Collapse, Divider, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import { CaretDown, CaretUp } from "phosphor-react";
import React, { useState } from 'react';
import Show from '../../../../../components/Show';
import { translateField } from '../../../../../services/Helper';
import SidebarElement from '../sidebar-element/SidebarElement';

const CollapsedCategroy = ({ element, sidebarOpened, closeSidebarOnResponsive }) => {

    const [open, setOpen] = useState(true)
    const handleClick = () => {
        setOpen((prev) => !prev)
    }
    return (
        <>
            <Stack
                direction={"column"}
            >
                {sidebarOpened ?
                    <Box>
                        <Show condition={sidebarOpened}>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Typography className="sidebar-element-group-title">
                                    {translateField(element, "title")}
                                </Typography>
                                <Box sx={{
                                    cursor: "pointer"
                                }}
                                    onClick={() => handleClick(element.groupTitle)}>
                                    {open ?
                                        <IconButton>
                                            <CaretUp size={20} />
                                        </IconButton>
                                        : <IconButton>
                                            <CaretDown size={20} />
                                        </IconButton>
                                    }
                                </Box>
                            </Stack>
                            <Collapse easing={true} in={open} timeout="auto" >
                                <List component="div">
                                    {element.children.map((child) => {
                                        return <>
                                            <ListItem
                                                alignItems='center'
                                                disableGutters
                                                sx={{
                                                    width: "100%",
                                                    justifyItems: "space-between"
                                                }}>
                                                <SidebarElement
                                                    id={child.id}
                                                    icon={<child.icon />}
                                                    title={translateField(child, "title")}
                                                    path={child.link}
                                                    svg={child.svg}
                                                    // parent={route.parent}
                                                    active={child.active}
                                                    opened={sidebarOpened}
                                                    onClick={() => closeSidebarOnResponsive()}
                                                />
                                            </ListItem>
                                            {
                                                child.divider && (
                                                    <Divider flexItem light sx={{ marginY: 2 }} />
                                                )
                                            }
                                        </>
                                    })}
                                </List>
                            </Collapse>
                        </Show>
                    </Box> : <>
                        {element.children.map((child) => {
                            return <>
                                <SidebarElement
                                    id={child.id}
                                    icon={<child.icon />}
                                    title={translateField(child, "title")}
                                    path={child.link}
                                    svg={child.svg}
                                    active={child.active}

                                    // parent={route.parent}
                                    opened={sidebarOpened}
                                    onClick={() => closeSidebarOnResponsive()}
                                />
                                {
                                    child.divider && (
                                        <Divider flexItem light sx={{ marginY: 2 }} />
                                    )
                                }
                            </>
                        })}

                    </>
                }
            </Stack>

        </>
    )
}

export default CollapsedCategroy