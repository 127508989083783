import {
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { XCircle } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./SearchBar.scss";

const SearchBar = ({
  headerRef,
  fetchPage,
  fetchSearchPage,
  isPaginated,
  handleSearch,
  loading,
  reset,
  search,
  setSearch,
}) => {
  const location = useLocation();
  const pageNumber = useSelector(
    (state) => state.applicantSearchAdmin.pageNumber
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const fetchPageOfSearch = () => {
    console.log("fetch page", search);
    if (search === "" || search === null || search === undefined || search?.trim().length === 0) {
      if (isPaginated) dispatch(fetchPage(10, 1));
      // setSearch('')
      reset?.();
    } else {
      if (isPaginated) dispatch(fetchSearchPage(search, 10, 1));
      else handleSearch(search);
    }
  };

  const resetSearch = () => {
    setSearch("");
    reset?.();
    if (isPaginated) dispatch(fetchPage(10, 1));
  };

  useEffect(() => {
    fetchPageOfSearch();
  }, []);

  useEffect(() => {
    setSearch("");
  }, [location]);

  return (
    <Stack
      direction={{
        xs: "row",
        md: "row",
      }}
      justifyContent={"space-between"}
      spacing={2}
    >
      <InputBase
        className="search-input"
        type="text"
        placeholder={t("others.search") + ".."}
        sx={{
          width: "100%",
        }}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            fetchPageOfSearch();
          }
        }}
        value={search}
        endAdornment={
          search ? (
            <InputAdornment position="end">
              <IconButton onClick={resetSearch}>
                <XCircle />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      <Button
        className="btn btn-dark search-btn"
        onClick={fetchPageOfSearch}
        disabled={loading}
      >
        {t("others.search")}
      </Button>
      {/* <Grid item xs={2} sm={2} md={1}>
        <Button
          className="btn btn-dark search-btn"
          onClick={resetSearch}
          disabled={loading}
        >
          {t("others.reset")}
        </Button>
      </Grid> */}
    </Stack>
  );
};

export default SearchBar;
