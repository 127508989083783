import { Chip } from "@mui/material";
import Loader from "../loader/Loader";
import "./JobTag.scss";

const JobTag = (props) => {
  const { text, bold } = props;

  return (
    <Loader data={text} height={15} width={20}>
      <Chip
        className="job-tag"
        sx={
          bold && {
            //fontWeight: "bold !important",
            color: "#3A3B81 !important",
            fontSize: "16px !important",
            lineHeight: "19px !important",
          }
        }
        label={text}
      />
    </Loader>
  );
};
export default JobTag;
