import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SUBMIT_OTP_SUCCESSFULL,
} from "../../actions/auth/types";

let initialState = {
  token: null,
  refreshToken: null,
  // otpToken: null,
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_LOGIN_SUCCESS:
      console.log("AUTH_LOGIN_SUCCESS", payload);
      return {
        ...state,
        token: payload.access_token,
        refreshToken: payload.refresh_token,
      };
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        refreshToken: null,
        // otpToken: null,
      };
    case AUTH_SUBMIT_OTP_SUCCESSFULL:
      return {
        ...state,
        token: payload.access_token,
        // otpToken: payload.token,
      };
    default:
      return state;
  }
};

export default authReducer;
