import {
    CalendarBlank,
    FileText,
    Flag,
    GearSix,
    GlobeHemisphereEast,
    GlobeSimple,
    House,
    List,
    LockLaminated,
    Star
} from "phosphor-react";
import Permissions from "../../../constants/permissions";
import {
    applicantsList, careerLevelIcon,
    companyIndustryIcon,
    companyPositionsIcon,
    educationDegreeIcon,
    gradeIcon,
    jobTitlesIcon,
    maritalIcon,
    militaryIcon, notification, recruitersList, workStyleIcon
} from "../../../constants/sidebarIcons";
export const sidebarElements = [
    {
        id: "home",
        title_en: "Home",
        title_ar: "الرئيسية",
        icon: House,
        link: "/",
        permission: Permissions.viewHome,
        divider: false,
        active: ["/"]
    },
    {
        id: "settings",
        title_en: "App Settings",
        title_ar: "إعدادات التطبيق",
        icon: GearSix,
        link: "/settings",
        permission: Permissions.viewHome,
        divider: false,
        active: ["/settings"]
    },
    {
        id: "languages",
        title_en: "App Languages",
        title_ar: "لغات التطبيق",
        icon: GlobeSimple,
        link: "/app-languages",
        permission: Permissions.viewHome,
        divider: false,
        active: ["/app-languages"]
    },
    {
        id: "countries",
        title_en: "Countries",
        title_ar: "البلدان",
        icon: GlobeHemisphereEast,
        link: "/category/Country",
        permission: Permissions.viewHome,
        divider: true,
        active: ["/category/Country", "/category/Governorate", "/category/City"]
    },
    {
        id: "terms-conditions",
        title_en: "Terms & Conditions",
        title_ar: "الشروط والأحكام",
        icon: FileText,
        link: "/terms-and-conditions",
        permission: Permissions.viewHome,
        divider: false
    },
    {
        id: "privacy-policy",
        title_en: "Privacy Policy",
        title_ar: "سياسة الخصوصية",
        icon: LockLaminated,
        link: "/privacy-policy",
        permission: Permissions.viewHome,
        divider: true
    },
    {
        title_en: "Categories",
        title_ar: "الفئات",
        permission: Permissions.viewHome,
        groupTitle: true,
        divider: true,
        active: ["/category/:model_name"],
        children: [
            {
                id: "nationalities",
                title_en: "Nationalities",
                title_ar: "الجنسيات",
                icon: Flag,
                link: "/category/Nationality",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/Nationality"],
            },
            {
                id: "military_status",
                title_en: "Military Status",
                title_ar: "الحالة العسكرية",
                icon: militaryIcon,
                link: "/category/Military",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/Military"],
            },
            {
                id: "marital_status",
                title_en: "Marital Status",
                title_ar: "الحالة الاجتماعية",
                icon: maritalIcon,
                link: "/category/Marital",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/Marital"],

            },
            {
                id: "years_of_experience",
                title_en: "Years Of Experience",
                title_ar: "سنوات الخبرة",
                icon: CalendarBlank,
                link: "/category/YearsOfExperience",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/YearsOfExperience"],

            },
            {
                id: "experience_type",
                title_en: "Experience Type",
                title_ar: "نوع الخبرة",
                icon: Star,
                link: "/category/ExperienceType",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/ExperienceType"],
            },
            {
                id: "career_level",
                title_en: "Seniority Level",
                title_ar: "مستوى الأقدمية",

                icon: careerLevelIcon,
                link: "/category/CareerLevel",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/CareerLevel"],
            },
            {
                id: "job_categories",
                title_en: "Job Categories",
                title_ar: "فئات العمل",
                icon: List,
                link: "/category/JobCategory",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/JobCategory"],

            },
            {
                id: "job_titles",
                title_en: "Job Titles",
                title_ar: "عناوين العمل",
                icon: jobTitlesIcon,
                link: "/category/JobTitle",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/JobTitle"],

            },
            {
                id: "education_degree",
                title_en: "Education Degree",

                title_ar: "درجة التعليم",
                icon: educationDegreeIcon,
                link: "/category/EducationDegree",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/EducationDegree"],

            },
            {
                id: "grade",
                title_en: "Grade",
                title_ar: "المعدل",
                icon: gradeIcon,

                link: "/category/Grade",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/Grade"],

            },
            {
                id: "company_industry",
                title_en: "Company Industry",
                title_ar: "قطاع الشركة",

                icon: companyIndustryIcon,
                link: "/category/CompanyIndustry",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/CompanyIndustry"],

            },
            {
                id: "company_positions",
                title_en: "Company Positions",
                title_ar: "مناصب الشركة",
                icon: companyPositionsIcon,
                link: "/category/RecruiterPosition",

                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/RecruiterPosition"],
            },
            {
                id: "work_style",
                title_en: "Work Style",
                title_ar: "طبيعة العمل",
                icon: workStyleIcon,
                link: "/category/WorkStyle",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "categories",
                active: ["/category/WorkStyle"],
            },
        ]
    },
    {
        title_en: "Users",
        title_ar: "المستخدمين",
        permission: Permissions.viewHome,
        groupTitle: true,
        divider: true,
        // active: ["/category/Nationality"],

        children: [
            {
                id: "applicants",
                title_en: "Applicants",
                title_ar: "مقدمي الطلبات",
                icon: applicantsList,
                link: "/applicants",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "users",
                active: ["/applicants", "applicants/:applicant_id"]
            },

            {
                id: "recruiters",
                title_en: "Recruiters",
                title_ar: "أصحاب العمل",
                icon: recruitersList,
                link: "/recruiters",
                permission: Permissions.viewHome,
                divider: false,
                elementGroup: "users",
                active: ["/recruiters", "recruiters/:company_id", "jobs/:job_id"]
            },
        ]
    },
    {
        id: "send-notification",
        title_en: "Send Notification",
        title_ar: "إرسال إشعارات",
        icon: notification,
        link: "/send-notification",
        permission: Permissions.viewHome,
        divider: false,
        active: ["/send-notification"]

    },
]