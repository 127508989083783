import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, Dialog, FormControlLabel, FormHelperText, Grid, Switch, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import IconInput from '../../components/IconInput';
import { addLanguageLocal, editLanguageLocal, getDocumentDirection } from '../../services/Helper';

const LanguagesModal = ({ open, closeHandler, langs, edit, row, languages_list }) => {
    const [loading, setLoading] = useState(false)
    const documentDirection = getDocumentDirection();
    let schema = {
        locale: yup
            .string()
            .required(t("validations.required_input"))
            .matches(
                /^[a-zA-Z0-9 \\#\[\]\",/~`'!@$%^&*()_+={}|;<>.?:]+$/,
                t("validations.only_english_alphapet")
            ),
        language_id: yup.string().required(t("validations.required_input")),
        status: yup.bool().optional(),
        name: yup.string().required(t("validations.required_input"))
    };
    const modelSchema = yup.object(schema).required();

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(modelSchema),
    });
    const onSubmit = (data) => {
        setLoading(true)
        console.log({ data })
        data.language_id = parseInt(data.language_id)
        if (data.status) {
            data.status = 1
        }
        else {
            data.status = 0
        }
        if (!edit) {
            addLanguageLocal(data).then((res) => {
                console.log(res)
            }).finally(() => {
                setLoading(true)

                closeHandler(true)
            })
        }
        else {
            data.id = row.id
            console.log("editing", { row }, data)
            editLanguageLocal(data).then((res) => {
                console.log(res)
            })
                .finally(() => {
                    setLoading(true)
                    closeHandler(true)
                })
        }
    }
    const restructureLanguageList = () => {
        return languages_list.map((lang) => {
            return {
                id: lang.id,
                label: lang.name
            }
        })
    }
    useEffect(() => {
        console.log({ row })
        if (edit) {
            setValue("locale", row?.locale)
            setValue("language_id", row?.lang_id)
            setValue("status", row?.status_code)
            setValue("name", row?.name)
        }
        else {
            setValue("status", 0)
        }
    }, [])

    return (
        <Dialog open={open}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container
                    sx={{ maxWidth: "450px", height: "fit-content", padding: "20px" }}
                    rowSpacing={2}
                    columnSpacing={2}
                    justifyContent={"center"}
                >
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: "24px" }}>{edit ? t("titles.edit_locale") : t("titles.add_new_locale")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name={"language_id"}
                            render={({ field }) => {
                                return <>
                                    <Autocomplete
                                        value={edit && { label: row.name, id: row.id }}
                                        options={restructureLanguageList()}
                                        onChange={(e, newVal) => { setValue("language_id", newVal.id) }}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => <>
                                            <TextField error={errors?.language_id} sx={{ borderRadius: "8px" }} {...params} label="Language" />
                                            {errors?.language_id &&
                                                <FormHelperText sx={{ color: "red" }}>
                                                    {errors?.language_id.message}
                                                </FormHelperText>
                                            }
                                        </>}
                                    />
                                </>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IconInput
                            id="locale"
                            placeholder={t("labels.locale")}
                            label={t("labels.locale")}
                            fieldName="locale"
                            type="text"
                            className={`w100 ${documentDirection === "right"
                                ? "arabic-align"
                                : "category-english-name-input english-align"
                                } `}
                            onChange={(e) => console.log(e.target.value)}
                            register={register}
                            error={errors?.locale}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IconInput
                            id="locale"
                            placeholder={t("placeholders.enter_name")}
                            label={t("placeholders.enter_name")}
                            fieldName="name"
                            type="text"
                            className={`w100 ${documentDirection === "right"
                                ? "arabic-align"
                                : "category-english-name-input english-align"
                                } `}
                            onChange={(e) => console.log(e.target.value)}
                            register={register}
                            error={errors?.name}
                        />
                    </Grid>
                    <Grid item container xs={12} justifyContent={"center"}>
                        <Grid item>
                            <Controller
                                control={control}
                                name="status"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return <>
                                        <FormControlLabel checked={value} control={
                                            <Switch
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                inputProps={{ 'aria-label': 'controlled' }} />
                                        }
                                            label="Ready" />
                                    </>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} columnSpacing={1} justifyContent={"flex-end"}>
                        <Grid item xs={3}>
                            <Button
                                disabled={loading}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: "#3A3B81",
                                        opacity: "0.6"
                                    },
                                    backgroundColor: "#3A3B81",
                                    textTransform: "capitalize",
                                    width: "100%",
                                    color: "white"
                                }}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                sx={{
                                    '&:hover': {
                                        backgroundColor: "red",
                                        opacity: "0.6"
                                    },
                                    backgroundColor: "red",
                                    textTransform: "capitalize",
                                    width: "100%",
                                    color: "white"
                                }}
                                onClick={() => closeHandler(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Dialog>
    )
}

export default LanguagesModal
