import { Button, Box, Divider, Fade, Grid, IconButton, Menu, MenuItem, Popover, useMediaQuery } from "@mui/material";
import { Bell, List, MagnifyingGlass, Plus, SquaresFour, SignIn } from "phosphor-react";
import PageBack from "../../../components/page-back/PageBack";
import PageHeader from "../../../components/page-header/PageHeader";
import { useLocation, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import "./Header.css";
import React, { useEffect, useRef, useState } from "react";
import { fetchMyProfile, getNotificationList, getPageHeaderByPath, getRoutePath, getUnreadNotificationsCount, perm } from "../../../services/Helper";
import Hide from "../../../components/Hide";
import Show from "../../../components/Show";
import { toggleSidebar } from "../../../state/actions/sidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import NotificationsDropdown from "./components/notifications-dropdown/NotificationsDropdown";
import RoleSwitcher from "./components/role-switcher/RoleSwitcher";
import { useTranslation } from "react-i18next";
import { login, logout } from "../../../services/auth/Auth"
import ChangeLang from "../../../components/ChangeLang/ChangeLang"
import Permissions from "../../../constants/permissions";
import { OPENED } from "../../../state/actions/notification/types";
import { assignRole, fetchProfile } from "../../../state/actions/profile/profile";
import { completeB2cLogin } from "../../../state/actions/b2c/b2c";
import { FETCH_PROFILE } from "../../../state/actions/profile/types";
import { deleteFirebaseToken } from "../../../firebase";
import { SEARCH_SET, SEARCH_TOGGLE, SEARCH_UNSET } from './../../../state/actions/search/types';
import SearchBar from '../../../views/search-page/components/search-bar/SearchBar';
import { authLogin as adminLogin, adminLogout, authLogout } from "../../../state/actions/auth/auth";
import { useMsal } from "@azure/msal-react";

const iconColor = "#3A3B81"

const menuIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
            <g id="Menu" transform="translate(-29 -24)">
                <rect id="Rectangle_120" data-name="Rectangle 120" width="20" height="20" transform="translate(29 24)" fill="rgba(0,0,0,0)" />
                <g id="Menu-2" data-name="Menu" transform="translate(6.377 12.551)">
                    <rect id="Rectangle_67" data-name="Rectangle 67" width="18" height="2" rx="1" transform="translate(23.623 16.449)" fill={iconColor} />
                    <rect id="Rectangle_68" data-name="Rectangle 68" width="9" height="2" rx="1" transform="translate(23.623 24.449)" fill={iconColor} />
                    <rect id="Rectangle_121" data-name="Rectangle 121" width="14" height="2" rx="1" transform="translate(23.623 20.449)" fill={iconColor} />
                </g>
            </g>
        </svg>
    )
}

const NotificationsButtonDropdown = ({ redDotState }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        // dispatch({ type: OPENED })
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>
            <IconButton
                id="notification_button"
                className={`jb-icon-btn `}
                onClick={handleClick}
            >
                <Bell color={iconColor} />
                <Box
                    component="div"
                    className={`${redDotState && "notify-round"}`}
                ></Box>
            </IconButton>
            <Popover
                id="notification_button"
                className="header-dropdown"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <NotificationsDropdown seeAllClick={() => handleClose()} handleClose={handleClose} />
            </Popover>
        </>
    );
}

const RoleSwitcherButtonDropdown = () => {

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>
            <IconButton
                id="role_switcher_button"
                className="jb-icon-btn"
                onClick={handleClick}
            >
                <SquaresFour color={iconColor} />
            </IconButton>
            <Popover
                id="role_swticer_drp"
                className="header-dropdown"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <RoleSwitcher />
            </Popover>
        </>
    );
}

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const headerRef = useRef()
    const params = useParams();
    const path = getRoutePath(location, params);
    const dispatch = useDispatch();
    const smMatch = useMediaQuery('(max-width:599.95px)');
    const mdMatch = useMediaQuery('(max-width:899.99px)');
    const { instance } = useMsal();

    const { t, i18n } = useTranslation();
    const [pageHeader, setPageHeader] = useState();
    useEffect(() => {
        const header = getPageHeaderByPath(path);
        setPageHeader(header);
        if (location.pathname === "/search")
            dispatch({ type: SEARCH_SET })
        else
            dispatch({ type: SEARCH_UNSET })

    }, [location.pathname]);






    const loading = useSelector((state) => state.auth.loading);
    const authenticated = useSelector((state) => state.auth.token);
    const profile = useSelector((state) => state.profile.data);
    const roles = useSelector((state) => state.profile.data?.roles);
    const role = useSelector((state) => state.profile.role?.name);
    const permissions = useSelector((state) => state.profile.role?.permissions);
    const redDotState = useSelector((state) => state.newNotification.new)
    const searchState = useSelector((state) => state.search.active);
    const onToggleClick = () => {
        dispatch(toggleSidebar())
    }
    const handleSearchIconClick = () => {
        console.log(location.pathname)
        if (location.pathname === "/search") {
            return
        }
        dispatch({ type: SEARCH_TOGGLE })
        console.log(searchState)
    }

    const handleLogout = (logoutType) => {
        // if (logoutType === "popup") {
        //     instance.logoutPopup({
        //         postLogoutRedirectUri: "/",
        //         mainWindowRedirectUri: "/"
        //     });
        // } else if (logoutType === "redirect") {
        //     instance.logoutRedirect({
        //         postLogoutRedirectUri: "/",
        //     });
        // }

        dispatch(authLogout(navigate));
    }
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" paddingY={3} sx={{
                height: "48px"
            }}>
                <Hide condition={mdMatch}>
                    <Grid item xs={6}>
                        <Hide condition={mdMatch}>
                            <Show condition={searchState}>
                                <SearchBar headerRef={headerRef} />
                            </Show>
                            <Hide condition={searchState}>
                                {pageHeader ? pageHeader : <></>}
                            </Hide>
                        </Hide>
                        <Hide condition={!authenticated}>
                            <Show condition={mdMatch}>
                                <IconButton className="jb-icon-btn" onClick={() => onToggleClick()}>
                                    {menuIcon()}
                                </IconButton>
                            </Show>
                        </Hide>
                    </Grid>
                </Hide>

                <Grid container item xs direction="row" columnSpacing={1} justifyContent="end" >
                    <Show condition={false}>
                        <Grid item ref={headerRef}>
                            <IconButton className={`jb-icon-btn ${searchState ? "btn-dark" : null}`} onClick={handleSearchIconClick}>
                                <MagnifyingGlass color={searchState ? "white" : iconColor} />
                            </IconButton>
                        </Grid>
                    </Show>
                    <Show condition={false}>
                        <Grid item>
                            <NotificationsButtonDropdown redDotState={redDotState} />
                        </Grid>
                    </Show>
                    <Show condition={perm(permissions, Permissions.canSwitchRole)}>
                        <Grid item>
                            <Divider light orientation="vertical" />
                        </Grid>
                    </Show>
                    <Show condition={perm(permissions, Permissions.canSwitchRole)}>
                        <Grid item>
                            <RoleSwitcherButtonDropdown />
                        </Grid>
                    </Show>
                    <Hide condition={authenticated && mdMatch}>
                        <Hide condition={authenticated}>
                            <Grid item>
                                <Button
                                    className="btn btn-dark btn-home"
                                    onClick={() => dispatch(adminLogin())}
                                >
                                    {t("buttons.login")} / {t("buttons.sign_up")}
                                </Button>
                            </Grid>
                        </Hide>
                        <Show condition={authenticated}>
                            <Grid item>
                                <Button
                                    className="btn btn-home"
                                    onClick={() => handleLogout('popup')}
                                >
                                    {t("buttons.log_out")}
                                </Button>
                            </Grid>
                        </Show>
                        <Grid item>
                            <ChangeLang />
                        </Grid>
                    </Hide>
                </Grid>
            </Grid>

        </>
    );
}
export default Header;