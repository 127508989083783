import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Button,
  Divider,
  IconButton,
  InputLabel,
  InputBase,
} from "@mui/material";
import {
  At,
  Lock,
  AppleLogo,
  FacebookLogo,
  GoogleLogo,
  WindowsLogo,
} from "phosphor-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
import { useNavigate, Link } from "react-router-dom";
import IconInput from "../../../components/IconInput";
import { login } from "../../../services/Helper";
import { useDispatch, useSelector } from "react-redux";
import { authLogin } from "../../../state/actions/auth/auth";
import {
  fetchProfile,
  fetchProfileAndAssignRole,
} from "../../../state/actions/profile/profile";

const socialLoginIcons = [AppleLogo, FacebookLogo, GoogleLogo, WindowsLogo];

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let width = window.innerWidth;
  const { t, i18n } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const assignedRole = useSelector((state) => state.profile.role);
  const loginProcess = useSelector((state) => state.process.login);

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("validations.email_format"))
        .required(t("validations.required_input")),
      password: yup
        .string()
        .required(t("validations.required_input"))
        .min(8, t("validations.min_input", { number: 8 }))
        .max(16, t("validations.max_input", { number: 16 })),
    })
    .required();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    const { email, password } = formData;
    dispatch(authLogin(navigate, email, password));
  };

  useEffect(() => {
    if (token && assignedRole.name === "GUEST_USER") {
      dispatch(fetchProfileAndAssignRole(navigate));
    }
  }, [token]);

  useEffect(() => {
    if (token && loginProcess.finished) {
      navigate("/");
    }
  }, [token]);

  return (
    <AuthPageWrapper
      titleSlice1="Enjoy the"
      titleSlice2="Opportunities"
      description="Job Links allows connection between people and organizations through the world."
      className="sign-in-wrapper"
      single={width <= 991 ? true : false}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid className="signin-form" justifyContent="center" xs={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormControl className="mb24">
                <Typography className="title">Joblink</Typography>
                <Typography className="desc">{t("labels.hello")}</Typography>
              </FormControl>
              <IconInput
                label={t("labels.email")}
                id="email"
                placeholder={t("others.email_placeholder")}
                type="email"
                icon={At}
                className="mb16"
                fieldName="email"
                register={register}
                error={errors.email}
              />
              <IconInput
                label={t("others.password")}
                id="password"
                placeholder={t("others.enetr_password")}
                type="password"
                fieldName="password"
                icon={Lock}
                register={register}
                error={errors.password}
              />
              <FormControl className="mb16">
                <Typography
                  className="forgot-password-btn"
                  onClick={() => navigate("/forget-password")}
                >
                  {t("links.forgot_password")}
                </Typography>
              </FormControl>
              <FormControl className="mb24">
                <Button type="submit" className="login-btn" variant="contained">
                  {t("buttons.login")}
                </Button>
              </FormControl>
              {/* <FormControl>
                <Divider
                  flexItem
                  orientation="horizontal"
                  variant="middle"
                  className="auth-divider"
                >
                  {t("continue_with")}
                </Divider>
              </FormControl> */}
              {/* <FormControl className="mb16">
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {socialLoginIcons.map((icon) => {
                    return (
                      <IconButton className="social-login-btn" to="#">
                        {React.createElement(icon, {
                          weight:
                            icon.displayName === "GoogleLogo" ? "bold" : "fill",
                          size: "20",
                        })}
                      </IconButton>
                    );
                  })}
                </Box>
              </FormControl> */}
            </FormGroup>
          </form>
        </Grid>
      </Grid>
    </AuthPageWrapper>
  );
};
export default Signin;
