import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { addNewModel } from "../../../../services/Helper";
import IconInput from "./../../../../components/IconInput";
import { getDocumentDirection } from "./../../../../services/Helper";
import "./AddModal.scss";
import JLSelect from "../../../../components/JLSelect";

const AddModal = ({ open, openHandler, modelName, refresh, setRefresh }) => {
  const { t } = useTranslation();
  const { params } = useParams();
  const documentDirection = getDocumentDirection();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  let addSchema = {
    name_en: yup
      .string()
      .required(t("validations.required_input"))
      .matches(
        /^[a-zA-Z0-9 \\#\[\]\",/~`'!@$%^&*()_+={}|;<>.?:]+$/,
        t("validations.only_english_alphapet")
      ),
  };
  if (modelName !== "Grade") {
    addSchema.name_ar = yup
      .string()
      .required(t("validations.required_input"))
      .matches(
        /^[\u0621-\u064A0-9 \\#\[\]\",/~`'!@$%^&*()_+={}|;<>.?:]+$/,
        t("validations.only_arabic_alphapet")
      );
  } else {
    addSchema.name_ar = yup.string().required(t("validations.required_input"));
  }
  if (modelName === "Country") {
    addSchema.id_name = yup
      .string()
      .required(t("validations.required_input"))
      .min(2, t("validations.min_input", { number: 2 }))
      .max(2, t("validations.max_input", { number: 2 }));
  }
  const modelSchema = yup.object(addSchema).required();
  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(modelSchema),
  });
  const onSubmit = (data) => {
    setLoading(true);
    let modelData = { ...data, model: modelName };
    const country = searchParams.get("country");
    const governorate_id = searchParams.get("governorate_id");
    if (country) {
      modelData.id_name = country;
    }

    if (governorate_id) {
      modelData.id_name = governorate_id;
    }
    addNewModel(modelData, true)
      .then((res) => {
        console.log(res);
        openHandler(false);
        clearErrors();
        reset();
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCancel = () => {
    openHandler(false);
    clearErrors();
    reset();
  };
  console.log(documentDirection, "dirr");
  return (
    <Dialog open={open} className="add-category-modal" disableScrollLock={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography className="add-modal-title">
              {t("buttons.add_new_2")} {t(`models.${modelName}`)}
            </Typography>
          </Grid>
          {modelName === "Country" && (
            <Grid item xs={12}>
              <IconInput
                label={t("labels.country_id")}
                id="country_id"
                placeholder={t("placeholders.enter_country_id")}
                fieldName="id_name"
                type="text"
                className={`w100 ${
                  documentDirection === "right"
                    ? "arabic-align"
                    : "category-english-name-input english-align"
                } `}
                searchHome={true}
                onChange={(e) => console.log(e.target.value)}
                register={register}
                error={errors?.id_name}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IconInput
              label={t("labels.name_in_english")}
              id="job_title"
              placeholder={t("placeholders.enter_name")}
              fieldName="name_en"
              type="text"
              // className={`w100 ${
              //   documentDirection === "right"
              //     ? "arabic-align"
              //     : "category-english-name-input english-align"
              // } `}
              className="placeholder-input"
              searchHome={true}
              onChange={(e) => console.log(e.target.value)}
              register={register}
              error={errors?.name_en}
            />
          </Grid>
          <Grid item xs={12}>
            <IconInput
              label={t("labels.name_in_arabic")}
              id="job_title"
              placeholder={t("placeholders.enter_name")}
              fieldName="name_ar"
              type="text"
              // className="arabic-align w100"
              className="placeholder-input"
              searchHome={true}
              register={register}
              onChange={(e) => console.log(e.target.value)}
              error={errors?.name_ar}
            />
          </Grid>
          {(modelName === "Country" || modelName === "Governorate") && (
            <Grid item xs={12}>
              <JLSelect
                control={control}
                fieldName={modelName === "Country" ? "countryStatus" : "governorateStatus"}
                label={"Status"}
                // selectLabel={"Disabled"}
                defaultValue={0}
                className="new-job-input"
                childs={[{id: 1, name_en: "Enabled"}, {id: 0, name_en: "Disabled"}]}
                error={errors?.status}
              />
            </Grid>
          )}
          <Grid item xs={12} container columnSpacing={2}>
            <Grid item xs={6}>
              <Button className="btn-cancel" onClick={handleCancel}>
                {t("buttons.cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className="btn-add" type="submit" disabled={loading}>
                {t("buttons.add")} {t(`models.${modelName}`)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default AddModal;
