import "./Layout.css";

import { usetoken, useMsal } from "@azure/msal-react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Hide from "../../../components/Hide";
import Show from "../../../components/Show";
import { HIDDEN_OVERFLOW_ROUTES } from "../../../constants/constants";
import { onMessageListener } from "../../../firebase";
import { loginRequest } from "../../../services/auth/B2CConfig";
import { getPageHeaderByPath } from "../../../services/Helper";
import { closeSidebar } from "../../../state/actions/sidebar/sidebar";
import Redirecting from "../../../views/pages/redirecting/Redirecting";
import SearchBar from "../../../views/search-page/components/search-bar/SearchBar";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";

const Layout = () => {
    const { instance, accounts } = useMsal();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const params = useParams();
    const headerRef = useRef()

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const smMatch = useMediaQuery('(max-width:599.95px)');
    const mdMatch = useMediaQuery('(max-width:899.99px)');

    const rtlDir = document.dir === "rtl"

    const [pageHeader, setPageHeader] = useState();

    const sidebarOpened = useSelector((state) => state.sidebar.opened);
    const rehydrated = useSelector((state) => state._persist.rehydrated);
    const searchState = useSelector((state) => state.search.active);
    const responsiveFilterState = useSelector((state) => state.filter.responsiveFilterState)
    const token = useSelector((state) => state.auth.token);

    console.log("Layout.js rehydrated", rehydrated);


    const loading = useSelector((state) => state.auth.loading);
    // const token = usetoken();
    useEffect(() => {
        if (token) {
            // instance.acquireTokenSilent({
            //     ...loginRequest,
            //     account: accounts[0]
            // }).then((response) => {
            //     //fetchMyProfile();
            // });
        } else {
            navigate('/login')
        }
    }, [token])

    useEffect(() => {
        const header = getPageHeaderByPath(location.pathname);
        setPageHeader(header);
    }, [location.pathname]);



    useEffect(() => {
        if (HIDDEN_OVERFLOW_ROUTES.includes(location.pathname) && !mdMatch) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [location.pathname])

    onMessageListener();

    const handleCloseSidebar = () => {
        dispatch(closeSidebar())
    }

    const handleSearchPagePath = () => {
        if (location.pathname === "/search") {
            return true
        }
        else
            return false
    }

    return (
        <>
            <Show condition={loading}>
                <Redirecting />
            </Show>
            <Show condition={!loading}>
                <Box component="div" className="layout" padding={smMatch ? 2 : 3}>
                    <Grid
                        container sx={{ height: "100%" }}
                        flexWrap="wrap"
                    >
                        <Show condition={token}>
                            <Grid item md={"auto"} className="layout-sidebar-side">
                                <Sidebar />
                                <Show condition={sidebarOpened}>
                                    <Box className="layout-overlay" onClick={handleCloseSidebar} ></Box>
                                </Show>
                            </Grid>
                        </Show>
                        <Grid
                            container
                            item
                            lg
                            sm
                            md
                            paddingX={!mdMatch ? 3 : 0}
                            paddingBottom={3}
                            paddingTop={0}
                        >
                            <Grid
                                container
                                item
                                paddingX={!mdMatch ? 3 : 0}
                                paddingBottom={4}
                                direction="column"
                                marginLeft={!token ? 'unset' : !rtlDir ? sidebarOpened && !mdMatch ? "86px" : "unset" : ""}
                                marginRight={!token ? 'unset' : rtlDir ? sidebarOpened && !mdMatch ? "86px" : "unset" : ""}
                            >
                                <Grid container item className="" height={"100px"}>
                                    <Header />
                                </Grid>
                                <Grid container item direction="row" ref={headerRef} mb={1}>
                                    <Show condition={mdMatch}>
                                        <Show condition={searchState}>
                                            <Hide condition={handleSearchPagePath() && responsiveFilterState}>

                                                <SearchBar headerRef={headerRef} />
                                            </Hide>
                                        </Show>
                                        <Hide condition={searchState}>
                                            <Box component="div" marginTop={4}>
                                                {pageHeader ? pageHeader : <></>}
                                            </Box>
                                        </Hide>
                                    </Show>
                                    <Box mt={1}>

                                    </Box>
                                    <Outlet />
                                    {/* <Content /> */}
                                </Grid>
                                {/* <Grid container item>
                                    <Footer />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Show>
        </>
    );
}
export default Layout;