import React, { useEffect } from "react";
import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Box,
  FormGroup,
} from "@mui/material";
import IconInput from "../../../components/IconInput";
import { At, Lock } from "phosphor-react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toastr } from "react-redux-toastr";
import { sendOTP } from "../../../services/AuthHelper";
import { useDispatch, useSelector } from "react-redux";
import { authResetPassowrd } from "../../../state/actions/auth/auth";

const ResetPassword = () => {
  let width = window.innerWidth;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const otp = useSelector((state) => state.otp);

  // const otpToken = useSelector((state) => state.otp.token);

  const schema = yup
    .object({
      new_password: yup
        .string()
        .min(6, t("validations.min_input", { number: 6 }))
        .max(20, t("validations.max_input", { number: 20 }))
        .required(t("validations.required_input")),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("new_password")], "Passwords must match")
        .required(t("validations.required_input")),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    const { new_password, confirm_password } = formData;
    dispatch(
      authResetPassowrd(navigate, new_password, confirm_password, otp?.email)
    );
  };

  // useEffect(() => {
  //   if (!otpToken) navigate("/login");
  // }, [otpToken]);

  return (
    <AuthPageWrapper
      titleSlice1="Enjoy the"
      titleSlice2="Opportunities"
      description="Job Links allows connection between people and organizations through the world."
      className="sign-in-wrapper"
      single={true}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid className="signin-form" justifyContent="center" xs={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormControl className="mb24">
                <Typography className="title">Joblink</Typography>
                <Typography className="desc">
                  {t("subTitles.lets_reset")}
                </Typography>
              </FormControl>
              <IconInput
                label={"New Password"}
                id="new_password"
                placeholder={"Enter new password"}
                type="password"
                icon={Lock}
                className="mb16"
                fieldName="new_password"
                register={register}
                error={errors.new_password}
              />
              <IconInput
                label={"Confirm Password"}
                id="confirm_password"
                placeholder={"Repeat password"}
                type="password"
                icon={Lock}
                className="mb16"
                fieldName="confirm_password"
                register={register}
                error={errors.confirm_password}
              />
              <FormControl className="mb24">
                <Button type="submit" className="login-btn" variant="contained">
                  {"Submit"}
                </Button>
              </FormControl>
              <FormControl>
                <Typography variant="div" className="navigation-text">
                  {t("subTitles.remember_password")}{" "}
                  <Box component={Link} className="navigation-btn" to="/login">
                    {t("links.back_to_login")}
                  </Box>
                </Typography>
              </FormControl>
            </FormGroup>
          </form>
        </Grid>
      </Grid>
    </AuthPageWrapper>
  );
};

export default ResetPassword;
