import * as msal from "@azure/msal-browser";
import {
  msalConfig,
  loginRequest,
  tokenRequest,
  b2cPolicies,
} from "./B2CConfig";
import { store, persistor } from "../../state/store";
import {
  assignRole,
  deleteProfile,
  fetchProfile,
} from "../../state/actions/profile/profile";
import { type } from "@testing-library/user-event/dist/type";
import { getPermissionsArray, searchInArray } from "../Helper";
import Permissions from "../../constants/permissions";
import {
  completeB2cLogin,
  finishB2cLogin,
  startB2cLogin,
} from "../../state/actions/b2c/b2c";
import { deleteFirebaseToken } from "../../firebase";
import { B2C_LOGIN_COMPLETED } from "../../state/actions/b2c/types";
import { getToken } from "../AuthHelper";

const { getState } = store;
let msalInstance = new msal.PublicClientApplication(msalConfig("login"));

const login = (type) => {
  try {
    let loginRequestCustom = {
      ...loginRequest,
      extraQueryParameters: { ui_locales: document.dir == "rtl" ? "ar" : "en" },
    };
    store.dispatch(startB2cLogin());
    msalInstance.loginRedirect(loginRequestCustom);
  } catch (error) {}
};

const changePassword = () => {
  let loginRequestCustom = {
    ...loginRequest,
    extraQueryParameters: { ui_locales: document.dir == "rtl" ? "ar" : "en" },
  };
  msalInstance.loginRedirect(b2cPolicies.authorities.changePassword);
};

const acquireToken = async () => {
  try {
    // const accessTokenResponse = await msalInstance.acquireTokenSilent(
    //   loginRequest
    // );
    // const accessToken = accessTokenResponse.accessToken;
    const accessToken = await getToken(getState().auth.token);

    return accessToken;
  } catch (error) {}
};

const logout = () => {
  deleteFirebaseToken()
    .catch((err) => console.log(err))
    .finally(() => {
      store.dispatch(startB2cLogin());
      msalInstance.logoutRedirect();
    });
};

const handleResponse = async (response) => {
  if (response !== null) {
    const account = response.account;
    msalInstance.setActiveAccount(account);
    store.dispatch(finishB2cLogin());
  }
};

msalInstance.handleRedirectPromise().then(handleResponse);

msalInstance.addEventCallback(
  async (event) => {
    if (event.eventType === msal.EventType.LOGOUT_SUCCESS) {
      store.dispatch(deleteProfile());
      // deleteFirebaseToken().then((res) => {
      //     if (window.location !== "/") {
      //         window.location = "/";
      //     }
      // });
    } else if (event.eventType === msal.EventType.LOGOUT_SUCCESS) {
      store.dispatch({ type: B2C_LOGIN_COMPLETED });
    }
  },
  (error) => {}
);

export { login, logout, changePassword, acquireToken };
