import {
    CLOSE_SIDEBAR,
    OPEN_SIDEBAR,
    TOGGLE_SIDEBAR
} from "./types"

export const openSidebar = () => (dispatch) => {
    dispatch({ type: OPEN_SIDEBAR })
}

export const closeSidebar = () => (dispatch) => {
    dispatch({ type: CLOSE_SIDEBAR })
}

export const toggleSidebar = () => (dispatch) => {
    dispatch({ type: TOGGLE_SIDEBAR })
}